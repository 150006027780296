import React, {useEffect, useState} from "react";
import {BottomMenu} from "../../core/layout/buttomMenu/bottomMenu";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "../../infrastructure/localization/localizer";
import {Loading} from "../../core/components/loading/loading";
import Tooltip from "../../core/components/tooltips/Tooltip";
import ExternalLink from "../../core/components/link/ExternalLink";
import {laegeuddannelsenUrl} from "../../infrastructure/redirectConstant";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {EmblaIcons} from "../../core/emblaIcons";
import classNames from "classnames";
import useScreenResolution from "../../core/hooks/useScreenResolution";
import useUser from "../../core/hooks/useUser";
import {CreateHelpLinks} from "./createHelpLinks";
import {FolderAndLinks} from "../../core/components/mapper/folderAndLinks";
import {FoldersModel} from "../../services/api/stamdataMappe/FoldersModel";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import DynamicButton from "../../core/components/button/dynamicButton";
import useStamdataApi from "../../core/hooks/useStamdataApi";
import {LinkModel} from "../../services/api/stamdataMappe/LinkModel";
import {MappestrukturModel} from "../../services/api/stamdataMappe/MappestrukturModel";
import {EditHelpLinks} from "./editHelpLinks";

export function InternalLinkPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [foldersAndLinks, setFoldersAndLinks] = useState<FoldersModel>();
    const [createLinkModalOpen, setCreateLinkModalOpen] = useState<boolean>(false);
    const [editLinkModalOpen, setEditLinkModalOpen] = useState<boolean>(false);
    const [selectedLink, setSelectedLink] = useState<LinkModel>();

    const {stamdataMappeApi} = useStamdataApi();
    const {isMobile} = useScreenResolution();
    const {currentUser} = useUser();

    const createLinkModalTarget = "createLinkModal";
    const editLinkModalTarget = "editLinkModal";

    SetTitleBar(Localizer.global_interneLinks(), []);

    useEffect(() => {
        const fetch = async () => {
            return await stamdataMappeApi.getMapperAndLinks()
        }
        fetch().then(result => {
            setFoldersAndLinks(result)
            setIsLoading(false);
        })
    }, [stamdataMappeApi])

    const findFolderById = (mapperMedIndhold: MappestrukturModel[] | undefined, mappeId: string): MappestrukturModel | null => {
        if (!mapperMedIndhold) return null;
        for (const mappeMedIndhold of mapperMedIndhold) {
            if (mappeMedIndhold.id === mappeId) {
                return mappeMedIndhold;
            }
            if (mappeMedIndhold.children) {
                const found = findFolderById(mappeMedIndhold.children, mappeId);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const deleteLinkCallback = (link: LinkModel) => {
        setFoldersAndLinks((prev) => {
            if (prev === undefined) return prev;
            const folder = findFolderById(prev?.mapperMedIndhold, link.mappeId);
            if (folder) {
                folder.links = [...folder.links?.filter(x => x.id !== link.id) ?? []];
            }
            return {
                ...prev,
                mapperMedIndhold: [...prev.mapperMedIndhold]
            };
        })
    }

    function updateLinkList(linkModel: LinkModel) {
        setFoldersAndLinks((prev) => {
            if (prev === undefined) return prev;
            const folder = findFolderById(prev?.mapperMedIndhold, linkModel.mappeId);
            //Add newly created link to the found folder's links
            if (folder?.links) {
                const existingLinkIndex = folder.links.findIndex(link => link.id === linkModel.id);
                if (existingLinkIndex !== undefined && existingLinkIndex >= 0) {
                    // Update existing link
                    folder.links[existingLinkIndex] = linkModel;
                } else {
                    folder.links = [...(folder.links), linkModel];
                }
            }
            //Update state
            return {
                ...prev,
                mapperMedIndhold: [...prev.mapperMedIndhold]
            };
        })
    }

    return (
        <>
            {isMobile &&
                <BottomMenu/>
            }
            <div className="card">
                <div className="card-body wrap d-flex align-items-center">
                    <h3>{Localizer.helpPage_headerDescription()}</h3>
                    {currentUser.IsAdminOrVus() &&
                        <DynamicButton additionalClasses={"flex-align-r"} onClick={() => setCreateLinkModalOpen(true)}
                                       isPrimary>
                            <DynamicButton.TextIconItem iconName={EmblaIcons.Plus}
                                                        text={Localizer.helpPage_createLink()}/>
                        </DynamicButton>
                    }
                </div>
                <div className={classNames("card-body", isMobile && "padding-0")}>
                    <p className={"margin-bottom-m"}>{Localizer.helpPage_linkCreateDescription()}</p>
                    <Loading isLoading={isLoading} text={Localizer.henterLinks()} useAsHidden={true}>
                        {foldersAndLinks &&
                            <FolderAndLinks foldersAndLinks={foldersAndLinks}
                                            deleteCallback={deleteLinkCallback}
                                            editLink={(link: LinkModel) => {
                                                setSelectedLink(link)
                                                setEditLinkModalOpen(true)
                                            }}
                            />
                        }
                    </Loading>
                </div>
            </div>

            {createLinkModalOpen &&
                <SlideIn id={createLinkModalTarget}
                         title={Localizer.helpPage_link()}
                         bodyContent={<CreateHelpLinks
                             modalId={createLinkModalTarget}
                             linkCreatedCallback={(linkModel: LinkModel) => updateLinkList(linkModel)}/>}
                         defaultOpen={true}
                         actionOnCloseCallback={() => setCreateLinkModalOpen(false)}
                />
            }

            {editLinkModalOpen && selectedLink &&
                <SlideIn id={editLinkModalTarget}
                         title={Localizer.helpPage_link()}
                         bodyContent={<EditHelpLinks
                             modalId={editLinkModalTarget}
                             link={selectedLink}
                             linkEdittedCallback={(linkModel: LinkModel) => updateLinkList(linkModel)}/>}
                         defaultOpen={true}
                         actionOnCloseCallback={() => setEditLinkModalOpen(false)}
                         actionFinishedCallback={() => setEditLinkModalOpen(false)}
                />
            }
        </>
    );
}
