import { DynamicMenu } from "../DynamicMenu";
import {
    brugereMenuLink,
    dataadministrationMenuLink,
    evalueringsstatistikMenuLink,
    fileAdministrationMenuLink,
    forloebOverviewMenuLink, interneLinksMenu,
    uddannelseslaegeOverviewMenuLink,
    vejledereMenuLink
} from "core/layout/shared/MenuLinkProps";
import MenuProps from "./menuProps";
import useScreenResolution from "core/hooks/useScreenResolution";

export const AdminMenu = ({ showNotification, nyhederMenuLink }: MenuProps) => {
    const { isMobile } = useScreenResolution();

    const menuLinks = [
        forloebOverviewMenuLink,
        vejledereMenuLink,
        evalueringsstatistikMenuLink,
        brugereMenuLink,
        nyhederMenuLink,
        dataadministrationMenuLink,
        fileAdministrationMenuLink,
        interneLinksMenu
    ];

    if(isMobile)
        menuLinks.unshift(uddannelseslaegeOverviewMenuLink);

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );

}
