import React from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import {NotificationModule} from "ditmer-embla";
import {HandleError} from "../../core/infrastructure/errors/errorBoundary";
import useStamdataApi from "../../core/hooks/useStamdataApi";
import {BuildNew, folderLinkValidationSchema, LinkInputModel} from "./LinkInputModel";
import {ValidationForm} from "../../core/components/validation/components/validationForm";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {useAppDispatch} from "../../app/hooks";
import {LinkModel} from "../../services/api/stamdataMappe/LinkModel";
import {HelpLinkCrudFields} from "./helpLinkCrudFields";
import {ValidationFormSubmitButton} from "../../core/components/validation/components/validationFormButton";

type CreateHelpLinksProps = {
    modalId: string;
    linkCreatedCallback: (linkModel: LinkModel) => void;
}

export function CreateHelpLinks({modalId, linkCreatedCallback}: CreateHelpLinksProps) {
    const dispatch = useAppDispatch();
    const {stamdataLinkApi} = useStamdataApi();

    const createrLinkInFolder = async (inputModel: LinkInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}))
        const linkModel = await stamdataLinkApi.createLinkInFolder(inputModel);
        linkCreatedCallback(linkModel);
        NotificationModule.showSuccess(Localizer.helpPage_createdLink(), "")
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
    }

    return (
        <ValidationForm
            initialValues={BuildNew()}
            validationSchema={folderLinkValidationSchema}
            onSubmit={async (values: LinkInputModel) => {
                await createrLinkInFolder(values)
                    .catch(err => {
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <>
                        <HelpLinkCrudFields formik={formik}/>
                        <div className="modal-actions">
                            <ValidationFormSubmitButton formikProps={formik}>
                                {Localizer.global_save()}
                            </ValidationFormSubmitButton>
                        </div>
                    </>
                )
            }
        </ValidationForm>
    );
}
