
export const EmblaIcons = {
    Delete: "delete",
    Email: "email",
    Phone: "phone",
    Comment: "comment",
    UserGroup: "user-group",
    Activity: "activity",
    Tasks: "tasks",
    Overflow: "overflow",
    List: "list",
    Dashboard: "dashboard",
    Star: "star",
    StarFilled: "star-filled",
    ContentView: "content-view",
    DialogChat: "dialog-chat",
    Feed: "feed",
    Menu: "menu",
    Remove: "remove",
    Success: "success",
    DocumentCheck: "document-check",
    DocumentView: "document-view",
    DocumentDelete: "document-delete",
    Edit: "edit",
    Close: "close",
    Search: "search",
    Plus: "plus",
    Incoming: "incoming",
    DocumentAdd: "document-add",
    Download: "download",
    Warning: "warning",
    Document: "document",
    ListQuestion: "list-question",
    Info: "info",
    ArrowRight: "arrow-right",
    ArrowLeft: "arrow-left",
    ArrowUp: "arrow-up",
    ArrowDown: "arrow-down",
    Question: "question",
    Filter: "filter",
    User: "user",
    Camera: "camera",
    Calendar: "calendar",
    Notifications: "notifications",
    Settings: "settings",
    Toolbox: "toolbox",
    Link: "link",
    Image: "image",
    Lightning: "lightning",
    Preferences: "preferences",
    Danger: "danger",
    Dialog: "dialog",
    Clock: "clock",
    Before: "before",
    Graph: "graph",
    Save: "save",
    Move: "move",
    Flip: "flip",
} as const;

/**
 * Takes all possible string-values inside "EmblaIcons", and maps them to a new union type
 */
export type EmblaIconsType = typeof EmblaIcons[keyof typeof EmblaIcons];

