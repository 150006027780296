import classNames from "classnames";
import { forwardRef, Ref, useEffect } from "react";
import StateManagedSelect, { ActionMeta, GroupBase, mergeStyles, OnChangeValue, Props, SelectInstance, StylesConfig } from "react-select";
import Creatable, { CreatableProps } from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import useForwardedRef from "core/hooks/useForwardedRef";
import MultiValuesAsTextValueContainer from "./components/base/multiValuesAsTextValueContainer";
import styles from "./dropdown.module.scss";
import { Localizer } from "infrastructure/localization/localizer";
import { useMediaQuery } from "react-responsive";
import { mobileMaxWidthQuery } from "core/layout/responsive";
import AsyncSelect, { AsyncProps } from 'react-select/async';
import DropdownOptionLabel from "./components/base/dropdownOptionLabel";
import MultiValueCustom from "./components/base/multiValueCustom";

export type DropdownOption<T = unknown> = {
  label: string;
  value: T;
  subLabel?: string;
};

// So that we can pass custom props to custom components:
// https://react-select.com/props#option
// https://stackoverflow.com/questions/52551786/how-to-pass-props-to-custom-components-in-react-select
export type CustomComponentsCommonSelectProps<T, Option extends DropdownOption<T>> = {
  valueContainerAppendTextOverride?: string;
  optionColorOverride?: (option: Option) => string;
}

export type DropdownProps<T, Option extends DropdownOption<T>, IsCreatable extends boolean, IsMulti extends boolean, IsAsync extends boolean = false> =
  {
    allowCreate?: IsCreatable;
    isAsync?: IsAsync;
    hideDropdownIndicator?: boolean;
    renderMultiValuesAsText?: boolean;
    renderMultiValuesAsTextAppendTextOverride?: string;
    minWidth?: string | number;
    colorAsSubjects?: boolean;
    closeMenuOnOptions?: T[];
    stylesOverride?: StylesConfig;
    /** Shows the "SubLabel" of the DropdownOption in the options-menu (if it exists) */
    showOptionSubLabel?: boolean;
    optionColorOverride?: (option: Option) => string;

    
    ariaLabel?: string; // For accessibility purposes
  }
  &
  (
    IsAsync extends false
      ? AsyncProps<Option, IsMulti, GroupBase<Option>>
      : IsCreatable extends true
        ? CreatableProps<Option, IsMulti, GroupBase<Option>>
        : Props<Option, IsMulti>
  );

export const Dropdown = forwardRef(<T, Option extends DropdownOption<T>, IsCreatable extends boolean = false, IsMulti extends boolean = false, IsAsync extends boolean = false>(
  {
    allowCreate,
    isAsync,
    hideDropdownIndicator,
    renderMultiValuesAsText = false,
    renderMultiValuesAsTextAppendTextOverride,
    minWidth,
    colorAsSubjects = false,
    onChange,
    optionColorOverride,
    closeMenuOnOptions,
    className,
    stylesOverride,
    hideSelectedOptions = false,
    showOptionSubLabel = false,
    isClearable = true,
    ariaLabel,
    ...props
  }: DropdownProps<T, Option, IsCreatable, IsMulti, IsAsync>,
  forwardedRef: Ref<SelectInstance<Option, IsMulti>>,
) => {
  const dropdownRef = useForwardedRef(forwardedRef);
  const isMobile = useMediaQuery(mobileMaxWidthQuery);

  const componentsOverride: Partial<SelectComponents<Option, IsMulti, GroupBase<Option>>> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    IndicatorSeparator: null,
    ...props.isMulti && (renderMultiValuesAsText || isMobile) ? { ValueContainer: MultiValuesAsTextValueContainer  } : { MultiValue: MultiValueCustom },
    ...hideDropdownIndicator ? { DropdownIndicator: null } : {},
    ...props.components,
  };

  // https://react-select.com/props#option
  const customComponentsOverrideCommonSelectProps: CustomComponentsCommonSelectProps<T, Option> = {
    valueContainerAppendTextOverride: renderMultiValuesAsTextAppendTextOverride,
    optionColorOverride: optionColorOverride
  };

  const DropdownComponent = allowCreate ? Creatable : StateManagedSelect;

  if (!props.placeholder) {
    props.placeholder = "";
  }

  const handleChange = (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => {
    onChange?.(newValue, actionMeta);
    if (closeMenuOnOptions && actionMeta.action === "select-option" && actionMeta.option && closeMenuOnOptions.includes(actionMeta.option.value)) {
      dropdownRef.current?.onMenuClose();
    }
  };

  return (
      <>
          {isAsync &&
              <AsyncSelect
                  aria-label={ariaLabel ?? props.placeholder}
                  ref={dropdownRef}
                  onChange={handleChange}
                  noOptionsMessage={() => Localizer.noResults()}
                  formatOptionLabel={(option, meta) => <DropdownOptionLabel option={option} hideSublabel={meta.context === "value" || !showOptionSubLabel} />}
                  styles={mergeStyles(buildBaseStyles(minWidth, colorAsSubjects), stylesOverride)}
                  className={classNames(styles.dropdown, className)}
                  components={componentsOverride}
                  closeMenuOnSelect={props.closeMenuOnSelect ?? !props.isMulti}
                  hideSelectedOptions={hideSelectedOptions}
                  isClearable={isClearable}
                  {...customComponentsOverrideCommonSelectProps}
                  {...props}
              />
          }

          {!isAsync &&
              <DropdownComponent
                  aria-label={ariaLabel ?? props.placeholder}
                  ref={dropdownRef}
                  onChange={handleChange}
                  noOptionsMessage={() => Localizer.noResults()}
                  formatCreateLabel={(input: string) => `${Localizer.global_createNew()} "${input}"`}
                  formatOptionLabel={(option, meta) => <DropdownOptionLabel option={option} hideSublabel={meta.context === "value" || !showOptionSubLabel} />}
                  styles={mergeStyles(buildBaseStyles(minWidth, colorAsSubjects), stylesOverride)}
                  className={classNames(styles.dropdown, className)}
                  components={componentsOverride}
                  closeMenuOnSelect={props.closeMenuOnSelect ?? !props.isMulti}
                  hideSelectedOptions={hideSelectedOptions}
                  isClearable={isClearable}
                  {...customComponentsOverrideCommonSelectProps}
                  {...props}
              />
          }
      </>
  );
});

const buildBaseStyles = (minWidth: string | number | undefined, colorAsSubjects: boolean): StylesConfig => ({
  container: (provided) => ({
    ...provided,
    pointerEvents: "all",
    fontWeight: 400,
    minWidth: minWidth,
    fontSize: "var(--dropdown-font-size)",
    lineHeight: "var(--dropdown-line-height)",
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    cursor: isDisabled ? "not-allowed" : "pointer",
    borderColor: "var(--dropdown-border-color) !important",
    minHeight: "var(--dropdown-height)",
    boxShadow: isFocused ? "var(--dropdown-focus-shadow)" : undefined,
    backgroundColor: isDisabled ? "var(--dropdown-disabled-background-color)" : provided.backgroundColor,
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? undefined : "var(--dropdown-placeholder-color)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
    lineHeight: "17px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0 4px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "var(--dropdown-indicator-color) !important",
    width: "16px",
    padding: 0,
  }),
  dropdownIndicator: (provided, { selectProps }) => ({
    ...provided,
    color: `var(${selectProps.menuIsOpen ? "--dropdown-caret-open-color" : "--dropdown-indicator-color"}) !important`,
    transform: selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
    width: "16px",
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: "200px",
  }),
  option: (provided, { isSelected, isFocused }) => {
    const style = {
      ...provided,
      padding: "6px 8px",
      color: undefined,
    };
    if (isSelected) {
      style.backgroundColor = "var(--dropdown-selected-item-background-color)";
      style.borderLeft = "2px solid var(--dropdown-selected-item-border-color)";
      style.paddingLeft = "6px";
    }
    if (isFocused) {
      style.backgroundColor = "var(--dropdown-item-highlighted-background-color)";
    }
    return style;
  },
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? "var(--dropdown-multi-item-selected-disabled-background-color)" : colorAsSubjects ? "var(--dropdown-multi-item-selected-background-color-as-subjects)" : "var(--dropdown-multi-item-selected-background-color)",
    color: "var(--dropdown-multi-item-selected-text-color)",
    borderRadius: "20px",
    padding: "3px 8px 2px",
    display: "flex",
    alignItems: "center",
  }),
  multiValueLabel: () => ({
    fontSize: "12px",
    padding: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    overflowWrap: "normal",
  }),
  multiValueRemove: (_, { isDisabled }) => ({
    display: isDisabled ? "none" : "flex",
    marginLeft: "4px",
    width: "12px",
  }),
  group: (provided, { data }) => {
    const style = {
      ...provided,
    };

    if (!data.label) {
      style.borderTop = "solid 1px #dcdcdc";
      style.paddingTop = "0";
      style.paddingBottom = "0";
      style.marginTop = "0";
    }

    return style;
  },
  groupHeading: (provided, { data }) => ({
    ...provided,
    height: data.label === undefined ? "0" : provided.height,
    marginBottom: data.label === undefined ? "0" : provided.marginBottom,
  }),
});

