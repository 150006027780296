import './dynamicButton.scss';
import { EmblaIcon, EmblaIconProps } from "../emblaIcon/emblaIcon";
import { Spinner } from "../spinner/spinner";
import classNames from "classnames";
import useScreenResolution from "core/hooks/useScreenResolution";
import React, { AriaRole } from 'react';

type ItemProps = EmblaIconProps & {
    text?: string;
    renderText?: boolean;
    applyDatatableStopEvent?: boolean;
}

export const TextIconItem = ({
    text,
    applyDatatableStopEvent = false,
    renderText = true,
    ...iconProps
} : ItemProps) => {

    const { isMobile } = useScreenResolution();

    return (
        <>
            <EmblaIcon
                additionalClasses={classNames(applyDatatableStopEvent && "stop-event", iconProps.additionalClasses)}
                spriteSvgClasses={classNames(applyDatatableStopEvent && "stop-event")}
                {...iconProps}
            />
            {!isMobile && text && renderText &&
                text
            }

        </>
    );
}

/** Use when wanting TableHeaderAction-buttons to show loading */
const TableHeaderActionLoadingContainer = () => {

    const { isMobile } = useScreenResolution();
    const applyMarginRight = !isMobile;

    return (
        <span className={classNames("loading-container", applyMarginRight && "margin-right-s")} hidden>
            <Spinner spinnerSize="extra-tiny-small"/>
        </span>
    );
}

type ButtonType = 'submit' | 'reset' | 'button' | undefined;

type ModalData = {
    dataTarget: string;
    dataToggle?: string;
    dataDismiss?: string;
}

export type DynamicButtonDefaultProps = {
    id?: string;
    shouldRender?: boolean;
    additionalClasses?: string;
    isPrimary?: boolean;
    isDanger?: boolean;
    isIconButton?: boolean;
    modal?: ModalData;
    disabled?:boolean;
    rounded?:boolean;
    type?: ButtonType;
    formId?: string;
    useFlex?: boolean;
    disableMobileClasses?: boolean; // Can be used if a TextIcon is not used, and the button still should be square
    title?: string;

    // For accessibility
    ariaLabel?: string;
    role?: AriaRole | undefined; 
}

type DynamicButtonProps = DynamicButtonDefaultProps & {
    modal?: ModalData;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const DynamicButtonRoundedClasses = 'rounded-circle btn-icon';

export const getDynamicButtonClasses = (isMobile: boolean, isPrimary: boolean, isDanger : boolean, rounded: boolean, isIconButton?: boolean, useFlex?: boolean, additionalClasses?: string) => {
    const iconClasses = isMobile || rounded ? DynamicButtonRoundedClasses : '';
    const onlyShowIconClasses = isMobile ? 'onlyIcon' : '';
    const styleClasses = isPrimary ? 'btn-primary' : isDanger ? 'btn-danger' : isIconButton ? 'btn-icon' : 'btn-default';
    const flexClasses = useFlex ? 'flex-container flex-align-c' : '';
    return `btn fit-content-min-height ${styleClasses} ${onlyShowIconClasses} ${iconClasses} ${flexClasses} ${additionalClasses}`;
}

const DynamicButton = ({
    id,
    onClick,
    modal,
    formId,
    additionalClasses="",
    shouldRender=true,
    isPrimary=false,
    isDanger=false,
    disabled=false,
    rounded=false,
    type='button',
    isIconButton=false,
    useFlex = false,
    disableMobileClasses=false,
    title,
    ariaLabel,
    role,
    ...props
} : React.PropsWithChildren<DynamicButtonProps>) => {

    const { isMobile } = useScreenResolution()

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(onClick) onClick(event);
    };

    const children = useFlex ? <div className="flex-center">{props.children}</div> : props.children;

    const useMobileClasses = isMobile && !disableMobileClasses;

    return (
        <>
            {shouldRender &&
                <button
                    id={id}
                    type={type}
                    form={formId}
                    className={classNames(getDynamicButtonClasses(useMobileClasses, isPrimary, isDanger, rounded, isIconButton, useFlex, additionalClasses))}
                    disabled={disabled}
                    onClick={(event) => handleOnClick(event)}
                    data-toggle={modal ? modal?.dataToggle ?? 'modal' : undefined}
                    data-target={modal?.dataTarget}
                    data-dismiss={modal ? modal?.dataDismiss ?? 'modal' : undefined}
                    title={title ?? ""} // If not denfined browsers will give "Buttons must have discernible text: Element has no title attribute"
                    aria-label={ariaLabel}
                    role={role}
                >
                    {children}
                </button>
            }
        </>
    );
}


export default Object.assign(
    DynamicButton,
    {
        TextIconItem,
        TableHeaderActionLoadingContainer
    },
);
