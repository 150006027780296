import { GroupBase, MultiValueProps, components } from "react-select";
import { CustomComponentsCommonSelectProps, DropdownOption } from "../../dropdown";

type MultiValueCustomProps<T, Option extends DropdownOption<T>, IsMulti extends boolean> = MultiValueProps<Option, IsMulti, GroupBase<Option>>;

const MultiValueCustom = <T, Option extends DropdownOption<T>, IsMulti extends boolean>({
  children,
  ...props
}: MultiValueCustomProps<T, Option, IsMulti>) => {

  const { optionColorOverride } = props.selectProps as CustomComponentsCommonSelectProps<T, Option>;

  const multiValueOption = props.data;
  const customColorClass = !!optionColorOverride ? optionColorOverride(multiValueOption) : "";

  return (
    <components.MultiValue className={customColorClass} {...props}>
      {children}
    </components.MultiValue>
  );
};

export default MultiValueCustom;
