import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {Localizer} from "../../infrastructure/localization/localizer";
import {Loading} from "../../core/components/loading/loading";
import {LaegekursusGruppeCard} from "./components/laegekursusGruppeCard";
import {Tab, Tabs} from "../../core/components/Tabs/Tabs";
import {GetLaegekursusGruppeTranslation} from "../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {Alert} from "../../core/components/alert/alert";
import {AlertType} from "../../core/components/alert/alertType";
import {CheckboxSwitch} from "../../core/components/checkbox/checkboxSwitch";
import {DeletedLaegekurserCard} from "./components/deletedKurserComponents/deletedLaegekurserCard";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import useUser from "core/hooks/useUser";
import useScreenResolution from "core/hooks/useScreenResolution";
import useLaegeKursusGrupper from "./hooks/useLaegeKursusGrupper";
import useInitUddannelseslaegeForloebState from "core/hooks/useInitUddannelseslaegeForloebState";

export function LaegekurserPage() {
    const routeMatch = useRouteMatch();
    const { isMobile } = useScreenResolution();
    const { currentUser } = useUser();

    const routeParams = routeMatch.params as { id: string, brugerKursusraekkeId: string };
    const uddannelseslaegeId = routeParams.id;
    const brugerKursusraekkeId = routeParams.brugerKursusraekkeId;

    const { forloebSliceState, initializedToCorrectLaege } = useInitUddannelseslaegeForloebState(uddannelseslaegeId);

    const [showDeletedKurser, setShowDeletedKurser] = useState<boolean>(false);
    const [activeKursusGruppeTab, setActiveKursusGruppeTab] = useState<string>("");

    const {
        isLoading,
        kursusGrupper,
        deletedLageKurser,
        kursusGruppeHalfLength,
        refetch: loadKursusGrupper,
    } = useLaegeKursusGrupper(uddannelseslaegeId, brugerKursusraekkeId ?? forloebSliceState.forloebState.brugerKursusraekkeId, forloebSliceState.forloebState.uddannelseId, initializedToCorrectLaege);

    const kursusGrupperFirstHalf = kursusGrupper.slice(0, kursusGruppeHalfLength);
    const kursusGrupperSecondHalf = kursusGrupper.slice(kursusGruppeHalfLength);

    const tabs: Tab[] = kursusGrupper.map(kg => (
        {
            id: kg.id,
            title: GetLaegekursusGruppeTranslation(kg.kursusGruppeType),
            defaultActive: activeKursusGruppeTab === kg.id,
            content: 
                (<LaegekursusGruppeCard 
                    kursusGruppe={kg}
                    kurserUpdatedCallback={() => loadKursusGrupper()}
                    brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}
                />)
        }
    ));

    return (
        <Loading isLoading={isLoading} text={Localizer.kursusPage_getKurser()}>
            <ForloebSpecificPageWrapper hideAllContent={!initializedToCorrectLaege}>
                {!isMobile &&
                    <div>
                        <ForloebHeader hideSeUddannelseAndForloebDetails={true}/>

                        <Alert alertType={AlertType.Info}
                            message={Localizer.kursusPage_laegekursusBeskedOmDobbeltKurser()}/>

                        <div className="row">
                            {kursusGrupper.length === 0 &&
                                <div className="col-sm-12">
                                    <div className="alert alert-info">
                                        {forloebSliceState.forloebState.manglerKursusOpdatering &&
                                            <>
                                                <b>{Localizer.vejlederpage_afventerKursusopdatering()}</b>
                                            </>
                                        }
                                        {!forloebSliceState.forloebState.manglerKursusOpdatering &&
                                            <>
                                                <b>{Localizer.vejlederpage_noKurser()}</b> <br/>
                                                <b> <i>{Localizer.global_kursusledere()}</i></b>
                                                {Localizer.vejlederpage_noKurserForKursusleder()} <br/>
                                                <b> <i>{Localizer.global_kursusudbydere()}</i></b>
                                                {Localizer.vejlederpage_noKurserForKursusUdbyder()}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-sm-6 padding-right-s">
                                {kursusGrupperFirstHalf?.map((kursusGruppe, index) =>
                                        <div key={index}>
                                            <LaegekursusGruppeCard kursusGruppe={kursusGruppe}
                                                                kurserUpdatedCallback={() => loadKursusGrupper()}
                                                                brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-sm-6 padding-left-s">
                                {kursusGrupperSecondHalf?.map((kursusGruppe, index) =>
                                        <div key={index}>
                                            <LaegekursusGruppeCard kursusGruppe={kursusGruppe}
                                                                kurserUpdatedCallback={() => loadKursusGrupper()}
                                                                brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                        </div>
                                    )
                                }
                            </div>

                            {currentUser.IsAdminOrVus() &&
                                <div className="col-sm-12 margin-left-s margin-top-l">
                                    <CheckboxSwitch
                                        id={"vis-slettede-kurser-id"}
                                        label={Localizer.kursusPage_visSlettedeKurser()}
                                        onChange={(e) => setShowDeletedKurser(e.currentTarget.checked)}
                                        value={showDeletedKurser}/>
                                </div>
                            }

                            {showDeletedKurser && deletedLageKurser.length > 0 && currentUser.IsAdminOrVus() &&
                                <div className="col-sm-6 margin-top-m">
                                    <DeletedLaegekurserCard
                                        kurserUpdatedCallback={() => loadKursusGrupper()}
                                        deletedLaegekurserForUser={deletedLageKurser}
                                        brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                </div>
                            }
                        </div>
                    </div>
                }

                {isMobile && tabs.length > 0 &&
                <div className="top-level-row">
                    <Tabs tabs={tabs}
                          setActiveCallback={setActiveKursusGruppeTab}/>
                </div>
                }
            </ForloebSpecificPageWrapper>
        </Loading>
    );
}
