import React, {useState} from 'react'
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {Vejledersamtale} from "./vejledersamtale";
import {VejledersamtaleEnum, VejledersamtaleModel} from "../vejledersamtaleModel";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {SlideIn} from "../../../../core/components/slideIn/slideIn";
import {CreateVejledersamtale} from "./createVejledersamtale";
import {NotificationModule} from "ditmer-embla";
import {ContextButtonInfo, ContextButtonMenu} from "../../../../core/components/contextMenu/contextButtonMenu";
import {EmblaIcons} from "../../../../core/emblaIcons";
import useLogbogApi from "../../../../core/hooks/useLogbogApi";
import {useAppSelector} from "../../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../../core/forloebSlice";
import {LinkPlacementEnum} from "../../../../core/components/link/LinkPlacementEnum";
import {HelpLinks} from "../../../../core/components/link/helpLinks";
import classNames from 'classnames';

type VejledersamtaleListProps = {
    vejledersamtaler?: VejledersamtaleModel[];
    vejledersamtalerUpdatedCallback: () => void;
}

export function VejledersamtaleList(props: VejledersamtaleListProps) {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const modalTarget = "vejledersamtale-create-modal";
    const {logbogExportApi} = useLogbogApi();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false)

    function tilfoejVejledersamtale() {
        setCreateModalOpen(true);
    }

    const vejledersamtaleOprettet = async () => {
        props.vejledersamtalerUpdatedCallback();
        NotificationModule.showSuccess(Localizer.vejledersamtalepage_vejledersamtaleCreated(), "");
    }

    const createContextMenuButtons = (): ContextButtonInfo[] => {
        let buttons = new Array<ContextButtonInfo>()
        buttons.push(new ContextButtonInfo(downloadVejledersamtalerPdfs, Localizer.GetPdf(), EmblaIcons.Download));
        return buttons;
    }

    const downloadVejledersamtalerPdfs = async () => {
        setIsLoadingPdf(true);
        await logbogExportApi.getPdfVejledersamtaler(forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.id, forloebSliceState.forloebState.stillingId)
            .then(() => setIsLoadingPdf(false));
    }

    return (
        <>
            <div className="card top-level-container">
                <div className="card-header">
                    <h3 className="card-title">{Localizer.global_vejledersamtaler()}
                    <HelpLinks linkPlacement={LinkPlacementEnum.VejledersamtaletPage} additionalClasses={"margin-left-s"}/>
                    </h3>
                    <div className="card-actions">
                        <ContextButtonMenu buttons={createContextMenuButtons()} useButtonIcons={true} border
                                           showLine={false}
                                           additionalClasses={"hamburger is-active margin-left-s margin-right-s"}
                                           isLoading={isLoadingPdf}
                                           loadingTooltipText={Localizer.gettingPdf()}
                        />
                        <button type="button" className="btn btn-default rounded-circle btn-icon" aria-label="Primary"
                                onClick={() => tilfoejVejledersamtale()}>
                            <EmblaIcon iconName="plus"/>
                        </button>
                    </div>
                </div>

                <div className="card-body padding-0">
                    {props.vejledersamtaler &&
                        props.vejledersamtaler?.map((vejledersamtale, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div
                                        className={classNames("card-list", vejledersamtale.currentUserHasAccess && vejledersamtale.id && "clickable-no-hover")}
                                        key={vejledersamtale.id}
                                    >
                                        <Vejledersamtale vejledersamtale={vejledersamtale}
                                                         vejledersamtalerUpdatedCallback={props.vejledersamtalerUpdatedCallback}/>
                                    </div>
                                    <div className={"card-list-border"}/>
                                </React.Fragment>
                            )
                        })
                    }
                </div>

                {createModalOpen &&
                    <SlideIn
                        id={modalTarget}
                        title={Localizer.vejledersamtalePage_planVejledersamtale()}
                        actionText={Localizer.vejledersamtalePage_creatingVejledersamtale()}
                        actionFinishedCallback={() => vejledersamtaleOprettet()}
                        actionOnCloseCallback={() => setCreateModalOpen(false)}
                        bodyContent={<CreateVejledersamtale modalId={modalTarget}
                                                            vejledersamtaleType={VejledersamtaleEnum.Anden}/>}
                        defaultOpen={true}
                    />
                }
            </div>
        </>
    );
}
