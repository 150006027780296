import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LaegekursusGruppeModel} from "../../../services/api/laegekursus/laegekursusGruppeModel";
import {
    GetLaegekursusGruppeTranslation,
    LaegekursusGruppeTypeEnum
} from "../../sharedmodels/kursus/laegekursusGruppeTypeEnum";
import PdfComponent from "core/components/pdf/pdfComponent";
import {PdKursusHeaderComponent} from "./pdfHeaders/pdfKursusHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";

export const KursusPdf = () => {

    const routeMatch = useRouteMatch();
    const routeParams = routeMatch.params as { laegekursusgruppeId: string, brugerId: string, brugerMaalbeskrivelseId:string };

    const [laegeKursusgruppe, setlaegekursusgruppe] = useState<LaegekursusGruppeModel>();
    const setError = useFetchWithErrorHandlingPdf();
    const { logbogLaegekursusApi } = useLogbogApi();

    useEffect( () => {
        const fetch = async () => {
            const laegekursusResponse = await logbogLaegekursusApi.getLaegeKursusGruppe(routeParams.laegekursusgruppeId);
            sortKurserBySortOrder(laegekursusResponse);
            setlaegekursusgruppe(laegekursusResponse);
        }
        fetch().catch(err => setError(err));
        (document as any).pdfReady = true;
    }, [logbogLaegekursusApi, routeParams.laegekursusgruppeId, setError])

    const sortKurserBySortOrder = (kursusGruppe: LaegekursusGruppeModel) => {
        return kursusGruppe.kurser.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
    }

    return (
        <PdfComponent>
                <div>
                    <PdKursusHeaderComponent
                        brugerId = {routeParams.brugerId}
                        brugerMaalbeskrivelseId = {routeParams.brugerMaalbeskrivelseId}
                    />

                    {laegeKursusgruppe && (laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.Forskningstraening ||
                            laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.FaellesInternMedicin ||
                            laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.Specialespecifikke ||
                            laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi ||
                            laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.AlmenMedicin
                        ) &&
                    <div className={"card shadow-none"}>
                        <div className={"card-body"}>
                            <div className={"card-list-modal"}>
                                    <>
                                        <div className={"row margin-bottom-m"}>
                                            <h5 className={"col-6"}>{Localizer.kursusPage_samletGodkendelse()}</h5>
                                            <h5 className={"col-2"}>{Localizer.global_godkendt()}</h5>
                                            <h5 className={"col-2"}>{Localizer.global_kommentarTilGodkendelse()}</h5>
                                            <h5 className={"col-2"}>{Localizer.global_godkendtandet()}</h5>
                                        </div>

                                        <div className="row margin-top-s margin-bottom-s">
                                            <div className={"col-6"}>
                                                <div className="flex-row">
                                                    <div className="subtle">
                                                        {laegeKursusgruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.AlmenMedicin &&
                                                        <>
                                                            <b>{GetLaegekursusGruppeTranslation(laegeKursusgruppe.kursusGruppeType)}</b>
                                                            <p>{Localizer.kursusPage_AlmenMedicinKurserBeskrivelseEt()}</p>
                                                            <p>{Localizer.kursusPage_AlmenMedicinKurserBeskrivelseTo()}
                                                                <a href="https://www.speam.dk/" target="_blank" rel="noreferrer">
                                                                    <u>{Localizer.global_SPEAM()}</u>
                                                                </a>
                                                            </p>
                                                        </>
                                                        }
                                                        {laegeKursusgruppe.kursusGruppeType !== LaegekursusGruppeTypeEnum.AlmenMedicin &&
                                                            <>
                                                                <div className="subtle">
                                                                    {GetLaegekursusGruppeTranslation(laegeKursusgruppe.kursusGruppeType)}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-2"}>
                                                <div className="flex-row">
                                                    <div className="subtle">
                                                        {laegeKursusgruppe.godkendelsesInfo?.godkendtDen &&
                                                            <div>
                                                                {laegeKursusgruppe.godkendelsesInfo.godkendtDen.dateWithoutTimeFormat(true)}
                                                            </div>
                                                        }
                                                        {laegeKursusgruppe.godkendelsesInfo?.godkendtAfNavn &&
                                                            <div>
                                                                {laegeKursusgruppe.godkendelsesInfo?.godkendtAfNavn}
                                                            </div>
                                                        }
                                                        {laegeKursusgruppe.godkendelsesInfo?.rolleTitel &&
                                                            <div>
                                                                {laegeKursusgruppe.godkendelsesInfo?.rolleTitel}
                                                            </div>
                                                        }
                                                        {laegeKursusgruppe.godkendelsesInfo?.authorisationsId &&
                                                            <div>
                                                                AuthID: {laegeKursusgruppe.godkendelsesInfo?.authorisationsId}
                                                            </div>
                                                        }
                                                        {laegeKursusgruppe.godkendelsesInfo?.rolle &&
                                                            <div>
                                                                Rolle: {laegeKursusgruppe.godkendelsesInfo?.rolle}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-2"}>
                                                <div className="flex-row">
                                                    <div className="subtle">
                                                        {laegeKursusgruppe.godkendelsesInfo &&
                                                            <div>
                                                                {laegeKursusgruppe.godkendelsesInfo.kommentar}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-2"}>
                                                <div className="flex-row">
                                                    <div className="subtle">
                                                        {laegeKursusgruppe.godkendelsesInfo?.godkendtDen &&
                                                            <div>
                                                                {laegeKursusgruppe.godkendtPaaAndetGrundlag ? Localizer.global_ja() : Localizer.global_nej()}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            </div>
                        </div>
                    </div>
                    }

                    <div className={"card shadow-none"}> 
                        <div className={"card-body h-100"}>
                            <div className={"card-list-modal"}>
                                {laegeKursusgruppe !== undefined && laegeKursusgruppe?.kurser.length > 0 &&
                                <>
                                    <div className={"row margin-bottom-m"}>
                                        <h5 className={"col-2"}>{Localizer.global_kursustitel()}</h5>
                                        <h5 className={"col-2"}>{Localizer.startdato()}</h5>
                                        <h5 className={"col-2"}>{Localizer.slutdato()}</h5>
                                        <h5 className={"col-2"}>{Localizer.global_godkendt()}</h5>
                                        <h5 className={"col-2"}>{Localizer.global_kommentarTilGodkendelse()}</h5>
                                        <h5 className={"col-2"}>{Localizer.global_godkendtandet()}</h5>
                                    </div>
                                    {laegeKursusgruppe?.kurser?.map((kursus, key) =>
                                        <PdfComponent.NonBreakableItem>
                                            <div key={key} className="row margin-top-s margin-bottom-s">
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.titel}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.startDato?.dateWithoutTimeFormat(true)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.slutDato?.dateWithoutTimeFormat(true)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.godkendelsesInfo?.godkendtDen &&
                                                                <div>
                                                                    {kursus.godkendelsesInfo.godkendtDen.dateWithoutTimeFormat(true)}
                                                                </div>
                                                            }
                                                            {kursus.godkendelsesInfo?.godkendtAfNavn &&
                                                                <div>
                                                                    {kursus.godkendelsesInfo?.godkendtAfNavn}
                                                                </div>
                                                            }
                                                            {kursus.godkendelsesInfo?.rolleTitel &&
                                                                <div>
                                                                    {kursus.godkendelsesInfo?.rolleTitel}
                                                                </div>
                                                            }
                                                            {kursus.godkendelsesInfo?.authorisationsId &&
                                                                <div>
                                                                    AuthID: {kursus.godkendelsesInfo?.authorisationsId}
                                                                </div>
                                                            }
                                                            {kursus.godkendelsesInfo?.rolle &&
                                                                <div>
                                                                    Rolle: {kursus.godkendelsesInfo?.rolle}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.godkendelsesInfo &&
                                                                <div>
                                                                    {kursus.godkendelsesInfo.kommentar}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-2"}>
                                                    <div className="flex-row">
                                                        <div className="subtle">
                                                            {kursus.godkendelsesInfo &&
                                                                <div>
                                                                    {kursus.godkendPaaAndetGrundlag ? Localizer.global_ja() : Localizer.global_nej()}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PdfComponent.NonBreakableItem>
                                    )}
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </PdfComponent>

    );
}
