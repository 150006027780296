import { useState } from "react";
import { Localizer } from "infrastructure/localization/localizer";
import { Loading } from "../../core/components/loading/loading";
import { SetTitleBar } from "../../core/layout/titleBar/titleBarApi";
import {useHistory, useRouteMatch} from "react-router-dom";
import { VejledersamtaleList } from "./vejledersamtaler/components/vejledersamtaleList";
import { UddannelsesplanList } from "./uddannelsesplan/components/uddannelsesplanList";
import { Tab, Tabs } from "../../core/components/Tabs/Tabs";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import useScreenResolution from "core/hooks/useScreenResolution";
import {userVejledningQueryParams} from "../../infrastructure/route/queryParams";
import {RoutePaths} from "../../infrastructure/routes";
import useInitUddannelseslaegeForloebState from "core/hooks/useInitUddannelseslaegeForloebState";
import useVejledersamtaler from "./hooks/useVejledersamtaler";
import useUddannelsesplaner from "./hooks/useUddannelsesplaner";

export function VejledningPage() {
    const { params } = useRouteMatch<userVejledningQueryParams>();
    const { isMobile } = useScreenResolution();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState<string>(params.uddannelsesplan ? Localizer.uddannelsesplan() : Localizer.global_vejledersamtale());

    const uddannelseslaegeId = params.uddannelseslaege;

    const { forloebSliceState, initializedToCorrectLaege } = useInitUddannelseslaegeForloebState(uddannelseslaegeId);

    const { vejledersamtaler, isLoading: vejlederSamtalerIsLoading, refetch: refetchVejlederSamtaler } = useVejledersamtaler(forloebSliceState.forloebState.id, initializedToCorrectLaege);
    const { uddannelsesplaner, isLoading: uddannelsesplanerIsLoading, refetch: refetchUddannelsesplaner } = useUddannelsesplaner(forloebSliceState.forloebState.id, initializedToCorrectLaege);

    const isLoading = vejlederSamtalerIsLoading || uddannelsesplanerIsLoading;

    SetTitleBar(Localizer.global_vejledning(), [{text: Localizer.global_vejledning(), url: ""}]);

    const setTabAndHistory = (id: string) => {
        setActiveTab(id);

        history.replace(RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
            uddannelsesplan: undefined,
            vejledersamtale: undefined
        }).url);
    }

    const tabs: Tab[] = [
        {
            title: Localizer.global_vejledersamtale(),
            id: Localizer.global_vejledersamtale(),
            defaultActive: activeTab === Localizer.global_vejledersamtale(),
            content: <VejledersamtaleList vejledersamtaler={vejledersamtaler} vejledersamtalerUpdatedCallback={refetchVejlederSamtaler} />
        },
        {
            title: Localizer.uddannelsesplan(),
            id: Localizer.uddannelsesplan(),
            defaultActive: activeTab === Localizer.uddannelsesplan(),
            content: <UddannelsesplanList uddannelsesplaner={uddannelsesplaner} uddannelsesplanerUpdatedCallback={refetchUddannelsesplaner} />
        },
    ];

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.profilepage_getVejledersamtaler()}>
                <ForloebSpecificPageWrapper hideAllContent={!initializedToCorrectLaege}>
                    {!isMobile &&
                        <div>
                            <ForloebHeader hideSeUddannelseAndForloebDetails={true} />
                            <div className="row top-level-row">
                                <div className="col-md-6 col-12">
                                    <VejledersamtaleList vejledersamtaler={vejledersamtaler} vejledersamtalerUpdatedCallback={refetchVejlederSamtaler} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <UddannelsesplanList uddannelsesplaner={uddannelsesplaner} uddannelsesplanerUpdatedCallback={refetchUddannelsesplaner} />
                                </div>
                            </div>
                        </div>
                    }

                    {isMobile &&
                        <div className="top-level-row">
                            <Tabs tabs={tabs} tabClickedCallback={x => setTabAndHistory(x.id)}/>
                        </div>
                    }
                </ForloebSpecificPageWrapper>
            </Loading>
        </>
    );
}
