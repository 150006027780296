import {Localizer} from "infrastructure/localization/localizer";
import {useRouteMatch} from "react-router-dom";
import {Loading} from "../../core/components/loading/loading";
import {ForloebKompetencerOverview} from "./forloebOverviews/forloebKompetencerOverview";
import {ForloebVejledersamtalerOverview} from "./forloebOverviews/forloebVejledersamtalerOverview";
import {ForloebNotaterOverview} from "./forloebOverviews/forloebNotaterOverview";
import {ForloebUddannelsesplanerOverview} from "./forloebOverviews/forloebUddannelsesplanerOverview";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {ForloebKurserOverview} from "./forloebOverviews/forloebKurserOverview";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import {SpecialespecifikkeFilerOverview} from "./forloebOverviews/specialespecifikkeFilerOverview";
import {UddannelsesprogrammerOverview} from "./forloebOverviews/uddannelsesprogrammerOverview";
import UddannelsesstedFiles from "../filer/components/uddannelsesstedFiles";
import useInitUddannelseslaegeForloebState from "core/hooks/useInitUddannelseslaegeForloebState";
import useUser from "core/hooks/useUser";

export type ForloebPageQueryParam = {
    id?: string;
    showForloebHistorik?: string;
}

export function ForloebPage() {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const routeMatch = useRouteMatch();
    const { currentUser } = useUser();

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;
    const forloebId = (routeMatch.params as { forloebId: string }).forloebId;

    const { 
        initializedToCorrectLaege, 
        isInitializing: isLoading 
    } = useInitUddannelseslaegeForloebState(uddannelseslaegeId, undefined, forloebId);

    const shouldHideSeUddannelseAndForloebDetailsButtons = currentUser.IsKursusBruger() || currentUser.IsHrMedarbejder() || currentUser.IsForskningstraeningsvejleder();
    const shouldHideForloebDetailsButton =currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsLedelse();
    const canSeeCardOverviews = currentUser.IsAdminOrVus() || currentUser.IsLaege() || currentUser.IsVejleder() || currentUser.IsUddannelsesansvarlig();

    return (
        <Loading isLoading={isLoading} text={Localizer.profilepage_getProfil()}>
            <ForloebSpecificPageWrapper hideAllContent={!initializedToCorrectLaege}>
                {!IsMobile &&
                    <div>
                        <ForloebHeader hideSeUddannelseAndForloebDetails={shouldHideSeUddannelseAndForloebDetailsButtons} hideForloebDetails={shouldHideForloebDetailsButton}/>
                        <div className="row">
                            {canSeeCardOverviews &&
                                <>
                                    <div className="col-6">
                                        <>
                                            <ForloebKompetencerOverview/>
                                            <ForloebVejledersamtalerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <ForloebUddannelsesplanerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                        </>
                                    </div>

                                    <div className="col-6">
                                        <>
                                            <ForloebKurserOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <ForloebNotaterOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <UddannelsesprogrammerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <UddannelsesstedFiles showRightHeaderContent hideContent/>
                                            <SpecialespecifikkeFilerOverview showRightHeaderContent/>
                                        </>
                                    </div>

                                    <div className="col-6">
                                        <>
                                        </>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }

                {IsMobile &&
                    <>
                        <ForloebHeader
                            hideSeUddannelseAndForloebDetails={shouldHideSeUddannelseAndForloebDetailsButtons}
                            hideForloebDetails={shouldHideForloebDetailsButton}/>
                        {canSeeCardOverviews &&
                            <>
                                <ForloebKompetencerOverview/>
                                <ForloebKurserOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebVejledersamtalerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebUddannelsesplanerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebNotaterOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <UddannelsesprogrammerOverview showRightHeaderContent={!currentUser.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <SpecialespecifikkeFilerOverview showRightHeaderContent/>
                            </>
                        }
                    </>
                }
            </ForloebSpecificPageWrapper>
        </Loading>
    );
}
