import React, {useCallback, useEffect, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {VejlederHeader} from "core/components/headerCards/vejlederHeader";
import {Loading} from "core/components/loading/loading";
import {Pagination} from "../../../../core/components/pagination/pagination";
import {useDataadministrationContext} from "../dataadministrationOverviewContextProvider";
import useLogbogApi from "../../../../core/hooks/useLogbogApi";
import {PaginationModel} from "../../../../core/sharedmodels/filter/PaginationModel";
import Title from "../../../uddannelseslaegeOverview/components/uddannelseslaegerTitle";
import SearchFieldsSynkroniseredeForloeb from "./filter/searchFieldsSynkroniseredeForloeb";
import {SynkroniseretForloeb} from "./synkroniseretForloeb";
import {SynkroniseredeForloebModel} from "../../../../core/sharedmodels/forloeb/synkroniseredeForloebModel";
import {SlideIn} from "core/components/slideIn/slideIn";
import DataadministrationFilter from "../dataadministrationFilter";
import {setSlideinState, SlideInStateEnum} from "core/components/slideIn/slideInSlice";
import {useAppDispatch} from "app/hooks";
import ApplyFiltrerButton from "core/components/filter/components/applyFiltrerButton";
import {EmblaIcons} from "../../../../core/emblaIcons";
import DynamicButton from "../../../../core/components/button/dynamicButton";
import Container from "core/components/container/container";
import {SetTitleBar} from "../../../../core/layout/titleBar/titleBarApi";
import {RoutePaths} from "../../../../infrastructure/routes";

const SynkroniseredeForloebList = () => {
    const {listReducer, statusReducer, filterReducer} = useDataadministrationContext();
    const {forloebApi} = useLogbogApi();
    const [paginationModelState, setPaginationModelState] = useState(new PaginationModel(1, 10, 0));
    const [synkroniseredeForloebModel, setSynkroniseredeForloebModel] = useState<SynkroniseredeForloebModel[]>([]);
    const setIsLoading = useCallback((isLoading: boolean) => statusReducer.dispatch({isLoading: isLoading}), [statusReducer]);
    const statusReducerDispatch = statusReducer.dispatch;
    const dispatch = useAppDispatch();

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);

    SetTitleBar(Localizer.global_dataadministration(), []);

    const filterModalId = "filtermodal";

    useEffect(() => {
        statusReducerDispatch({isLoading: true})
        const fetch = async () => {
            const synkroniseredeForloeb = await forloebApi.getSynkroniseredeForloeb(1);
            setPaginationModelState(current => ({
                ...current,
                recordCount: synkroniseredeForloeb.recordsFiltered,
                page: 1
            }))
            setSynkroniseredeForloebModel(synkroniseredeForloeb.data)
        }
        fetch().then(() => statusReducerDispatch({isLoading: false}));
    }, [forloebApi, statusReducerDispatch])

    const getSynkroniseredeForloeb = async (searchLaeger: string, specialer: string[] | undefined, regioner: string[] | undefined, page?: number) => {
        setIsLoading(true);
        const synkroniseredeForloeb = await forloebApi.getSynkroniseredeForloeb(
            page ?? 1,
            searchLaeger,
            specialer,
            regioner
        );
        setPaginationModelState(current => ({
            ...current,
            recordCount: synkroniseredeForloeb.recordsFiltered,
            page: page ?? 1
        }))
        setSynkroniseredeForloebModel(synkroniseredeForloeb.data)
        listReducer.dispatch({synkroniseredeForloeb: synkroniseredeForloeb.data});
        setIsLoading(false);
    }

    const handlePagination = async (page: number) => {
        await getSynkroniseredeForloeb(filterReducer.state.searchLaege, filterReducer.state?.specialer, filterReducer.state.regioner, page);
    };

    async function callbackLaege(search: string) {
        filterReducer.dispatch({
            ...filterReducer.state,
            searchLaege: search,
        });
        await getSynkroniseredeForloeb(search, filterReducer.state?.specialer, filterReducer.state?.regioner);
    }

    const applyFilter = async () => {
        setFilterModalOpen(false)
        if ((filterReducer.state.specialer && filterReducer.state.specialer?.length > 0) || (filterReducer.state.regioner && filterReducer.state.regioner?.length > 0))
            setFilterApplied(true)
        else
            setFilterApplied(false)
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: filterModalId}));
        await getSynkroniseredeForloeb(filterReducer.state.searchLaege, filterReducer.state?.specialer, filterReducer.state?.regioner)
    }

    async function clearFilterButtonClicked () {
        filterReducer.dispatch({
            ...filterReducer.state,
            searchLaege: filterReducer.state.searchLaege,
            specialer: undefined,
            regioner: undefined
        });
        setFilterApplied(false);
        await getSynkroniseredeForloeb(filterReducer.state.searchLaege, undefined, undefined)
    }

    return (
        <>
            <VejlederHeader/>

            <Container>
                <div className="card-header">
                    <div className="flex-fill">
                        <Title text={Localizer.forloebOverviewPage_synkroniseredeForloeb()}/>
                        <div className="alert alert-info">
                            {Localizer.forloebOverview_overfoerDataBeskrivelse()} <br/>
                            {Localizer.forloebOverview_overfoerDataBeskrivelseTillaegsaftale()}
                        </div>
                    </div>
                </div>

                <div className="d-flex margin-top-m margin-left-m margin-bottom-m">
                    <SearchFieldsSynkroniseredeForloeb callbackLaege={(search) => callbackLaege(search)}/>
                    <ApplyFiltrerButton onClick={() => setFilterModalOpen(true)} hasFilters={filterApplied}/>
                    <DynamicButton
                        shouldRender={filterApplied}
                        onClick={clearFilterButtonClicked}
                        additionalClasses={"margin-left-s"}>
                        <DynamicButton.TextIconItem iconName={EmblaIcons.Close} text={Localizer.global_clearFilters()} />
                    </DynamicButton>
                </div>

                {listReducer.state.synkroniseredeForloeb.flatMap(x => x.id)?.length === 0 && (filterReducer.state.searchLaege) && !statusReducer.state.isLoading &&
                    <div className="col-sm-12">
                        <p>{Localizer.noResults()}</p>
                    </div>
                }

                <Loading
                    isLoading={statusReducer.state.isLoading}
                    text={Localizer.global_getData()} timeout={100}
                    spinnerClasses={"margin-bottom-m margin-left-m margin-top-m"}
                >
                    <div className="card-body padding-0 uddannelseslaeger-list">
                        {synkroniseredeForloebModel?.length > 0 &&
                            synkroniseredeForloebModel?.map(forloeb =>
                                <div className={`card-list card-list-border-bot`}>
                                    <SynkroniseretForloeb
                                        key={forloeb.id}
                                        synkroniseretForloeb={forloeb}
                                        forloebUpdatedCallabck={() => getSynkroniseredeForloeb(filterReducer.state.searchLaege, filterReducer.state?.specialer, filterReducer.state?.regioner)}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className="card-footer flex-row-center">
                        <Pagination
                            handlePagination={handlePagination}
                            paginationModel={paginationModelState}
                        />
                    </div>
                </Loading>
            </Container>

            {filterModalOpen &&
                <SlideIn
                    id={filterModalId}
                    title={Localizer.global_filtrer()}
                    actionFinishedCallback={() => applyFilter()}
                    actionOnCloseCallback={() => setFilterModalOpen(false)}
                    bodyContent={<DataadministrationFilter modalId={filterModalId}/>}
                    defaultOpen={true}
                />
            }
        </>
    );
}
export default SynkroniseredeForloebList;
