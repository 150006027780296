import yup from "types/yup-extended";
import {Localizer} from "../../infrastructure/localization/localizer";
import createValidationSchema from "../../core/components/validation/createValidationSchema";
import {RolleTitelEnum} from "../../infrastructure/enums/rolleTitelEnum";
import {LinkPlacementEnum} from "../../core/components/link/LinkPlacementEnum";
import {LinkModel} from "../../services/api/stamdataMappe/LinkModel";

export class LinkInputModel {
    public mappeId: string;
    public link: string;
    public linkTekst: string;
    public tooltipTekst: string;
    public linkPlacement: LinkPlacementEnum;
    public rolleTitler: RolleTitelEnum[];
}

export const BuildNew = () : LinkInputModel => {
    let inputModel = new LinkInputModel();
    inputModel.mappeId = "";
    inputModel.link = "";
    inputModel.linkTekst = "";
    inputModel.tooltipTekst = "";
    return inputModel;
}

export const BuildExisting = (link: LinkModel) : LinkInputModel => {
    let inputModel = new LinkInputModel();
    inputModel.mappeId = link.mappeId;
    inputModel.link = link.link;
    inputModel.linkTekst = link.linkTekst;
    inputModel.tooltipTekst = link.tooltipTekst;
    inputModel.rolleTitler = link.rolleTitler;
    inputModel.linkPlacement = link.linkPlacement;
    return inputModel;
}

export const folderLinkValidationSchema = createValidationSchema<LinkInputModel>({
    mappeId: yup.string().required(Localizer.validate_mappeRequired),
    link: yup.string().required(Localizer.validate_linkRequired),
    linkTekst: yup.string().required(Localizer.validate_textRequired),
    linkPlacement: yup.number().notRequired(),
    rolleTitler: yup.array().notRequired().nullable(),
    tooltipTekst: yup.string().notRequired()
});
