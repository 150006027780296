import {FileMetadataList} from "../fileMetadata/fileMetadataList";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import React from "react";
import {FoldersResponseModel} from "../../../services/api/stamdataMappe/FoldersResponseModel";
import {MappestrukturModel} from "../../../services/api/stamdataMappe/MappestrukturModel";
import {CollapseTab} from "../Tabs/collapseTab";
import classNames from "classnames";
import {FileData} from "services/api/stamdataMappe/FoldersModel";
import {FileMetadata} from "core/sharedmodels/fileMetaData/fileMetaData";
import {Link} from "./link";
import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";

export const anyChildrenWithFiles = (children: MappestrukturModel[]): boolean => {
    const childrenFlat = children?.flatMap(x => x.children);
    if (children.flatMap(x => x.files)?.length > 0)
        return true
    if (childrenFlat?.length < 1)
        return false
    return anyChildrenWithFiles(childrenFlat)
}

export const anyChildrenWithLinks = (children: MappestrukturModel[]): boolean => {
    const childrenFlat = children?.flatMap(x => x.children);
    if (children.flatMap(x => x.links)?.length > 0)
        return true
    if (childrenFlat?.length < 1)
        return false
    return anyChildrenWithLinks(childrenFlat)
}

export const childrenWithFilesCount = (children: MappestrukturModel[]): number => {
    return children.filter(x => x.files && x.files?.length > 0).length;
}

const getFileAmountFromChildFoldersRecursively = (mapperMedIndhold: MappestrukturModel[], totalAmount: number): number => {
    totalAmount += mapperMedIndhold.flatMap(x => x.files)?.length;
    const childrenFlat = mapperMedIndhold?.flatMap(x => x.children);
    if (childrenFlat?.length > 0)
        return getFileAmountFromChildFoldersRecursively(childrenFlat, totalAmount)
    return totalAmount;
}

export const getAmountOfFilesTotal = (foldersAndFiles: FoldersResponseModel): number => {
    let totalAmount = 0;
    totalAmount += foldersAndFiles?.filerUdenMappe?.length;
    return getFileAmountFromChildFoldersRecursively(foldersAndFiles.mapperMedIndhold, totalAmount);
}
type RenderChildrenRecursivelyProps = {
    folderChildren: MappestrukturModel[];
    paddingProp?: number;
    showCustomBadgeText?: ((fileMetadataId: string, files: FileData[]) => string);
    editFileAction?: (fileMetadataId: string, files: FileData[]) => void;
    deleteAction?: (fileMetadata: FileMetadata) => void;
    deleteLinkAction?: (link: LinkModel) => void;
    editLinkAction?: (link: LinkModel) => void;
    expandedDefault?: boolean;
}

const getFolderCounter = (folder: MappestrukturModel) => {
    const filesInFolder = folder.files?.length ?? 0;

    return filesInFolder > 0
        ? folder.mappeNavn.appendCounter(filesInFolder)
        : folder.mappeNavn;
}

const hasFiles = (folder: MappestrukturModel) => folder.files !== undefined && folder.files?.length > 0;
const hasLinks = (folder: MappestrukturModel) => folder.links !== undefined && folder.links?.length > 0;

export const RenderFolderChildrenRecursively = ({
                                                    folderChildren,
                                                    paddingProp = 2,
                                                    showCustomBadgeText,
                                                    editFileAction,
                                                    deleteAction,
                                                    deleteLinkAction,
                                                    editLinkAction,
                                                    expandedDefault = false
                                                }: RenderChildrenRecursivelyProps) => {

    const paddingClass = `dynamic-padding-${paddingProp}`;

    folderChildren.forEach(x => x.files?.forEach(y => y.fileMetadata.createdAt = new Date(y.fileMetadata.createdAt)))

    return (
        <>
            {(anyChildrenWithFiles(folderChildren) || anyChildrenWithLinks(folderChildren)) &&
                <>
                    {folderChildren.map((child, index) =>
                        <CollapseTab
                            id={child.id + index}
                            hide={(!anyChildrenWithFiles([child]) && !anyChildrenWithLinks([child])) }
                            title={getFolderCounter(child)}
                            expandedDefault={expandedDefault}
                            additionalClasses={classNames("folder", paddingClass)}
                            key={child.id + index}>
                            {hasFiles(child) &&
                                <div className={classNames("font-weight-bold", paddingClass)}>
                                    <FileMetadataList
                                        fileMetadatas={child.files?.map(x => x.fileMetadata) ?? []}
                                        fileLocation={FileLocationEnum.Stamdata}
                                        showDeleteButton={!!deleteAction}
                                        withUploader={false}
                                        hideBorder={true}
                                        showCustomBadgeText={showCustomBadgeText ? (fileId: string) => showCustomBadgeText(fileId, child?.files ?? []) : undefined}
                                        editFileOnClick={editFileAction ? (fileId: string) => editFileAction(fileId, child.files ?? []) : undefined}
                                        fileDeletedCallbackOverride={deleteAction}
                                    />
                                </div>
                            }
                            {hasLinks(child) &&
                                <div className={classNames("font-weight-bold", paddingClass)}>
                                    {child.links?.map((link, index) =>
                                        <Link link={link}
                                              deleteAction={deleteLinkAction}
                                              editLinkAction={editLinkAction}/>
                                    )}
                                </div>
                            }
                            <RenderFolderChildrenRecursively
                                editFileAction={editFileAction}
                                folderChildren={child?.children}
                                paddingProp={paddingProp + 2}
                                expandedDefault={expandedDefault}
                                deleteAction={deleteAction}
                                editLinkAction={editLinkAction}
                                deleteLinkAction={deleteLinkAction}
                            />
                        </CollapseTab>
                    )}
                </>
            }
        </>
    );
};
