
import moment, { min } from "moment";
import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {BeskaeftigelseModel} from "./beskaeftigelseModel";
import yup from "types/yup-extended";

const minNumberForNedsatTidAntalTimer = 0;
const maxNumberForNedsatTidAntalTimer = 168;
export class CrudBeskaeftigelseInputModel {
    constructor() {}

    public id: string;
    public stillingsbetegnelse: string;
    public stillingsnummer: string;
    public ansoegningId: string;
    public ansaettelsessted: string;
    public afdeling: string;
    public startdato?: Date;
    public slutdato?: Date;
    public maaneder: string;
    public nedsatTidAntalTimer?: number;
}

export const BuildNew = (ansoegningId: string) : CrudBeskaeftigelseInputModel => {
    let inputModel = new CrudBeskaeftigelseInputModel();
    inputModel.ansoegningId = ansoegningId;
    return inputModel;
}

export const BuildExisting = (model: BeskaeftigelseModel): CrudBeskaeftigelseInputModel =>  {
    const inputModel = new CrudBeskaeftigelseInputModel();
    inputModel.id = model.id;
    inputModel.stillingsbetegnelse = model.stillingsbetegnelse;
    inputModel.stillingsnummer = model.stillingsnummer;
    inputModel.ansoegningId = model.ansoegningId;
    inputModel.ansaettelsessted = model.ansaettelsessted;
    inputModel.afdeling = model.afdeling;
    inputModel.startdato = model.startdato;
    inputModel.slutdato = model.slutdato;
    inputModel.maaneder = model.maaneder;
    inputModel.nedsatTidAntalTimer = model.nedsatTidAntalTimer;
    return inputModel;
}

export const crudBeskaeftigelseValidationSchema = createValidationSchema<CrudBeskaeftigelseInputModel>({
    id: yup.string().notRequired(),
    stillingsbetegnelse: yup.string().required(Localizer.validate_stillingsbetegnelseRequired),
    stillingsnummer: yup.string().required(Localizer.validate_stillingsNummerRequired),
    ansoegningId: yup.string().notRequired(),
    ansaettelsessted: yup.string().required(Localizer.validate_AnsaettelsesstedRequired),
    afdeling: yup.string().required(Localizer.validate_afdelingRequired),
    startdato: yup.date().required(Localizer.validate_starttidspunktRequired).test(
            "date-validation",
            Localizer.validate_tidspunktInvalid(),
            (value) => {
                return moment(value).isValid();
            }
        ),
    slutdato: yup.date().required(Localizer.validate_sluttidspunktRequired()).test(
        "date-validation",
        Localizer.validate_tidspunktInvalid(),
        (value) => {
            return moment(value).isValid();
        }
    )
        .test(
            "date-validation",
            Localizer.validate_slutDatoBeforeStartdate(),
            function (value) {
                const parent = this.parent as CrudBeskaeftigelseInputModel;
                if (parent.startdato === undefined && parent.slutdato === undefined)
                    return true;
                if (parent.startdato !== undefined)
                    return !value || value >= parent.startdato;
                return true;
            }
        ),
    maaneder: yup.string().required(Localizer.validate_MaanederRequired),
    nedsatTidAntalTimer: yup.number()
                            .decimalMaxScale(2, Localizer.validate_nedsatTidAntalTimerDecimalPrecision)
                            .min(minNumberForNedsatTidAntalTimer, Localizer.validate_nedsatTidAntalTimerMin)
                            .max(maxNumberForNedsatTidAntalTimer, Localizer.validate_nedsatTidAntalTimerMax)
                            .typeError(Localizer.validate_numberTypeError)
                            .required(Localizer.validate_ugentligtTimetal),
});
