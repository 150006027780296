import {Localizer} from "../../../infrastructure/localization/localizer";

//The enums added here needs to be added to stamdata enum as well
export enum LinkPlacementEnum {
    KompetencePage = 1,
    EvalueringsstatistikPage = 2,
    BrugerListPage = 3,
    VejlederListPage = 4,
    VejledersamtaletPage = 5,
    UddannelsesplanPage = 6
}

export const LinkPlacementEnumToString = (linkPlacement: LinkPlacementEnum): string => {
    switch (linkPlacement) {
        case LinkPlacementEnum.KompetencePage:
            return Localizer.global_kompetencer();
        case LinkPlacementEnum.EvalueringsstatistikPage:
            return Localizer.global_evalueringsstatistik();
        case LinkPlacementEnum.BrugerListPage:
            return Localizer.brugere();
        case LinkPlacementEnum.VejlederListPage:
            return Localizer.global_vejledere();
        case LinkPlacementEnum.VejledersamtaletPage:
            return Localizer.global_vejledersamtaler();
        case LinkPlacementEnum.UddannelsesplanPage:
            return Localizer.global_uddannelsesplaner();
        default:
            throw new Error("Missing localizer map for linkPlacement in LinkPlacementEnum: " + linkPlacement);
    }
}
