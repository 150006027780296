import {UserModel} from "../services/account/userModel";
import {RolleEnum} from "./enums/userRole";
import {userBrevskabelonCreateQueryParams, userBrevskabelonEditQueryParams, userBrevskabelonGeneratePdfQueryParams, userBrevskabelonQueryParams, userKompetenceQueryParams, userStpsAnsoegnignQueryParams, userVejledningQueryParams, userKompetenceEvalueringQueryParams} from "./route/queryParams";
import {
    brevskabelonCreateRoute,
    brevskabelonEditRoute,
    brevskabelonGeneratePdfRoute,
    brevskabelonRoute,
    kompetenceEvalueringRoute,
    kompetencerRoute,
    RoutePath,
    sendStpsDocumentationRoute,
    vejledningRoute
} from "./route/routePathConstants";
import BuildQueryParams from "./route/queryParamBuilder";
import {getEnums} from "../core/helpers/enumToList";
import {laegeuddannelsenUrl} from "./redirectConstant";

export class RoutePaths {
    public static SignInCallback = {path: "/signin-oidc", url: "/signin-oidc", roles: null};
    public static Impersonate = {path: "/impersonate", url: "/signin-impersonate", roles: null};
    public static ChangeRole = {path: "/changeRole", url: "changeRole", roles: null};
    public static Index = {path: "/", url: "/", roles: null};

    //  Only admins
    public static forloebOverview = {
        path: "/forloebsoversigt", url: "/forloebsoversigt",
        roles: [
            RolleEnum.Systemadministrator,
            RolleEnum.Administrator,
            RolleEnum.Uddannelsesansvarlig,
            RolleEnum.VusMedarbejder,
            RolleEnum.HrMedarbejder,
            RolleEnum.KonsulentForDenLaegeligeVidereuddannelse,
            RolleEnum.Sekretaer,
            RolleEnum.Kursusleder,
            RolleEnum.Kursusudbyder,
            RolleEnum.Forskningstraeningsvejleder,
            RolleEnum.Ledelse
        ]
    };

    public static ForloebList = (urlParameter?: string) =>
        RoutePaths.buildRoute("/forloebList", "/forloebList/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.HrMedarbejder, RolleEnum.VusMedarbejder],
            urlParameter ? [urlParameter] : undefined);

    public static Stilling = {
        path: "/stilling", url: "/stilling",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator]
    };

    // Exports
    public static LaegeKompetencePdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/kompetence", "/kompetence/pdf/:id/:brugerId/:brugerMaalbeskrivelseId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static LaegeKompetencerPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/kompetencer", "/kompetencer/pdf/:brugerId/:brugerMaalbeskrivelseId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static AllKompetencerOnUserPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/allKompetencer", "/allKompetencer/pdf/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static SelvstaendigtVirkePdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/selvstaendigtVirke", "/selvstaendigtVirke/pdf/:ansoegningId/:brugerId/:brugerMaalbeskrivelseId/:uddannelseTypeEnum",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static AttestationForTidPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/selvstaendigtVirke", "/selvstaendigtVirke/attestation/pdf/:brugerId/brugerMaalbeskrivelse/:brugerMaalbeskrivelseId/(forloeb)?/:forloeb?/",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static KursusPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/kursus", "/kursus/pdf/laegekursusgruppe/:laegekursusgruppeId/bruger/:brugerId/brugerMaalbeskrivelse/:brugerMaalbeskrivelseId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static EvalueringStatistikPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/evalueringsstatistik", "/evalueringsstatistik/pdf/:forloebEvalueringId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static EvalueringSpoergerammePdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/evalueringsstatistik", "/evalueringspoergeramme/pdf",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static UddannelsesplanPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/uddannelsesplan", "/uddannelsesplan/pdf/:brugerId/brugerMaalbeskrivelse/:brugerMaalbeskrivelseId/forloeb/:forloebId/stilling/:stillingId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static VejledersamtalePdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/vejledersamtale", "/vejledersamtale/pdf/:brugerId/brugerMaalbeskrivelse/:brugerMaalbeskrivelseId/forloeb/:forloebId/stilling/:stillingId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static UddannelseslaegeNotesPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/uddannelseslaegeNotater", "/uddannelseslaegeNotater/pdf/:brugerId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static AllVurderingerPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/allVurderinger", "/allVurderinger/pdf/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static KompetenceEvalueringPdf = (urlParameter?: string) =>
        RoutePaths.buildRoute("/kompetenceEvaluering", "/kompetenceEvaluering/pdf/id/:laegekompetenceEvalueringId/brugermaalbeskrivelse/:brugerMaalbeskrivelseId/bruger/:brugerId",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    // Others
    public static UserProfile = {path: "/profil", url: "/profil", roles: null};

    // Kontakt
    public static Contact = {path: "/contact", url: "/contact", roles: null};

    public static Uddannelseslaege = (urlParameter?: string) =>
        RoutePaths.buildRoute("/profil/uddannelseslaege", "/profil/uddannelseslaege/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder, RolleEnum.HrMedarbejder, RolleEnum.Sekretaer, RolleEnum.Forskningstraeningsvejleder, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse, RolleEnum.Ledelse],
            urlParameter ? [urlParameter] : undefined);

    public static Forloeb = (urlParameter?: string[], showForloebHistorik?: boolean) =>
        RoutePaths.buildRoute("/forloeb", "/forloeb/:id/:forloebId?/:showForloebHistorik?/",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse, RolleEnum.Sekretaer, RolleEnum.Ledelse, RolleEnum.HrMedarbejder, RolleEnum.Forskningstraeningsvejleder, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder],
            urlParameter ? urlParameter.concat(showForloebHistorik?.toString() ?? "") : undefined);

    public static Kompetencer = (queryParams?: userKompetenceQueryParams) =>
        RoutePaths.buildTypedRoute(kompetencerRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            queryParams);

    public static EditKompetenceEvaluering = (queryParams?: userKompetenceEvalueringQueryParams) =>
        RoutePaths.buildTypedRoute(kompetenceEvalueringRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            queryParams);

    public static Kurser = (urlParameter?: string[]) =>
        RoutePaths.buildRoute("/kurser", "/kurser/:id/:brugerKursusraekkeId?/",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder, RolleEnum.Forskningstraeningsvejleder, RolleEnum.Sekretaer, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse],
            urlParameter ? urlParameter : undefined);

    public static KompetencerOverview = (urlParameters?: string[]) =>
        RoutePaths.buildRoute("/KompetencerOverview", "/KompetencerOverview/:id/:laegeKompetenceId?/:getAllRegardlessOfMaalbeskrivelse?/:activeTab?",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            urlParameters);

    public static SendStpsDocumentationPage = (urlQueryParam?: userStpsAnsoegnignQueryParams) =>
        RoutePaths.buildTypedRoute(sendStpsDocumentationRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            urlQueryParam);

    public static Vejledning = (urlQueryParam?: userVejledningQueryParams) =>
        RoutePaths.buildTypedRoute(vejledningRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            urlQueryParam);

    public static Brevskabeloner = (urlQueryParam?: userBrevskabelonQueryParams) =>
        RoutePaths.buildTypedRoute(brevskabelonRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.HrMedarbejder],
            urlQueryParam);

    public static BrevskabelonerCreatePage = (urlQueryParam?: userBrevskabelonCreateQueryParams) =>
        RoutePaths.buildTypedRoute(brevskabelonCreateRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.HrMedarbejder],
            urlQueryParam);

    public static BrevskabelonerEditPage = (urlQueryParam?: userBrevskabelonEditQueryParams) =>
        RoutePaths.buildTypedRoute(brevskabelonEditRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.HrMedarbejder],
            urlQueryParam);

    public static BrevskabelonerGeneratePdfPage = (urlQueryParam?: userBrevskabelonGeneratePdfQueryParams) =>
        RoutePaths.buildTypedRoute(brevskabelonGeneratePdfRoute,
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.HrMedarbejder],
            urlQueryParam);

    public static Files = (urlQueryParam?: string) =>
        RoutePaths.buildRoute("/filer", "/filer/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            urlQueryParam ? [urlQueryParam] : undefined);

    public static Notater = (urlParameter?: string) =>
        RoutePaths.buildRoute("/notater", "/notater/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.Uddannelsesansvarlig],
            urlParameter ? [urlParameter] : undefined);

    public static Evaluering = (urlParameter?: string) =>
        RoutePaths.buildRoute("/evaluering", "/evaluering/:id",
            [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Uddannelseslaege],
            urlParameter ? [urlParameter] : undefined);

    public static Vejledere = {
        path: "/vejledere", url: "/vejledere",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.Uddannelsesansvarlig, RolleEnum.Sekretaer, RolleEnum.VusMedarbejder]
    };

    public static FileAdministration = {
        path: "/filer", url: "/filer",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.Uddannelsesansvarlig, RolleEnum.Sekretaer, RolleEnum.VusMedarbejder, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse]
    };

    public static UddannelseslaegeOverview = {
        path: "/uddannelseslaeger", url: "/uddannelseslaeger",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder, RolleEnum.Forskningstraeningsvejleder]
    };

    public static KursisterOverview = {
        path: "/kursister", url: "/kursister",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder]
    };

    public static Klarmeldinger = {
        path: "/klarmeldinger", url: "/klarmeldinger",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder, RolleEnum.Kursusleder]
    };

    public static Nyheder = {
        path: "/nyheder", url: "/nyheder",
        roles: getEnums(RolleEnum)
    };
    public static Dataadministration = {
        path: "/dataadministration",
        url: "/dataadministration",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator]
    };

    public static VejlederNotater = {
        path: "/vejlederNotater", url: "/vejlederNotater",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.VusMedarbejder, RolleEnum.Uddannelsesansvarlig, RolleEnum.Vejleder]
    };

    public static Evalueringsstatistik = {
        path: "/evalueringsstatistik", url: "/evalueringsstatistik",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.Uddannelsesansvarlig, RolleEnum.VusMedarbejder, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse, RolleEnum.Ledelse, RolleEnum.Sekretaer, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.HrMedarbejder, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder, RolleEnum.Forskningstraeningsvejleder]
    };

    public static CreateEvalueringsstatistik = {
        path: "/createEvalueringsstatistik", url: "/createEvalueringsstatistik",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator, RolleEnum.Uddannelsesansvarlig, RolleEnum.VusMedarbejder, RolleEnum.KonsulentForDenLaegeligeVidereuddannelse, RolleEnum.Ledelse, RolleEnum.Sekretaer, RolleEnum.Uddannelseslaege, RolleEnum.Vejleder, RolleEnum.HrMedarbejder, RolleEnum.Kursusleder, RolleEnum.Kursusudbyder, RolleEnum.Forskningstraeningsvejleder]
    };

    public static NotifikationHistorik = {
        path: "/notifikationHistorik", url: "/notifikationHistorik",
        roles: getEnums(RolleEnum)
    };

    public static Brugere = {
        path: "/brugere", url: "/brugere",
        roles: getEnums(RolleEnum, [RolleEnum.Uddannelseslaege])
    };

    public static InterneLinks = {
        path: "/interneLinks", url: "/interneLinks",
        roles: [RolleEnum.Systemadministrator, RolleEnum.Administrator]
    };

    public static Hjaelp = {
        path: "/hjaelp", url: laegeuddannelsenUrl,
        roles: getEnums(RolleEnum)
    };

    //Info pages
    public static Privatlivspolitik = {
        path: "/privatlivspolitik", url: "/privatlivspolitik",
    };

    public static Cookies = {
        path: "/cookies", url: "/cookies",
    };

    // Helper
    private static buildRoute = (basePath: string, path: string, roles: RolleEnum[], urlParameters?: string[]) => {
        if (urlParameters && urlParameters.length > 0) {
            urlParameters.forEach(parameter => {
                basePath += "/" + parameter;
            });
        }

        return { path: path, url: basePath, roles: roles };
    }

    private static buildTypedRoute<T> (path: RoutePath, roles: RolleEnum[], urlQueryParam?: T) {
        const url = urlQueryParam ? BuildQueryParams(urlQueryParam, path) : "";
        return { basePath: path.basePath, path: path.pathDescription , url, roles };
    }
}

export class RootPaths {
    public static getRootPaths(currentUser: UserModel): string[] {
        if (currentUser.IsAdminOrVus()) {
            return [RoutePaths.UddannelseslaegeOverview.path, RoutePaths.Vejledere.path, RoutePaths.Stilling.path]
        }

        if (currentUser.IsUddannelsesansvarlig()) {
            return [RoutePaths.UddannelseslaegeOverview.path, RoutePaths.Vejledere.path]
        }

        if (currentUser.IsVejleder()) {
            return [RoutePaths.UddannelseslaegeOverview.path]
        }

        if (currentUser.IsLaege()) {
            return [RoutePaths.Forloeb().path, RoutePaths.Kompetencer().path, RoutePaths.Vejledning().path, RoutePaths.Notater().path]
        }

        if (currentUser.IsKursusleder()) {
            return [RoutePaths.Forloeb().path]
        }

        if (currentUser.IsKursusudbyder()) {
            return [RoutePaths.Forloeb().path]
        }

        if (currentUser.IsHrMedarbejder()) {
            return [RoutePaths.Forloeb().path]
        }

        if (currentUser.IsKonsulentForLaegeligVidereuddannelse()) {
            return [RoutePaths.Forloeb().path]
        }

        return [];
    }
}
