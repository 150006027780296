import React, {useEffect, useState} from "react";
import PdfComponent from "./pdfComponent";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import PdfRow from "./pdfRow";
import Title from "../titels/title";
import useLogbogApi from "../../hooks/useLogbogApi";
import {KompetenceEvalueringModel} from "../../sharedmodels/evaluering/kompetenceEvalueringModel";
import {useRouteMatch} from "react-router-dom";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import {setDocumentPdfAsReady} from "./helpers/pdfHelper";
import HtmlHeading from "../titels/HtmlHeadings";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {svarReadTypeCompareMap} from "../../../pages/kompetencerPage/readSvar/svarReadTypeCompareMap";
import {KvSkemaConstants} from "../../../pages/kompetencerPage/evaluering/KbuSkemaConstants";

export type KompetencevurderingPdfParams = {
    brugerId: string
    laegekompetenceEvalueringId: string
    brugerMaalbeskrivelseId: string,
};

const KompetenceEvalueringPdf = () => {

    const {evalueringApi} = useLogbogApi();

    const [kompetencevurdering, setKompetencevurdering] = useState<KompetenceEvalueringModel>();

    const {
        laegekompetenceEvalueringId,
        brugerMaalbeskrivelseId,
        brugerId
    } = useRouteMatch<KompetencevurderingPdfParams>().params;

    const setError = useFetchWithErrorHandlingPdf();

    const pdfRowMargin = "margin-top-l";
    const pdfRenderId = "pdfRenderId";

    useEffect(() => {
        const fetchData = async (): Promise<KompetenceEvalueringModel> => {
            return await evalueringApi.getKompetenceEvaluering(laegekompetenceEvalueringId);

        };

        fetchData()
            .then(data => setKompetencevurdering(data))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());

    }, [laegekompetenceEvalueringId, evalueringApi, setError]);

    return (
        <PdfComponent>
            <PdfComponent.PageBreakAfter key={kompetencevurdering?.id}>

                <PdfKompetenceHeaderComponent
                    brugerId={brugerId}
                    brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                />

                <PdfComponent.BlockItem className={"card shadow-none"}>
                    <PdfComponent.PageBreakAfter>
                        <PdfComponent.CardBody>
                            <PdfRow>
                                <PdfRow.Col>
                                    <Title title={kompetencevurdering?.titel} heading={HtmlHeading.H4}/>
                                </PdfRow.Col>
                            </PdfRow>

                            <PdfRow.Col width={12}>
                                <Title title={Localizer.global_kompetence()}
                                       subTitle={kompetencevurdering?.kompetenceTitel}
                                       largeSubTitle nonSubtleSubTitle/>
                            </PdfRow.Col>

                            <PdfRow className={pdfRowMargin}>
                                <PdfRow.Col width={4}>
                                    <Title title={Localizer.UdfyldtAf()}
                                           subTitle={`${kompetencevurdering?.createdByUser?.firstName} ${kompetencevurdering?.createdByUser?.lastName}`}
                                           largeSubTitle nonSubtleSubTitle/>
                                </PdfRow.Col>
                                <PdfRow.Col width={4}>
                                    <Title title={Localizer.dato()}
                                           subTitle={kompetencevurdering?.indsendtDato ? kompetencevurdering?.indsendtDato.dateWithoutTimeFormat(true) : ""}
                                           largeSubTitle nonSubtleSubTitle/>
                                </PdfRow.Col>
                            </PdfRow>

                            <PdfRow className={pdfRowMargin}>
                                <PdfRow.Col width={12}>
                                    <Title title={Localizer.Kompetencevurdering()}
                                           subTitle={kompetencevurdering?.tekst}
                                           largeSubTitle nonSubtleSubTitle/>
                                </PdfRow.Col>
                            </PdfRow>

                            <PdfRow className={pdfRowMargin}>
                                <PdfRow.Col width={12}>
                                    <Title title={Localizer.VejledersVurdering()}>
                                        <div className="flex-column margin-bottom-m">
                                            {kompetencevurdering?.sektioner.filter(s => s.includedInProgress).map(sektion => sektion.spoergsmaal.map(spoergsmaal =>
                                                    <PdfComponent.NonBreakableItem key={spoergsmaal.id + sektion.id}>
                                                        <div className="margin-bottom-m">
                                                            <>
                                                                {spoergsmaal.isFeedback &&
                                                                    <h5>{Localizer.global_feedback()}</h5>}
                                                                {spoergsmaal.isFeedback ?
                                                                    <i><p>{spoergsmaal?.titel}</p></i>
                                                                    :
                                                                    <>
                                                                        <p>{spoergsmaal?.titel}</p>
                                                                        <p className={"subtle"}>{spoergsmaal.tekst}</p>
                                                                    </>
                                                                }
                                                            </>
                                                            {spoergsmaal.evalueringSvar?.map(es => {
                                                                const Component = svarReadTypeCompareMap[spoergsmaal.spoergsmaalTypeEnum];
                                                                return Component ?
                                                                    <Component spoergsmaal={spoergsmaal}
                                                                               evalueringSvar={es}
                                                                               excludeAnswerText={KvSkemaConstants.InitialKbuSkemaIds.includes(kompetencevurdering.evalueringsSkemaId)}
                                                                               renderId={pdfRenderId}/> : <></>;
                                                            })}
                                                        </div>
                                                        <div className="border margin-bottom-m"/>
                                                    </PdfComponent.NonBreakableItem>
                                                )
                                            )}
                                        </div>
                                    </Title>
                                </PdfRow.Col>
                            </PdfRow>
                        </PdfComponent.CardBody>
                    </PdfComponent.PageBreakAfter>
                </PdfComponent.BlockItem>
            </PdfComponent.PageBreakAfter>
        </PdfComponent>
    );
}

export default KompetenceEvalueringPdf;
