import React, {PropsWithChildren} from "react";
import {Tooltip, VariantType, PlacesType} from 'react-tooltip';


type IReactTooltipProps = {
    selectId: string;
    variant?: VariantType;
    delay?: number;
    clickable?: boolean;
    hidden?: boolean;
    openOnClick?: boolean;
    place?: PlacesType;
}

export const ReactTooltip = ({children, selectId, variant = "light", delay = 300, place, clickable = true, hidden=false, openOnClick = false}: PropsWithChildren<IReactTooltipProps>) => {
    return (
        <Tooltip
            className={"image-tooltip-override text-center"}
            classNameArrow={"arrow-classes"}
            openOnClick={openOnClick}
            anchorSelect={`#${selectId}`}
            variant={variant}
            place={place}
            hidden={hidden}
            delayShow={delay}
            clickable={clickable}>
            {children}
        </Tooltip>
    );
}
