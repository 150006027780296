import {Localizer} from "../../infrastructure/localization/localizer";
import {RoutePaths} from "../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import useLogbogApi from "../../core/hooks/useLogbogApi";
import {NotificationModule} from "ditmer-embla";
import {Loading} from "../../core/components/loading/loading";
import {
    GetLaegekursusGruppeTranslation,
    LaegekursusGruppeTypeEnum
} from "../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {LaegekursusModel} from "../../services/api/laegekursus/laegekursusModel";
import {LaegekursusCard} from "../kurserPage/components/laegekursusCard";
import {createPortal} from "react-dom";
import {ModalAcceptType, ModalSubmitMessage} from "../../core/components/modalMessage/modalSubmitMessage";
import {NoResults} from "../../core/components/noResults/noResults";
import classNames from "classnames";
import useScreenResolution from "../../core/hooks/useScreenResolution";
import {SubmitModalInfo} from "../../core/components/modalMessage/submitModalInfo";

export const KurserKlarmeldt = () => {
    const history = useHistory();
    const [areYouSureModalShown, setAreYouSureModalShown] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [kommentar, setKommentar] = useState("");
    const modalId = "modalUdeladKursus";
    const { isMobile } = useScreenResolution();

    const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
    const [selectedLeagekursusIds, setSelectedLeagekursusIds] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const [laegekurser, setLaegekurser] = useState<LaegekursusModel[]>([]);
    const [laegekurserFiltered, setLaegekurserFiltered] = useState<LaegekursusModel[]>([]);
    const {logbogLaegekursusApi} = useLogbogApi()


    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            return await logbogLaegekursusApi.getKlarmeldteKurser()
        }
        fetch().then((x) => {
            setLaegekurser(x)
            setIsLoading(false);
        });
    }, [logbogLaegekursusApi]);

    useEffect(() => {
        const filteredLaegekurser = laegekurser.filter(k =>
            k.titel.toLowerCase().includes(searchValue) ||
            k.uddannelseslaegeNavn.toLowerCase().includes(searchValue) ||
            k.specialeNavn.toLowerCase().includes(searchValue));

        setLaegekurserFiltered(filteredLaegekurser);

        setSelectedLeagekursusIds((prev) => [...prev.filter(id => filteredLaegekurser.map(k => k.id).includes(id))]);
    }, [laegekurser, searchValue]);

    function updateKursusList() {
        setLaegekurser((prev) => [...prev.filter(k => !selectedLeagekursusIds.includes(k.id))]);
        setSelectedLeagekursusIds([]);
    }

    const udeladKursus = async () => {
        const response = await logbogLaegekursusApi.setUdeladKursusFraKlarmeldinger(selectedLeagekursusIds, kommentar);
        if (response.apiResponseMessage.errorMessage)
            NotificationModule.showError(Localizer.klarmeldingerPage_kursusUdeladtError(), response.apiResponseMessage.errorMessage);
        else {
            NotificationModule.showSuccess(Localizer.klarmeldingerPage_kursusUdeladtSucces(selectedLeagekursusIds.length > 1), "")
            updateKursusList();
        }
        setAreYouSureModalShown(false);
    }

    const handleKommentarChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKommentar(e.target.value)
    }

    const rowClickAction = useCallback((data: LaegekursusModel) => {
        if (!showCheckboxes)
            history.push(RoutePaths.Kurser([data.brugerId]).url)
    }, [history, showCheckboxes]);


    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, laegekursusId: string) => {
        const isChecked = e.currentTarget.checked;
        if (isChecked)
            setSelectedLeagekursusIds((prev) => [...prev, laegekursusId]);
        else
            setSelectedLeagekursusIds((prev) => prev.filter(x => x !== laegekursusId));
    }

    const godkendChosenKurser = async () => {
        const alleKurserGodkendt = await logbogLaegekursusApi.godkendLaegekurser(selectedLeagekursusIds);
        if (alleKurserGodkendt) {
            NotificationModule.showSuccess(Localizer.kursusPage_kurserGodkendt(selectedLeagekursusIds.length > 1), "");
            updateKursusList();
        } else
            NotificationModule.showError(Localizer.kursusPage_kurserGodkendtError(), "");
    }

    const filterKurser = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchValue(searchValue);
    }

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.kursusPage_getKurser()}>
                <div className="card top-level-container">
                    <div className="card-header wrap">
                        <h3 className="card-title flex-">{Localizer.global_kurser()}</h3>
                        <input className={classNames("form-control align-self-center max-w-400  margin-right-m", isMobile && "margin-bottom-s")}
                               onChange={(event) => filterKurser(event)}
                               placeholder={Localizer.klarmeldingerPage_searchKurserString()}/>
                        {showCheckboxes &&
                            <>
                                <button type="button" className={classNames("btn btn-primary margin-right-m", isMobile && "margin-bottom-s")}
                                        onClick={godkendChosenKurser}
                                        disabled={selectedLeagekursusIds.length === 0}>
                                    {Localizer.global_godkend()}
                                </button>
                                <button type="button" className={classNames("btn btn-default margin-right-m", isMobile && "margin-bottom-s")}
                                        onClick={() => setAreYouSureModalShown(true)}
                                        disabled={selectedLeagekursusIds.length === 0}>
                                    {Localizer.global_remove()}
                                </button>
                            </>
                        }

                        <button type="button" className={classNames("btn btn-default margin-right-m", isMobile && "margin-bottom-s")}
                                onClick={() => setShowCheckboxes(!showCheckboxes)}>
                            {!showCheckboxes ? Localizer.global_chooseMultiple() : Localizer.global_fortryd()}
                        </button>
                    </div>

                    <div className={"height-80-vh"}>
                        <div className="card-body card-list-custom column-height card-list-scroll position-relative">
                            {laegekurserFiltered.length > 0
                                ?
                                laegekurserFiltered.map((kursus) => {
                                    return (
                                        <>
                                        <div className={classNames("flex-container")}>
                                                <div key={kursus.id} onClick={() => rowClickAction(kursus)}>
                                                    {showCheckboxes &&
                                                        <div className="margin-s d-flex"
                                                             onClick={(e) => e.stopPropagation()}>
                                                            <input type="checkbox"
                                                                   id={`checkbox${kursus.id}`}
                                                                   checked={selectedLeagekursusIds.includes(kursus.id)}
                                                                   onChange={e => onCheckboxChange(e, kursus.id)}
                                                            />
                                                            <label htmlFor={`checkbox${kursus.id}`}/>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex-grow"
                                                     style={{cursor: !showCheckboxes ? "pointer" : ""}}>
                                                    <LaegekursusCard laegekursus={kursus}
                                                                     onCardClickCallback={rowClickAction}
                                                                     laegekursusUdaladKlarmeldingCallback={() => {
                                                                         setSelectedLeagekursusIds([kursus.id]);
                                                                         setAreYouSureModalShown(true)
                                                                     }}
                                                                     laegekursusEditCallback={() => {
                                                                         setLaegekurser((prev) => [...prev.filter(k => k.id !== kursus.id)])
                                                                     }}
                                                                     gruppeKategoriTekst={GetLaegekursusGruppeTranslation(LaegekursusGruppeTypeEnum.Specialespecifikke)}
                                                                     laegekursusGruppeGodkendt={false}
                                                                     hideUdfyldDetails={true}
                                                                     renderApproveButton={!showCheckboxes}
                                                                     renderUdeladKlarmeldingButton={!showCheckboxes}
                                                                     showUserInfo={true}
                                                                     hideStatusBtn={true}
                                                                     hideNotatInfoIfNone={true}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={"d-flex card-list-border margin-bottom-m margin-top-m margin-left-0 margin-right-0"}/>
                                        </>

                                    )
                                })
                                :
                                <NoResults/>
                            }
                        </div>
                    </div>
                </div>
            </Loading>

            {areYouSureModalShown && selectedLeagekursusIds &&
                createPortal(
                    <ModalSubmitMessage
                        modalId={modalId}
                        title={Localizer.areYouSure()}
                        cancelAction={() => {
                            setAreYouSureModalShown(false);
                            setKommentar("");
                        }}
                        acceptAction={udeladKursus}
                        modalAcceptType={ModalAcceptType.primary}
                        acceptButtonText={Localizer.global_remove()}
                        description={<SubmitModalInfo
                            tekst={Localizer.klarmeldingerPage_kursus_areYouSureRemoveKursus()}
                            // Kommentar til denne anvendes ikke nu - kan tilføjes senere, hvis kunden ønsker kommentar til klarmelding - så virker dette ud af boksen.
                            // handleCommentChange={handleKommentarChange}
                        />
                        }
                        defaultOpen
                    />
                    , document.body)
            }
        </>
    );
}
