import React, {ReactFragment, useState} from 'react'
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {NotificationModule} from "ditmer-embla";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {SlideIn} from "../../../../core/components/slideIn/slideIn";
import {UddannelsesplanModel} from "../uddannelsesplanModel";
import {CreateUddannelsesplan} from "./createUddannelsesplan";
import {Uddannelsesplan} from "./uddannelsesplan";
import InfoModal from 'core/components/modalMessage/infoModal';
import {ContextButtonInfo, ContextButtonMenu} from "../../../../core/components/contextMenu/contextButtonMenu";
import {EmblaIcons} from "../../../../core/emblaIcons";
import useLogbogApi from "../../../../core/hooks/useLogbogApi";
import {useAppSelector} from "../../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../../core/forloebSlice";
import {LinkPlacementEnum} from "../../../../core/components/link/LinkPlacementEnum";
import {HelpLinks} from "../../../../core/components/link/helpLinks";

type UddannelsesplanListProps = {
    uddannelsesplaner?: UddannelsesplanModel[];
    uddannelsesplanerUpdatedCallback: () => void;
}

export function UddannelsesplanList(props: UddannelsesplanListProps) {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const modalTarget = "uddannelsesplan-create-modal";

    const tilfoejUddannelsesplan = () => setCreateModalOpen(true);
    const { logbogExportApi } = useLogbogApi();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false)
    const uddannelsesplanOprettet = async () => {
        props.uddannelsesplanerUpdatedCallback();
        NotificationModule.showSuccess(Localizer.vejledningPage_uddannelsesplanCreated(), "");
    }

    const createContextMenuButtons = (): ContextButtonInfo[] => {
        let buttons = new Array<ContextButtonInfo>()
        buttons.push(new ContextButtonInfo(downloadUddannelsesplanerPdfs, Localizer.GetPdf(), EmblaIcons.Download));
        return buttons;
    }

    const downloadUddannelsesplanerPdfs = async () => {
        setIsLoadingPdf(true);
        await logbogExportApi.getPdfUddannelsesplaner(forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.id, forloebSliceState.forloebState.stillingId)
            .then(() => setIsLoadingPdf(false));
    }


    let uddannelsesplaner: ReactFragment;
    if (props.uddannelsesplaner && props.uddannelsesplaner.length > 0) {
        uddannelsesplaner = props.uddannelsesplaner?.map((uddannelsesplan, i) => {
            return (
                <React.Fragment key={i}>
                    <div role="button" className="card-list">
                        <Uddannelsesplan uddannelsesplan={uddannelsesplan}
                                         lastIndex={i+1 === props.uddannelsesplaner?.length}
                                         uddannelsesplanerUpdatedCallback={props.uddannelsesplanerUpdatedCallback}/>
                    </div>
                    <div className={"card-list-border"}/>
                </React.Fragment>
            )
        })
    } else {
        uddannelsesplaner = (
            <>
                <div role="button" className="card-list">
                    <div className="flex-container-column">
                         <div>
                            <h5 className="subtle">{Localizer.vejledningPage_ingenUddannelsesplanerOprettet()}</h5>
                        </div>
                        <div className="flex-container flex-align-c margin-top-s ">
                            <p className="small subtle">{Localizer.vejledningPage_opretUddannelsesplanISamarbejdeMedVejleder()}</p>
                        </div>
                    </div>
                </div>
                <div className={"card-list-border"}/>
            </>
        );
    }

    const infoModalId = "UddannelsesplanListInfoModal";

    return (
        <>
            <div className="card top-level-container">
                <div className="card-header">
                    <div className="card-title d-flex align-items-center">
                        <h3>{Localizer.global_uddannelsesplaner()}</h3>
                        <InfoModal id={infoModalId} text={Localizer.forloebpage_uddannelsesplanInfo()} />
                        <HelpLinks linkPlacement={LinkPlacementEnum.UddannelsesplanPage} additionalClasses={"margin-left-s"}/>
                    </div>
                    <div className="card-actions">
                        <ContextButtonMenu buttons={createContextMenuButtons()}
                                           useButtonIcons={true}
                                           border
                                           showLine={false}
                                           additionalClasses={"hamburger is-active margin-left-s margin-right-s"}
                                           isLoading={isLoadingPdf}
                                           loadingTooltipText={Localizer.gettingPdf()}
                        />
                        <button type="button" className="btn btn-default rounded-circle btn-icon"
                                aria-label="Primary"
                                onClick={() => tilfoejUddannelsesplan()}>
                            <EmblaIcon iconName="plus"/>
                        </button>
                    </div>
                </div>

                <div className="card-body padding-0">
                    {uddannelsesplaner}
                </div>

                {createModalOpen &&
                    <SlideIn
                        title={Localizer.uddannelsesplan_createUddannelsesplan()}
                        id={modalTarget}
                        actionText={Localizer.vejledningPage_creatingUddannelsesplan()}
                    actionFinishedCallback={() => uddannelsesplanOprettet()}
                    actionOnCloseCallback={() => setCreateModalOpen(false)}
                    bodyContent={<CreateUddannelsesplan modalId={modalTarget}/>}
                    defaultOpen={true}
                />
                }
            </div>
        </>
    );
}
