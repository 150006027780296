import {
    newBreadcrumb,
    setActions,
    setTitle,
    setTitlebarLinks,
} from "./titlebarSlice";
import {useEffect} from "react";
import {useAppDispatch} from "../../../app/hooks";

export class BreadCrumb {
    public text: string;
    public url: string;
}

export class TitlebarAction {
    public text: string;
    public url?: string;
    public dataTarget?: string;
}

export const SetTitleBar = (title: string,
                            breadcrumbs: Array<BreadCrumb>,
                            titleBarLinks?: React.ReactNode) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(newBreadcrumb(breadcrumbs));
        dispatch(setTitle(title));
        dispatch(setActions([]));
        dispatch(setTitlebarLinks(titleBarLinks));
    },[])

    useEffect(() => {
        return () => {
            //reset titlebar links on dismount to make sure links are not shown on other pages
            dispatch(setTitlebarLinks(undefined));
        }
    }, [dispatch]);
}

export const SetTitleBarWithActions = (title: string,
                                       breadcrumbs: Array<BreadCrumb>,
                                       titlebarActions: Array<TitlebarAction>) => {
    const dispatch = useAppDispatch();

    SetTitleBar(title, breadcrumbs);
    useEffect(() => {
        dispatch(setActions(titlebarActions));
    }, [])
}


export const SetTitleBarWithBredcrubsRefresh = (title: string,
                            breadcrumbs: Array<BreadCrumb>) => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(newBreadcrumb(breadcrumbs));
        dispatch(setTitle(title));
        dispatch(setActions([]));
    },[])

    useEffect(() => {
        dispatch(newBreadcrumb(breadcrumbs));
    },[breadcrumbs])
}
