import { EmblaIcons, EmblaIconsType } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { RoutePaths } from "infrastructure/routes";

export type MenuLinkState = {
    userId: string;
}

export type MenuLinkProps = {
    emblaIconName: EmblaIconsType;
    linkText: string;
    routePath: string;
    routePathsToHighlight?: string[];
    enableShowNotification?: boolean;
    showNotification?: boolean;
    showNotificationAmount?: number;
    linkState?: MenuLinkState;
}

class MenuLinkModel {
    constructor(menuLinkProps: MenuLinkProps, clickableOnMobile= true, shouldRender?: () => boolean) {
        this.MenuLinkProps = menuLinkProps;
        this.ClickableOnMobile = clickableOnMobile;
        this.ShouldRender = shouldRender;
    }

    public MenuLinkProps: MenuLinkProps;
    public ClickableOnMobile: boolean;
    public ShouldRender?: () => boolean;
}

const interneLinksMenu = new MenuLinkModel({
    emblaIconName: EmblaIcons.Link,
    linkText: Localizer.global_interneLinks(),
    routePath: RoutePaths.InterneLinks.url
});

const uddannelseslaegeOverviewMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.UserGroup,
    linkText: Localizer.global_uddannelseslaeger(),
    routePath: RoutePaths.UddannelseslaegeOverview.url
});

const kursusBrugerUddannelseslaegeOverviewMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.UserGroup,
    linkText: Localizer.global_uddannelseslaeger(),
    routePath: RoutePaths.KursisterOverview.url
});

const klarmeldingerMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.Tasks,
    linkText: Localizer.global_klarmeldinger(),
    routePath: RoutePaths.Klarmeldinger.url
});

const vejlederNotaterMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.Comment,
    linkText: Localizer.global_notater(),
    routePath: RoutePaths.VejlederNotater.url
});

const brugereMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.UserGroup,
    linkText: Localizer.brugere(),
    routePath: RoutePaths.Brugere.url
});

const evalueringsstatistikMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.Activity,
    linkText: Localizer.global_evalueringsstatistik(),
    routePath: RoutePaths.Evalueringsstatistik.url,
    routePathsToHighlight: [RoutePaths.Evalueringsstatistik.url, RoutePaths.CreateEvalueringsstatistik.url]
});

const forloebOverviewMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.List,
    linkText: Localizer.global_forloebOverview(),
    routePath: RoutePaths.forloebOverview.url
}, false);

const vejledereMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.UserGroup,
    linkText: Localizer.global_vejledere(),
    routePath: RoutePaths.Vejledere.url
});

function nyhederMenuLink(showNotification?: boolean, notificationCount?: number) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Feed,
        linkText: Localizer.global_nyheder(),
        routePath: RoutePaths.Nyheder.url,
        enableShowNotification: true,
        showNotification: showNotification,
        showNotificationAmount: notificationCount,
    })
};

const dataadministrationMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.Flip,
    linkText: Localizer.global_dataadministration(),
    routePath: RoutePaths.Dataadministration.url,
});

const fileAdministrationMenuLink = new MenuLinkModel({
    emblaIconName: EmblaIcons.Document,
    linkText: Localizer.global_files(),
    routePath: RoutePaths.FileAdministration.url,
});

function forloebMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Dashboard,
        linkText: Localizer.global_overview(),
        routePath: RoutePaths.Forloeb([uddannelseslaegeId]).url
    });
}

function kursusMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.ContentView,
        linkText: Localizer.global_kurser(),
        routePath: RoutePaths.Kurser([uddannelseslaegeId]).url
    });
}

function kompetencerMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Star,
        linkText: Localizer.global_kompetencer(),
        routePath: RoutePaths.Kompetencer({uddannelseslaege: uddannelseslaegeId}).url,
        routePathsToHighlight: [RoutePaths.EditKompetenceEvaluering()?.basePath ?? ""]
    });
}

function vejledningMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.DialogChat,
        linkText: Localizer.global_vejledning(),
        routePath: RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
        }).url
    });
}

function filerMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Document,
        linkText: Localizer.Files(),
        routePath: RoutePaths.Files(uddannelseslaegeId).url
    });
}

function notaterMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Comment,
        linkText: Localizer.global_notater(),
        routePath: RoutePaths.Notater(uddannelseslaegeId).url
    });
}

function brevskabelonMenuLink(uddannelseslaegeId: string) {
    return new MenuLinkModel({
        emblaIconName: EmblaIcons.Email,
        linkText: Localizer.global_letters(),
        routePath: RoutePaths.Brevskabeloner({ uddannelseslaege: uddannelseslaegeId }).url
    });
}

const addStateToMenuLink = (modelToExtend: MenuLinkModel, state: MenuLinkState) => {
    return new MenuLinkModel(
        {...modelToExtend.MenuLinkProps, linkState: state},
        modelToExtend.ClickableOnMobile,
        modelToExtend.ShouldRender,
    );
}

export {
    MenuLinkModel,
    uddannelseslaegeOverviewMenuLink,
    klarmeldingerMenuLink,
    vejlederNotaterMenuLink,
    brugereMenuLink,
    evalueringsstatistikMenuLink,
    forloebOverviewMenuLink,
    vejledereMenuLink,
    nyhederMenuLink,
    dataadministrationMenuLink,
    forloebMenuLink,
    kursusMenuLink,
    kompetencerMenuLink,
    vejledningMenuLink,
    notaterMenuLink,
    kursusBrugerUddannelseslaegeOverviewMenuLink,
    addStateToMenuLink,
    fileAdministrationMenuLink,
    filerMenuLink,
    brevskabelonMenuLink,
    interneLinksMenu
}
