import ClickableIcon from "core/components/clickableIcons/clickableIcon";
import Row from "core/components/divSpecializations/Row";
import { EmblaIcon } from "core/components/emblaIcon/emblaIcon";
import HtmlHeading from "core/components/titels/HtmlHeadings";
import Title from "core/components/titels/title";
import { EmblaIcons } from "core/emblaIcons";
import { Localizer } from "infrastructure/localization/localizer";
import { ForloebOverviewEmptyCard } from "pages/forloeb/forloebOverviews/forloebOverviewEmptyCard";
import BrevskabelonDto from "services/api/brevskabelon/models/brevskabelonDto";

const EmptyList = () => (
    <ForloebOverviewEmptyCard emptyText={Localizer.letterPage_letterListEmptyInfo()}>
        <p>{Localizer.letterPage_letterListEmptyInfo()}</p>
    </ForloebOverviewEmptyCard>
);


type BreveListProps = {
    isAscOrder: boolean;
    breve: BrevskabelonDto[];
    editOnClick?: (brevId: string) => void;
    downloadOnClick?: (brevId: string) => void;
    deleteOnClick?: (brevId: string) => void;
    onSortorderChange: (isAsc: boolean) => void;
}

const BreveList = ({isAscOrder, breve, editOnClick, downloadOnClick, deleteOnClick, onSortorderChange} : BreveListProps) => {

    const hasBreve = breve.length > 0;
    const rowWrapperClasses = "card-list card-list-border-bot";

    return (
        <>
            {hasBreve && 
                <>
                    <div className={rowWrapperClasses}>
                        <Row>
                            <Row.Col>
                                <div className="d-flex clickable-no-hover" onClick={(e) => onSortorderChange(!isAscOrder)}>
                                    <Title title={Localizer.titel()} heading={HtmlHeading.H4}/>
                                    <EmblaIcon iconName={isAscOrder ? EmblaIcons.ArrowDown : EmblaIcons.ArrowUp} additionalClasses="small"/>
                                </div>
                            </Row.Col>  
                        </Row>
                    </div>

                    {breve.map(({id, titel}) => 
                        <div key={id} className={rowWrapperClasses}>
                            <Row>
                                <Row.Col width={10}>
                                    <p>{titel}</p>
                                </Row.Col>
                                <Row.Col width={2}>
                                    <div className="d-flex">
                                            <div className="d-flex flex-align-r">
                                                {deleteOnClick &&
                                                    <ClickableIcon
                                                        className="margin-right-s"
                                                        onClick={() => deleteOnClick(id)}
                                                        iconName={EmblaIcons.Delete}
                                                        needConfirmation
                                                        aria={{
                                                            standardText: Localizer.aria_label_ClickTo(Localizer.global_toDelete()),
                                                            confirmText: Localizer.aria_label_ClickToConfirm(),
                                                            negateText: Localizer.aria_label_ClickToNegate()
                                                        }}
                                                    />
                                                }

                                                {downloadOnClick &&
                                                    <ClickableIcon
                                                        className="margin-right-s"
                                                        onClick={() => downloadOnClick(id)}
                                                        iconName={EmblaIcons.Download}
                                                        aria={{ standardText: Localizer.aria_label_ClickTo(Localizer.global_toGet()) }}
                                                    />
                                                }

                                                {editOnClick &&
                                                    <ClickableIcon
                                                        onClick={() => editOnClick(id)}
                                                        iconName={EmblaIcons.Edit}
                                                        aria={{ standardText: Localizer.aria_label_ClickTo(Localizer.global_edit()) }}
                                                    />
                                                }
                                            </div>
                                    </div>
                                </Row.Col>
                            </Row>
                        </div>
                    )}
                </>
            }

            {!hasBreve &&
                <div className="margin-top-m margin-left-m margin-right-m">
                    <EmptyList />
                </div>
            }
        </>
    )
}

export default BreveList;
