import {Localizer} from "../../../infrastructure/localization/localizer";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import React, {Fragment} from "react";
import {KompetenceEvalueringCard} from "./kompetenceEvalueringCard";
import {useAppSelector} from "../../../app/hooks";
import {kompetenceCardReducer, KompetenceCardState} from "../cards/kompetenceCardSlice";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import useUser from "core/hooks/useUser";
import {KompetenceEvalueringsskemaModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringsskemaModel";
import {appendCounter} from "../../../core/helpers/stringHelper";
import useLogbogApi from "../../../core/hooks/useLogbogApi";
import {CollapseTab} from "../../../core/components/Tabs/collapseTab";

export interface KompetenceEvalueringOverviewProps {
    kompetenceEvalueringsskema: KompetenceEvalueringsskemaModel;
    brugerMaalbeskrivelseId: string;
    deleteKompetenceEvaluering: (laegekompetenceEvalueringId: string) => void;

}

export const EmptyKompetenceEvalering = ({disableSubtext = false}: { disableSubtext?: boolean }) => {
    return (
        <>
            <h5 className={"subtle"}>{Localizer.IngenKompetencevurderinger()}</h5>
            {!disableSubtext &&
                <p className={"subtle small"}>{Localizer.IngenVejlederKompetencevurderinger()}</p>
            }
        </>
    )
}

export function KompetenceEvalueringOverview({kompetenceEvalueringsskema, deleteKompetenceEvaluering, brugerMaalbeskrivelseId}: KompetenceEvalueringOverviewProps) {
    const history = useHistory();
    const {currentUser} = useUser()
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const isReadOnly = kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId !== forloebSliceState.forloebState.brugerMaalbeskrivelseId;

    const {evalueringApi} = useLogbogApi()

    const laegeKanOpretteSkema = currentUser.IsLaege() &&
        kompetenceEvalueringsskema.laegerKanOprette;

    const canCreateKompetencevurdering = kompetenceEvalueringsskema.aktiv && (currentUser.IsAdminOrVus() || currentUser.IsUddannelsesansvarlig() || currentUser.IsVejleder() || laegeKanOpretteSkema) && !isReadOnly

    const createKompetenceEvaluering = async () => {
        return await evalueringApi.createKompetenceEvaluering(brugerMaalbeskrivelseId, kompetenceEvalueringsskema.kompetenceEvalueringsskemaId, kompetenceEvalueringsskema.privat);
    }

    const createCallback = () => {
        if (canCreateKompetencevurdering)
            createKompetenceEvaluering().then(x => {
                history.push(RoutePaths.EditKompetenceEvaluering({
                    uddannelseslaege: forloebSliceState.forloebState.brugerId,
                    kompetence: kompetenceCardState.activeLaegekompetenceMeta.kompetenceId,
                    kompetenceevaluering: x
                }).url)
            })
    }

    return (
        <div className={"flex-column margin-m "}>
            <div className={"card shadow-sm"}>
                <CollapseTab
                    id={kompetenceEvalueringsskema.kompetenceEvalueringsskemaId}
                    title={appendCounter(kompetenceEvalueringsskema.titel, kompetenceEvalueringsskema.kompetenceEvalueringer?.length)}
                    expandedDefault={true}
                    collapseTabAction={canCreateKompetencevurdering ? createCallback : undefined}
                    key={kompetenceEvalueringsskema.kompetenceEvalueringsskemaId}>
                    <div className={"card-body"}>
                        {kompetenceEvalueringsskema.kompetenceEvalueringer.sort((a, b) => a.indsendtDato < b.indsendtDato ? 1 : -1).map(kompetenceEvaluering =>
                            <KompetenceEvalueringCard
                                key={kompetenceEvaluering.laegekompetenceEvalueringId}
                                kompetenceEvaluering={kompetenceEvaluering}
                                kompetenceId={kompetenceCardState.activeLaegekompetenceMeta.kompetenceId}
                                kompetenceBrugerId={kompetenceCardState.activeLaegekompetenceMeta.UserId}
                                indsendt={kompetenceEvaluering.indsendt}
                                deleteKompetenceEvaluering={deleteKompetenceEvaluering}/>
                        )}
                        {kompetenceEvalueringsskema.kompetenceEvalueringer?.length === 0 &&
                            <EmptyKompetenceEvalering/>
                        }
                    </div>

                </CollapseTab>

            </div>
        </div>
    )
}
