import { Localizer } from "infrastructure/localization/localizer";
import useStamdataApi from "core/hooks/useStamdataApi";
import { useCallback, useState } from "react";
import DynamicButton from "core/components/button/dynamicButton";
import { EmblaIcons } from "core/emblaIcons";
import { createPortal } from "react-dom";
import { SlideIn } from "core/components/slideIn/slideIn";
import { EducationFilterResult } from "core/components/filter/components/educationFilter";
import { PlaceFilterResult } from "core/components/filter/components/placeFilter";
import { NotificationModule } from "ditmer-embla";
import { SlideInStateEnum, setSlideinState } from "core/components/slideIn/slideInSlice";
import { useAppDispatch } from "app/hooks";
import { ReactFileUploaderFile } from "core/components/fileUpload/reactFileUploader";
import GenericFileList from "./fileList";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import useUser from "core/hooks/useUser";
import FileRelations, { validateFileUploadeOrEdit } from "core/sharedmodels/file/fileRelations";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { useFileContext } from "../context/fileContextProvider";
import FileFilters, { emptyEducationFilterValues, emptyPlaceFilterValues } from "./fileFilters";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import { FileData } from "services/api/stamdataMappe/FoldersModel";
import { UserModel } from "services/account/userModel";
import { FileArchiveEnum } from "core/sharedmodels/fileMetaData/fileArchiveEnum";

const getInitialEducationValuesByPermissions = (permissions: UserFilterPermissions) : EducationFilterResult => {
    return {
        ...emptyEducationFilterValues,
        specialer: permissions.specialeIds.length === 1 ? permissions.specialeIds : []
    }
}

const getInitialPlaceValuesByPermissions = (permissions: UserFilterPermissions, currentUser: UserModel) : PlaceFilterResult => {

    if(currentUser.IsAdminOrVus()) return emptyPlaceFilterValues;

    const isKonsulentForLaegeligVidereuddannelse = currentUser.IsKonsulentForLaegeligVidereuddannelse()
    const isUaoOrSekretaer = currentUser.IsUddannelsesansvarlig() || currentUser.IsSekretaer();
    const isLedelse = currentUser.IsLedelse();

    return {
        ...emptyPlaceFilterValues,
        regioner: isKonsulentForLaegeligVidereuddannelse && permissions.regionIds.length === 1 ? permissions.regionIds : [],
        omraader: isKonsulentForLaegeligVidereuddannelse && permissions.omraadeIds.length === 1 ? permissions.omraadeIds : [],
        praksiser: isUaoOrSekretaer && permissions.praksisIds.length === 1 ? permissions.praksisIds : [],
        sygehuse: isLedelse && permissions.sygehusIds.length === 1 ? permissions.sygehusIds : [],
        afdelinger: isUaoOrSekretaer &&  permissions.afdelingIds.length === 1 ? permissions.afdelingIds : [],
    }
}

const UddannelsesstedFileCreateSlideIn = ({permissions} : {permissions: UserFilterPermissions}) => {

    const filterModalTarget = "fileFilterSlideIn";
    const dispatch = useAppDispatch();

    const { currentUser } = useUser();
    const { stamdataFileApi } = useStamdataApi();
    const { state, setFiles } = useFileContext();

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<ReactFileUploaderFile[]>([]);
    const [placeFilterResult, setPlaceFilterResult] = useState<PlaceFilterResult>(emptyPlaceFilterValues);
    const [educationFilterResult, setEducationFilterResult] = useState<EducationFilterResult>(emptyEducationFilterValues);

    const setFilterModalAsClosed = () => setFilterModalOpen(false);

    const closeSlideIn = useCallback(() => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: filterModalTarget}))
    }, [dispatch]);

    const getFileRelations = useCallback(() : FileRelations => {
        return {
            uddannelseIds: educationFilterResult.uddannelser,
            specialeIds: educationFilterResult.specialer,
            regionIds: placeFilterResult.regioner,
            omraadeIds: placeFilterResult.omraader,
            praksisIds: placeFilterResult.praksiser,
            sygehusIds: placeFilterResult.sygehuse,
            afdelingIds: placeFilterResult.afdelinger,
        };
    }, [educationFilterResult.specialer, educationFilterResult.uddannelser, placeFilterResult.afdelinger, placeFilterResult.omraader, placeFilterResult.praksiser, placeFilterResult.regioner, placeFilterResult.sygehuse]);

    const onUploadClicked = useCallback(async () => {
        const fileRelations = getFileRelations();
        const isValid = validateFileUploadeOrEdit(fileRelations);
        if (!isValid) {
            NotificationModule.showError(Localizer.forloebEdit_FileUploadError(), "");
            return;
        }

        Promise.all(uploadedFiles.filter(f => !!f.file).map(async f =>
            stamdataFileApi.uploadFile(f.file!, FileArchiveEnum.UddannelsesstedFil, fileRelations)
        )).then((uploadFiles: FileData[]) => {
            setFiles([...state.files, ...uploadFiles].sortBy(x => x.fileMetadata.fileName));
            NotificationModule.showSuccess(Localizer.forloebEdit_FileUploadedSuccess(), "");
            setUploadedFiles([]);
            closeSlideIn();
        });

    }, [closeSlideIn, getFileRelations, setFiles, stamdataFileApi, state.files, uploadedFiles]);

    const handleFileUploadedCallback = useCallback((result: ReactFileUploaderFile[]) => {
        setUploadedFiles((prev) => [...prev, ...result.map(x => {
            x.fileMetadata.fileName = x.file?.name ?? "";
            x.fileMetadata.createdAt = new Date();
            x.fileMetadata.createdByName = currentUser.Name;
            return x;
        })]);
    }, [currentUser.Name]);

    const handlefileDeletedCallback = useCallback((result: FileMetadata) => {
        setUploadedFiles((prev) => prev.filter(f => f.fileMetadata.fileGuid !== result.fileGuid));
    }, []);

    const hasFiles = uploadedFiles.length > 0;

    return (
        <div>

            <div className="padding-left-m">
                <DynamicButton onClick={() => setFilterModalOpen(true)}>
                    <DynamicButton.TextIconItem
                        iconName={EmblaIcons.Plus}
                        text={Localizer.filePage_uploadFile()}
                    />
                </DynamicButton>
            </div>

            {filterModalOpen &&
                createPortal(
                    <SlideIn
                        id={filterModalTarget}
                        title={Localizer.global_uploadFile()}
                        actionFinishedCallback={setFilterModalAsClosed}
                        actionOnCloseCallback={setFilterModalAsClosed}
                        bodyContent={
                            <div>
                                <GenericFileList
                                    titleText={Localizer.global_filesFromEducationPlace()}
                                    files={uploadedFiles.map(f => f.fileMetadata)}
                                    fileUploadedCallback={handleFileUploadedCallback}
                                    fileDeletedCallback={handlefileDeletedCallback}
                                    fileLocation={FileLocationEnum.Stamdata}
                                    fileTypeEnum={FileArchiveEnum.UddannelsesstedFil}
                                    skipAutomaticFileUpload
                                    allwaysShowTitle
                                    disableSubTitle
                                />

                                <div className="margin-top-m">
                                    <FileFilters
                                        disable={!hasFiles}
                                        permissions={permissions}
                                        onPlaceFilterResult={(result) => setPlaceFilterResult(result)}
                                        onEducationFilterResult={(result) => setEducationFilterResult(result)}
                                        initialSelectedPlaceValues={getInitialPlaceValuesByPermissions(permissions, currentUser)}
                                        initialSelectedEducationValues={getInitialEducationValuesByPermissions(permissions)}
                                    />
                                </div>

                            </div>
                        }
                        footerContent={
                            <>
                                <DynamicButton onClick={() => closeSlideIn()}>
                                    <DynamicButton.TextIconItem
                                        iconName={EmblaIcons.Close}
                                        text={Localizer.global_cancel()}
                                    />
                                </DynamicButton>

                                <DynamicButton isPrimary onClick={onUploadClicked} >
                                    <DynamicButton.TextIconItem
                                        iconName={EmblaIcons.Plus}
                                        text={Localizer.filePage_uploadFile()}
                                    />
                                </DynamicButton>
                            </>

                        }
                        defaultOpen={true}
                    />
                    , document.body
                )
            }

        </div>
    );
}

export default UddannelsesstedFileCreateSlideIn;
