import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import {FoldersResponseModel} from "./FoldersResponseModel";
import { MappestrukturModel, addFileDataToMappestruktur, removeFileDataFromnMappestruktur } from "./MappestrukturModel";
import FileRelations from "core/sharedmodels/file/fileRelations";

export type FileData = FileRelations & {
    id: string;
    fileMetadata: FileMetadata;
    maalbeskrivelseId: string;
    regionIds: string[];
    alleRegioner: boolean;
};

export const BuildFileData = (file: FileData) => {
    file.fileMetadata.createdAt = new Date(file.fileMetadata.createdAt);
    return file;
}

export type FoldersModel = {
    filerUdenMappe: FileData[];
    mapperMedIndhold: MappestrukturModel[];
}

export const Build = (responseModel: FoldersResponseModel): FoldersModel => {
    responseModel.mapperMedIndhold.forEach(x => x.files?.forEach(y => BuildFileData(y)));
    responseModel.filerUdenMappe.forEach(f => BuildFileData(f))

    return {
        filerUdenMappe: responseModel.filerUdenMappe,
        mapperMedIndhold: responseModel.mapperMedIndhold
    };
}

export const editFileInMappeStruktur = (filesAndFolders: FoldersModel, updatedFileData: FileData, prevFileData: FileData) : FoldersModel => {
    const updateId = updatedFileData.fileMetadata.id;

    const wasInFolderLessBeforeEdit = !!filesAndFolders.filerUdenMappe.find(x => x.fileMetadata.id === updateId);
    const isInFolderLessAfterEdit = !updatedFileData.fileMetadata.mappeId;

    if(wasInFolderLessBeforeEdit || isInFolderLessAfterEdit) {
        filesAndFolders.filerUdenMappe = [
            ...filesAndFolders.filerUdenMappe.filter(x => x.fileMetadata.id !== updateId),
            ...isInFolderLessAfterEdit ? [updatedFileData] : []
        ]
    }

    if(!wasInFolderLessBeforeEdit) {
        const { removed, mappestruktur } = removeFileDataFromnMappestruktur(filesAndFolders.mapperMedIndhold, prevFileData);
        if(removed)
            filesAndFolders.mapperMedIndhold = mappestruktur;
    }

    if(!isInFolderLessAfterEdit) {
        const { added, mappestruktur } = addFileDataToMappestruktur(filesAndFolders.mapperMedIndhold, updatedFileData);
        if(added)
            filesAndFolders.mapperMedIndhold = mappestruktur;
    }

    return filesAndFolders;
}

export const deleteFileFromMappeStruktur = (filesAndFolders: FoldersModel, deletedFile: FileData) : FoldersModel => {
    const toDeleteId = deletedFile.fileMetadata.id;
    const isInFolderless = !!filesAndFolders.filerUdenMappe.find(x => x.fileMetadata.id === toDeleteId);
    if(isInFolderless) {
        filesAndFolders.filerUdenMappe = filesAndFolders.filerUdenMappe.filter(x => x.fileMetadata.id !== toDeleteId);
    } else {
        const { removed, mappestruktur } = removeFileDataFromnMappestruktur(filesAndFolders.mapperMedIndhold, deletedFile);
        if(removed)
            filesAndFolders.mapperMedIndhold = mappestruktur;
    }

    return filesAndFolders;
}

export const toFileRalations = (fileData: FileData) : FileRelations => {
    return {
        uddannelseIds: fileData.uddannelseIds,
        specialeIds: fileData.specialeIds,
        regionIds: fileData.regionIds,
        omraadeIds: fileData.omraadeIds,
        praksisIds: fileData.praksisIds,
        sygehusIds: fileData.sygehusIds,
        afdelingIds: fileData.afdelingIds,
    };
}

