import { Field, ErrorMessage, useField } from "formik";
import React, { useCallback } from "react";
import { ValidationInputProps } from "../validationModel";
import { ProcessValidationInput } from "../ValidationInputHelper";
import { CheckboxSwitch } from "../../checkbox/checkboxSwitch";
import classNames from "classnames";

type ValidationSwitchProps = ValidationInputProps & React.HTMLProps<HTMLInputElement> & {
    additionalSliderClasses?: string;
    noMarginBottom?: boolean;
    onClickCallback?: () => void;
    onChangeCallback?: (e: React.ChangeEvent<any>) => void;
}

export const ValidationSwitch = ({onClickCallback, noMarginBottom = false, ...props}: ValidationSwitchProps) => {
    const [field, meta] = useField<boolean>(props.model.htmlName);
    const validationInputResult = ProcessValidationInput(meta.error)

    const handleOnClick = useCallback(() => {
        if(onClickCallback) onClickCallback();
    }, [onClickCallback]);

    const onChange = (e: React.ChangeEvent<any>) => {
        if(props.onChangeCallback)
            props.onChangeCallback(e);

        field.onChange(e);
    }

    const input = <Field
        id={props.inputId}
        type="checkbox"
        name={field.name}
        className={`form-control switch ${meta.error && meta.touched ? validationInputResult.IsWarning ? "input-warning" : "input-validation-error" : ""}`}
        placeholder={props.model.placeholder}
        checked={field.value}
        disabled={props.readOnly ?? false}
        onClick={handleOnClick}
        onChange={onChange}
    />;

    return (
        <>
            <div className={classNames("form-group", !noMarginBottom && "margin-bottom-m")}>
                <CheckboxSwitch
                    id={props.inputId}
                    label={props.model.label}
                    labelInfoTooltipText={props.labelInfoTooltipText}
                    inputHtml={input}
                    additionalSliderClasses={props.additionalSliderClasses}
                    additionalSwitchContainerClasses="ml-auto align-self-center mb-0"
                />

                <ErrorMessage name={props.model.htmlName}>
                    {() => <span className={`errorMessage ${validationInputResult.IsWarning ? "text-warning" : "field-validation-error"}`}>{validationInputResult.Message}</span>}
                </ErrorMessage>
            </div>
        </>
    );
}

