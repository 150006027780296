import React, {ReactNode} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

export interface LaegeligBeskaeftigelseTextProps {
    uddannelseTypeEnum: UddannelseTypeEnum;
}

export function LaegeligBeskaeftigelseText(props: LaegeligBeskaeftigelseTextProps) {


    function getBeskaeftigelseBeskrivelseSelvstaendigtVirke(): ReactNode {
        return (
            <>
                <p className="font-weight-bold">{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseOverskrift()}</p>
                <ul>
                    <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktNul()}</li>
                    <li className="list-unstyled">
                        <ul>
                            <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktNul()}</li>
                            <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktEt()}</li>
                            <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTo()}</li>
                            <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTre()}</li>
                        </ul>
                    </li>
                    <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktEt()}</li>
                    <li>{Localizer.ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktTo()}</li>
                </ul>
            </>
        )
    }

    function getBeskaeftigelseBeskrivelseSpeciallaegeanerkendelse(): ReactNode {
        return (
            <>
                <p className="font-weight-bold">{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseOverskrift()}</p>
                <ul>
                    <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktNul()}</li>
                    <li className="list-unstyled">
                        <ul>
                            <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktNul()}</li>
                            <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktEt()}</li>
                            <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTo()}</li>
                            <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTre()}</li>
                            <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktFire()}</li>
                        </ul>
                    </li>
                    <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktEt()}</li>
                    <li>{Localizer.ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktTo()}</li>
                </ul>
            </>
        )
    }

    return (
        <>
            {props.uddannelseTypeEnum == UddannelseTypeEnum.KBU ? getBeskaeftigelseBeskrivelseSelvstaendigtVirke() : getBeskaeftigelseBeskrivelseSpeciallaegeanerkendelse()}
        </>
    )
}
