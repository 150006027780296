import React, {useCallback, useEffect, useState} from "react";
import './profileImage.scss'
import classNames from "classnames";
import Cropper, {Area} from "react-easy-crop";
import DynamicButton from "../button/dynamicButton";
import {EmblaIcons} from "../../emblaIcons";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UserProfileAvatar} from "../userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import {Event} from "../../infrastructure/Event";
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import useStamdataApi from "../../hooks/useStamdataApi";
import useUser from "../../hooks/useUser";
import {UserProfileModel} from "./userProfileModel";

export const UserProfileImage = ({...props}: { user: UserProfileModel }) => {
    const [user, setUser] = useState<UserProfileModel>(props.user)

    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState<{ fileData: string, fileName: string }>();
    const [croppedImgFile, setCroppedImgFile] = useState<File>();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [uploadedImageId, setUploadedImageId] = useState<string>();

    const {currentUser, updateCurrentUserProfileImage} = useUser();
    const isCurrentUser = currentUser.UserId === props.user.userId;

    const {stamdataFileApi} = useStamdataApi();

    const showCroppedImage = useCallback(async () => {
        const getCroppedImg = async (imgUrl: string, pixelCrop: Area): Promise<File> => {
            const image = await createImage(imgUrl);

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (!ctx) {
                throw new Error("canvas context is null");
            }

            // set canvas size to match the bounding box
            canvas.width = image.width;
            canvas.height = image.height;

            // draw rotated image
            ctx.drawImage(image, 0, 0);

            // croppedAreaPixels values are bounding box relative
            // extract the cropped image using these values
            if (pixelCrop.width > 0 && pixelCrop.height > 0) {
                const data = ctx.getImageData(
                    Math.round(pixelCrop.x),
                    Math.round(pixelCrop.y),
                    Math.round(pixelCrop.width),
                    Math.round(pixelCrop.height),
                );
                // set canvas width to final desired crop size - this will clear existing context
                canvas.width = pixelCrop.width;
                canvas.height = pixelCrop.height;

                // paste generated rotate image at the top left corner
                ctx.putImageData(data, 0, 0);
            }


            // As a blob
            const objectUrl = await new Promise((resolve, _) => {
                canvas.toBlob((file) => {
                    if (file === null)
                        return;
                    if (!imageSrc?.fileName)
                        return;

                    const newFile = new File([file], imageSrc.fileName, {type: "image/jpeg"});
                    resolve(newFile);
                }, "image/jpeg");
            });

            return objectUrl as File;
        };

        if (croppedAreaPixels && imageSrc) {
            const imgCropBlob = await getCroppedImg(
                imageSrc.fileData,
                croppedAreaPixels,
            );
            setCroppedImgFile(imgCropBlob);
        }

    }, [croppedAreaPixels, imageSrc]);

    const onCropComplete = useCallback((_, croppedAreaPix) => {
        setCroppedAreaPixels(croppedAreaPix);
    }, []);

    const createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener(Event.Load, () => resolve(image));
            image.addEventListener(Event.Error, (error) => reject(error));
            image.src = url;
        });

    const submitProfilePicture = async () => {
        if (croppedImgFile && uploadedImageId && isCurrentUser) {
            await stamdataFileApi.uploadCroppedFile(props.user.userId, croppedImgFile, uploadedImageId)
                .then((result) => {
                    updateCurrentUserProfileImage(result);
                    setUser((prev) => ({...prev, profileImageId: result}));
                })
                .finally(() => {
                    setCroppedImgFile(undefined)
                    setImageSrc(undefined)
                });
        }
    };

    const fileUploadedCallback = useCallback(async (file: ReactFileUploaderFile) => {
        const fileData = file.file;
        if (fileData) {
            const fileStringData = (await readFile(fileData)) as string
            setImageSrc({fileData: fileStringData, fileName: file.fileMetadata.fileName})
            setUploadedImageId(file.fileMetadata.id);
        }
    }, []);

    const cancelImageUpload = useCallback(async () => {
        setCroppedImgFile(undefined)
        setImageSrc(undefined);
        if (uploadedImageId)
            await stamdataFileApi.deleteFile(uploadedImageId);
    }, [stamdataFileApi, uploadedImageId])

    const deleteImageCallback = async () => {
        if (user.profileImageId && isCurrentUser) {
            await stamdataFileApi.deleteFile(user.profileImageId);
            updateCurrentUserProfileImage(undefined);
            setUser((prev) => ({...prev, profileImageId: undefined}));
        }
    }

    useEffect(() => {
        showCroppedImage();
    }, [croppedAreaPixels, showCroppedImage]);

    const readFile = (file: File)  =>  {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener(Event.Load, () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

    return (
        <>
            {imageSrc ?
                <div className={"flex-container-column flex-grow padding-m"}>
                    <div className={classNames("crop-container")}>
                        <Cropper
                            classes={{containerClassName: "crop-container-border"}}
                            image={imageSrc.fileData}
                            crop={crop}
                            zoom={zoom}
                            zoomSpeed={0.5}
                            maxZoom={100}
                            aspect={1}
                            cropShape="round"
                            showGrid={false}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                    <div className={classNames("margin-top-m margin-bottom-m flex-center ")}>
                        <DynamicButton onClick={() => submitProfilePicture()}>
                            <DynamicButton.TextIconItem iconName={EmblaIcons.Save} text={Localizer.global_save()}/>
                        </DynamicButton>
                        <DynamicButton onClick={cancelImageUpload} additionalClasses={"margin-left-m"}>
                            <DynamicButton.TextIconItem iconName={EmblaIcons.Close} text={Localizer.global_luk()}/>
                        </DynamicButton>
                    </div>
                </div>
                :
                <>
                    <div className="col-sm-12 flex-row-center">
                        <UserProfileAvatar
                            avoidHoverTooltip={true}
                            isCurrentUser={isCurrentUser}
                            userProfileAvatarModel={new UserProfileAvatarModel(user.userId, user.getFullName(), user.initialer, undefined, user.email, user.profileImageId)}
                            fileUploadedCallback={fileUploadedCallback}
                            displayEmail={user.email}
                            deleteImageCallback={deleteImageCallback}
                        />
                    </div>
                </>
            }
        </>
    );
}
