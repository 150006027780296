import React, {useEffect, useState} from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import {ValidationDropdown} from "../../core/components/validation/components/validationDropdown";
import {nameof} from "ts-simple-nameof";
import useStamdataApi from "../../core/hooks/useStamdataApi";
import {DropdownOption} from "../../core/components/dropdown/dropdown";
import {LinkInputModel} from "./LinkInputModel";
import {toDropdownOptions} from "../../core/sharedmodels/forSelectModel";
import {Loading} from "../../core/components/loading/loading";
import {ValidationInput} from "../../core/components/validation/components/validationInput";
import useEnumDropdownOptions from "../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {RolleTitelEnum, UserRoleTitleToString} from "../../infrastructure/enums/rolleTitelEnum";
import {LinkPlacementEnum, LinkPlacementEnumToString} from "../../core/components/link/LinkPlacementEnum";
import {FormikProps} from "formik";

type HelpLinkCrudFieldsProps = {
    formik: FormikProps<LinkInputModel>;
}

export function HelpLinkCrudFields({formik}: HelpLinkCrudFieldsProps) {
    const {stamdataMappeApi} = useStamdataApi();
    const [isLoading, setIsLoading] = useState(true);

    const [mappeDropdownOptions, setMappeDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const [rolleTitelDropdownOptions] = useEnumDropdownOptions<RolleTitelEnum>(RolleTitelEnum, UserRoleTitleToString);
    const [linkPlacementOptions] = useEnumDropdownOptions<LinkPlacementEnum>(LinkPlacementEnum, LinkPlacementEnumToString);

    useEffect(() => {
        const fetch = async () => {
            const selectModels = await stamdataMappeApi.getForSelects();
            return toDropdownOptions(selectModels);
        }
        fetch().then((result) => {
            setMappeDropdownOptions(result)
            setIsLoading(false);
        });
    }, [stamdataMappeApi])

    return (
        <Loading isLoading={isLoading} text={Localizer.global_gettingFolders()}>
            <ValidationDropdown
                model={{
                    label: Localizer.global_folder(),
                    placeholder: Localizer.global_folder(),
                    htmlName: nameof<LinkInputModel>(x => x.mappeId)
                }}
                options={mappeDropdownOptions}
                formikProps={formik}
            />
            <ValidationInput
                model={{
                    label: Localizer.helpPage_link(),
                    placeholder: Localizer.helpPage_link(),
                    htmlName: nameof<LinkInputModel>(x => x.link)
                }}
                inputId={nameof<LinkInputModel>(x => x.link)}
                formikProps={formik}
            />
            <ValidationInput
                model={{
                    label: Localizer.text(),
                    placeholder: Localizer.text(),
                    htmlName: nameof<LinkInputModel>(x => x.linkTekst)
                }}
                inputId={nameof<LinkInputModel>(x => x.linkTekst)}
                formikProps={formik}
            />
            <ValidationInput
                model={{
                    label: Localizer.helpPage_tooltipTekst(),
                    placeholder: Localizer.helpPage_tooltipTekst(),
                    htmlName: nameof<LinkInputModel>(x => x.tooltipTekst)
                }}
                inputId={nameof<LinkInputModel>(x => x.tooltipTekst)}
                formikProps={formik}
            />
            <ValidationDropdown
                model={{
                    label: Localizer.helpPage_linkPlacement(),
                    placeholder: Localizer.helpPage_linkPlacement(),
                    htmlName: nameof<LinkInputModel>(x => x.linkPlacement)
                }}
                options={linkPlacementOptions}
                value={formik.values.linkPlacement}
                formikProps={formik}
            />
            <ValidationDropdown
                isMulti
                model={{
                    label: Localizer.roller(),
                    placeholder: Localizer.roller(),
                    htmlName: nameof<LinkInputModel>(x => x.rolleTitler)
                }}
                options={rolleTitelDropdownOptions}
                formikProps={formik}
            />
        </Loading>

    );
}
