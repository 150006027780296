import {getTranslation} from "./localizer";
import {LocalizerPageSpecifics} from "./localizerPageSpecifics";

export class LocalizerSpecifics extends LocalizerPageSpecifics {
    public static loginIn = () => getTranslation("loginIn", "Logger ind");
    public static forloebValgt = () => getTranslation("forloebValgt", "forløb valgt");
    public static filerValgt = () => getTranslation("filerValgt", "filer valgt");
    public static filValgt = () => getTranslation("filValgt", "fil valgt");
    public static valgt = () => getTranslation("valgt", "valgt");
    public static dato = () => getTranslation("dato", "Dato");
    public static datoPlaceholder = () => getTranslation("dato", "Vælg dato");
    public static startdato = () => getTranslation("startDato", "Startdato");
    public static startdatoPlaceholder = () => getTranslation("startdatoPlaceholder", "Vælg startdato");
    public static adgangIndtil = () => getTranslation("adgangIndtil", "Adgang indtil");
    public static slutdato = () => getTranslation("slutDato", "Slutdato");
    public static slutdatoPlaceholder = () => getTranslation("slutDatoPlaceholder", "Vælg slutdato");
    public static noResults = () => getTranslation("noResults", "Der blev ikke fundet nogen resultater");
    public static vaelgSygehus = () => getTranslation("vaelgSygehus", "Vælg sygehus");
    public static vaelgPraksis = () => getTranslation("vaelgPraksis", "Vælg praksis");
    public static vaelgLaegeinstitution = () => getTranslation("vaelgLaegeinstitution", "Vælg lægeinstitution");
    public static sygehus = () => getTranslation("sygehus", "Sygehus");
    public static sygehusPraksis = () => getTranslation("sygehusPraksis", "Sygehus/Praksis");
    public static laegeinstitution = () => getTranslation("laegeinstitution", "Lægeinstitution");
    public static sygehuse = () => getTranslation("sygehuse", "Sygehuse");
    public static ingenValgt = () => getTranslation("ingenValgt", "Ingen valgt");
    public static stilling = () => getTranslation("stilling", "Stilling");
    public static vaelgStilling = () => getTranslation("vaelgStilling", "Vælg stilling");
    public static vaelgAfdeling = () => getTranslation("vaelgAfdeling", "Vælg afdeling");
    public static afdeling = () => getTranslation("afdeling", "Afdeling");
    public static uddannelsessted = () => getTranslation("uddannelsessted", "Uddannelsessted");
    public static uddannelsesstedHeaderSplit = () => getTranslation("uddannelsessted", "Uddannelses sted");
    public static rolle = () => getTranslation("rolle", "Rolle");
    public static roller = () => getTranslation("roller", "Roller");
    public static ansaettelsesperiode = () => getTranslation("ansaettelsesperiode", "Ansættelsesperiode");
    public static ansaettelsesperiodeHeaderSplit = () => getTranslation("ansaettelsesperiode", "Ansættelses periode");
    public static ansaettelsesperiodeStartet = () => getTranslation("ansaettelsesperiodeStartet", "Ansættelsesperiode startet");
    public static ansaettelsesperiodeAfsluttet = () => getTranslation("ansaettelsesperiodeAfsluttet", "Ansættelsesperiode afsluttet");
    public static afdelingPlaceholder = () => getTranslation("afdelingPlaceholder", "Indtast afdeling");
    public static vaelgLaeger = () => getTranslation("vaelgLaeger", "Vælg læger");
    public static vaelgBruger = () => getTranslation("vaelgBruger", "Vælg bruger");
    public static vaelgBrugere = () => getTranslation("vaelgBrugere", "Vælg brugere");
    public static vaelgLaege = () => getTranslation("vaelgLaege", "Vælg læge");
    public static laeger = () => getTranslation("laeger", "Læger");
    public static laege = () => getTranslation("laege", "Læge");
    public static alleLaeger = () => getTranslation("alleLaeger", "Alle læger");
    public static alleKbuLaeger = () => getTranslation("alleKbuLaeger", "Alle KBU-læger");
    public static alleHoveduddannelsesLaeger = () => getTranslation("alleHoveduddannelsesLaeger", "Alle hoveduddannelseslæger");
    public static alleIntroduktionsLaeger = () => getTranslation("alleIntroduktionsLaeger", "Alle introduktionslæger");
    public static brugere = () => getTranslation("brugere", "Brugere");
    public static soegNavnEmailEllerFoedselsdag = () => getTranslation("soegNavnEmailEllerFoedselsdag", "Søg navn, email, fødselsdag");
    public static bruger = () => getTranslation("bruger", "Bruger");
    public static henterBrugere = () => getTranslation("henterBrugere", "Henter brugere");
    public static henterLinks = () => getTranslation("henterLinks", "Henter links");
    public static henterForloebData = () => getTranslation("henterForloebData", "Henter forløbdata");
    public static overfoerDataConfirmMessage = () => getTranslation("overfoerDataConfirmMessage", "Du er ved at overføre data fra et deaktiveret forløb til et forløb, som er synkroniseret til uddannelseslæge fra videreuddannelsen. Dette kan ikke fortrydes. Er du sikker på, at du vil foretage denne ændring?");
    public static markerHaandteretConfirmMessage = () => getTranslation("markerHaandteretConfirmMessage", "Du er ved at markere samtlige deaktiverede forløb som håndteret. Dette vil resultere i, at dette forløb ikke længere kan få overført data fra disse ved hjælp af denne side. Er du sikker på, at du vil foretage denne ændring?");
    public static visibleToEveryoneWithAccessToLaege = () => getTranslation("visibleToEveryone", "Alle med adgang til lægen");
    public static noAccessToVejledersamtale = () => getTranslation("noAccessToVejledersamtale", "Du har ikke adgang til denne vejledersamtale");
    public static visibleToJustMe = () => getTranslation("visibleToJustMe", "Kun mig");
    public static visibleToUddannelseslaegeAndVejleder = () => getTranslation("visibleToUddannelseslaegeAndVejleder", "Kun uddannelseslæge og vejleder");
    public static vejleder = () => getTranslation("vejleder", "Vejleder");
    public static soegLaege = () => getTranslation("soegLaege", "Søg på læge");
    public static startSoegningForAtFremsoegeLaeger = () => getTranslation("startSoegningForAtFremsoegeLaeger", "Start søgning for at fremsøge læger");
    public static soegKursusTitel = () => getTranslation("soegKursusTitel", "Søg på kursus titel");
    public static soegLaegeKriterier = () => getTranslation("soegLaegeKriterier", "Søg på navn, autorisations ID eller fødselsdato");
    public static tilfoejAfdeling = () => getTranslation("tilfoejAfdeling", "Tilføj afdeling");
    public static tilfoejUddannelsessteder = () => getTranslation("tilfoejUddannelsessteder", "Tilføj uddannelsessted");
    public static tilfoejAftale = () => getTranslation("tilfoejAftale", "Tilføj aftale");
    public static tilfoejBeskaeftigelse = () => getTranslation("tilfoejBeskaeftigelse", "Tilføj beskæftigelse");
    public static tilfoejFil = () => getTranslation("tilfoejFil", "Tilføj fil");
    public static godkendtDen = () => getTranslation("godkendtDen", "Godkendt den");
    public static godkendtMedMeritDen = () => getTranslation("godkendtMedMeritDen", "Godkendt med merit den");
    public static fjernAfdeling = () => getTranslation("fjernAfdeling", "Fjern afdeling");
    public static afdelingerTilknyttet = () => getTranslation("afdelingerTilknyttet", "Afdelinger tilknyttet");
    public static uddannelsesstederTilknyttet = () => getTranslation("uddannelsesstederTilknyttet", "Uddannelsessteder tilknyttet");
    public static uddannelsessteder = () => getTranslation("uddannelsessteder", "Uddannelsessteder");
    public static uddannelsesstedCountText = (count: number) => getTranslation("uddannelsesstedCountText", `${count === 1 ? "Uddannelsessted" : "Uddannelsessteder"}`);
    public static tilknyttetUddannelsessted = () => getTranslation("tilknyttetUddannelsessted", "Tilknyttet uddannelsessted");
    public static skalIndgaaIEvalueringsstatistik = () => getTranslation("skalIndgaaIEvalueringsstatistik", "Skal indgå i evalueringsstatistik");
    public static titel = () => getTranslation("titel", "Titel");
    public static rettigheder = () => getTranslation("rettigheder", "Rettigheder");
    public static rettighederPlaceholder = () => getTranslation("rettighederPlaceholder", "Vælg rettighed");
    public static uddannelsesplan = () => getTranslation("uddannelsesplan", "Uddannelsesplan");
    public static uddannelsesprogram = () => getTranslation("uddannelsesprogram", "Uddannelsesprogram");
    public static uddannelsesprogrammer = () => getTranslation("uddannelsesprogrammer", "Uddannelsesprogrammer");
    public static titelPlaceholder = () => getTranslation("titelPlaceholder", "Indtast titel");
    public static subjectPlaceholder = () => getTranslation("subjectPlaceholder", "Indtast emne");
    public static uddannelsesplanPlaceholder = () => getTranslation("uddannelsesplanPlaceholder", "Indtast uddannelsesplan");
    public static uddannelsesplanFejlRedigerInfo = () => getTranslation("uddannelsesplanFejlRedigerInfo", "Uddannelsesplanen kan ikke redigeres, da vejleder har erklæret sig enig.");
    public static vejledersamtaleFejlRedigerInfo = () => getTranslation("vejledersamtaleFejlRedigerInfo", "Det er ikke muligt at redigere en afholdt vejledersamtale.");
    public static referat = () => getTranslation("referat", "Referat");
    public static text = () => getTranslation("text", "Tekst");
    public static referatPlaceholder = () => getTranslation("referatPlaceholder", "Skriv eventuelt referat...");
    public static eventuelSpecifikationerPlaceholder = () => getTranslation("eventuelSpecifikationerPlaceholder", "Eventuelle specifikationer...");
    public static starttidspunkt = () => getTranslation("starttidspunkt", "Starttidspunkt");
    public static starttidspunktPlaceholder = () => getTranslation("starttidspunktPlaceholder", "Vælg starttidspunkt");
    public static sluttidspunkt = () => getTranslation("sluttidspunkt", "Sluttidspunkt");
    public static sluttidspunktPlaceholder = () => getTranslation("sluttidspunktPlaceholder", "Vælg sluttidspunkt");
    public static areYouSure = () => getTranslation("areYouSure", "Er du sikker?");
    public static vaelgUddannelse = () => getTranslation("vaelgUddannelse", "Vælg uddannelse");
    public static vaelgUddannelsesType = () => getTranslation("vaelgUddannelsesType", "Vælg uddannelsestype");
    public static uddannelsesType = () => getTranslation("uddannelsesType", "Uddannelsestype");
    public static vaelgForloeb = () => getTranslation("vaelgForloeb", "Vælg forloeb");
    public static vaelgKategori = () => getTranslation("vaelgKategori", "Vælg kategori");
    public static vaelgPostnummer = () => getTranslation("vaelgPostnummer", "Vælg postnummer");
    public static kanSesAf = () => getTranslation("kanSesAf", "Kan ses af...");
    public static getUserRole = () => getTranslation("getUserRole", "Henter brugerroller");
    public static validateVejleder = () => getTranslation("validateVejleder", "Valider vejledere");
    public static vejlederValidated = () => getTranslation("validateVejleder", "Vejledere valideret");
    public static lastvalidated = () => getTranslation("Lastvalidated", "Sidst valideret:");
    public static notValidated = () => getTranslation("notValidated", "Endnu ikke valideret");
    public static noNotat = () => getTranslation("noNotat", "Ingen notater");
    public static AlleAfdelinger = () => getTranslation("AlleAfdelinger", "Alle afdelinger");
    public static AlleSpecialer = () => getTranslation("AlleSpecialer", "Alle specialer");
    public static AllStatus = () => getTranslation("AllStatus", "Alle statuser");
    public static NaesteVejledersamtale = () => getTranslation("NaesteVejledersamtale", "Næste samtale");
    public static NyesteVejledersamtale = () => getTranslation("NyesteVejledersamtale", "Nyeste vejledersamtale");
    public static EmailTemplates = () => getTranslation("EmailTemplates", "Emailskabeloner");
    public static EmailTemplatesPlaceholder = () => getTranslation("EmailTemplatesPlaceholder", "Vælg Emailskabelon");
    public static Files = () => getTranslation("Files", "Filer");
    public static FilesFromEducationPlace = () => getTranslation("FilesFromEducationPlace", "Filer fra uddannelsesstedet");
    public static FileAttachments = () => getTranslation("FileAttachments", "Vedhæftede filer");
    public static AcceptedByVejleder = () => getTranslation("AcceptedByVejleder", "Accepteret af vejleder");
    public static goToEvaluering = () => getTranslation("goToEvaluering", "Gå til evaluering");
    public static GetPdf = () => getTranslation("GetPdf", "Hent PDF");
    public static gettingPdf = () => getTranslation("gettingPdf", "Henter PDF");
    public static generatingPdf = () => getTranslation("generatingPdf", "Generer PDF");
    public static GetExcel = () => getTranslation("GetExcel", "Hent Excel");
    public static ShowUdgaaedeVejledertilknytninger = () => getTranslation("ShowUdgaaedeVejledertilknytninger", "Vis udgåede vejledertilknytninger");
    public static ShowUdgaaedeVejledertilknytningerMobile = () => getTranslation("ShowUdgaaedeVejledertilknytningerMobile", "Udgåede");
    public static GettingExcel = () => getTranslation("GettingExcel", "Henter Excel");
    public static getExcelDisabledText = () => getTranslation("getExcelDisabledText", "Det er ikke muligt at hente excel udtræk på nuværende tidspunkt.");
    public static noFilteredForloeb = () => getTranslation("noFilteredForloeb", "Ingen forløb i det angivede filter. Fremsøg forløb for at foretage exceludtræk.");
    public static evalueringsfristenErOverskredet = () => getTranslation("evalueringsfristenErOverskredet", "Evalueringsfristen er overskredet, kontakt dit videreuddannelsessekretariat, hvis du vil have åbnet for evalueringsmuligheden.");
    public static stillingsnummer = () => getTranslation("stillingsnummer", "Stillingsnummer");
    public static stillingsnummerPlaceholder = () => getTranslation("stillingsnummerPlaceholder", "Vælg stillingsnummer");
    public static uddannelsestrin = () => getTranslation("uddannelsestrin", "Uddannelsestrin");
    public static Kompetencevurderinger = () => getTranslation("Kompetencevurderinger", "Kompetencevurderinger");
    public static Kompetencevurdering = () => getTranslation("Kompetencevurdering", "Kompetencevurdering");
    public static IngenKompetencevurderinger = () => getTranslation("IngenKompetencevurderinger", "Denne kompetence er endnu ikke vurderet");
    public static IngenKompetencevurderingerMultiple = () => getTranslation("IngenKompetencevurderingerMultiple", "Denne kompetence er endnu ikke vurderet");
    public static IngenVejlederKompetencevurderinger = () => getTranslation("IngenKompetencevurderinger", "Der er endnu ingen kompetencevurderinger");
    public static UdfyldtAf = () => getTranslation("UdfyldtAf", "Udfyldt Af");
    public static VejledersVurdering = () => getTranslation("VejledersVurdering", "Vejleders vurdering");
    public static KompetenceVurderingKladde = () => getTranslation("KompetenceVurderingKladde", "Kladde af kompetencevurdering");
    public static KompetenceVurderingLoading = () => getTranslation("KompetenceVurderingLoading", "Henter kompetencevurderinger");
    public static chooseOmraade = () => getTranslation("chooseOmraade", "Vælg område");
    public static chooseRegion = () => getTranslation("chooseRegion", "Vælg region");
    public static chooseRole = () => getTranslation("chooseRole", "Vælg rolle");
    public static chooseFolder = () => getTranslation("chooseFolder", "Vælg mappe");
    public static preview = () => getTranslation("preview", "Forhåndsvisning");


    public static SamtykkeTilbagekald = () => getTranslation("SamtykkeTilbagekald", "Tilbagekald samtykke");
    public static SamtykkeTilbagekaldInfo = () => getTranslation("SamtykkeTilbagekaldInfo", "Hvis du vælger at trække samtykke tilbage, kan du ikke bruge Uddannelseslaege.dk.\n\n Du vil blive logget ud. Hvis du senere vælger at give samtykke, logger du ind igen.");
    public static SamtykkeErklaering = () => getTranslation("SamtykkeErklaering", "I anledningen af Eu's persondataforordning (General Data Protection Regulation - GDPR) som finder anvendelse fra den 25. maj 2018, så informeres du hermed om, at Uddannelseslaege.dk tager højde for disse bestemmelser.\n" +
        "\n\u2022 Du har en profil i Uddannelseslaege.dk, da du enten er uddannelseslæge, uddannelsesgivende, kursusleder, kursusudbyder eller administrator i den lægelige videreuddannelse i Danmark\n" +
        "\n\u2022 Følgende oplysninger bliver registreret i forbindelse med brug af Uddannelseslaege.dk: Navn, cpr-nummer, autorisations id., mailadresse, kursusdeltagelser, mobiltelefonnummer & ansættelser. Formålet for uddannelseslæger er at modtage godkendelser af de uddannelsesgivende, som har adgang til uddannelseslægens profil, i løbet af speciallægeuddannelsen. Formålet for andre brugere er at give godkendelser eller administrere lægelig videreuddannelse.\n" +
        "\n\u2022 Retsgrundlaget for registreringen i Uddannelseslaege.dk er Bekendtgørelse om lægers kliniske basisuddannelse nr.1256 af 25/10/2007, Bekendtgørelse om uddannelse af speciallæger nr. 96 af 02/02/2018, Bekendtgørelse om speciallæger nr. 458 af 25/04/2019 samt aftale om Administration af Uddannelseslaege.dk 01/01/2024 vedtaget af Danske Regioner.\n" +
        "\n\u2022 Jf. systemets godkendelse, kan uddannelseslæger beholde deres profil i Uddannelseslaege.dk indtil 6 mdr. efter opnået speciallægeanerkendelse. Systemet sender herefter en påmindelse om at hente data ud af systemet, hvorefter profilen slettes fra systemet efter 6 mdr. Andre brugere slettes, når de ophører i deres funktion.\n" +
        "\n\u2022 Uddannelseslæger kan til en hver tid hente deres data ud af Uddannelseslaege.dk via funktionen ’Hent mine data’.\n" +
        "\n\u2022 Du kan altid kontakte dit lokale Videreuddannelsessekretariat hvis du har spørgsmål til dine registreringer.");
    public static validateVejlederBeskrivelse = () => getTranslation("validateVejlederBeskrivelse", "Hvis du har adgang til vejledere (kan se nogle aktive vejledere på vejleder-siden), så får du pop-up med validering hver 6. måned.");

    public static CookieConsentApprove = () => getTranslation("CookieConsentApprove", "Ja tak");
    public static CookieConsentDecline = () => getTranslation("CookieConsentDecline", "Nej tak");
    public static CookieConsentInfoWeUseCookiesFor = () => getTranslation("CookieConsentInfoWeUseCookiesFor", "Uddannelseslæge.dk og tredjepartstjenester bruger cookies til at forbedre brugeroplevelsen på Uddannelseslæge.dk og til indsamling af statistik.");
    public static CookieConsentInfoApproveMeans = () => getTranslation("CookieConsentInfoApproveMeans", "’Ja tak’ betyder, at du accepterer vores brug af cookies til statistik.");
    public static CookieConsentInfoDeclineMeans = () => getTranslation("CookieConsentInfoDeclineMeans", "’Nej tak’ betyder, at vi kun sætter de allermest nødvendige tekniske cookies for at huske dit valg, og for at Uddannelseslæge.dk kan fungere.");
    public static CookieConsentTilbagekaldInfo = () => getTranslation("CookieConsentTilbagekaldInfo", "Du vil til enhver tid kunne tilbagekalde dit samtykke på siden om cookies på Uddannelseslæge.dk, som du finder et link til nederst på hjemmesiden.");
    public static CookieConsentLinkToCookiesPage = () => getTranslation("CookieConsentLinkToCookiesPage", "Læs mere om brugen af cookies på Uddannelseslæge.dk");

    public static Fravaerskoder = () => getTranslation("Fravaerskoder", "Fraværskoder");
    public static Fravaerskode = () => getTranslation("Fravaerskode", "Fraværskode");
    public static Fravaersaarsag = () => getTranslation("Fravaersaarsag", "Fraværsårsag");
    public static SelectFravaerskoder = () => getTranslation("SelectFravaerskoder", "Vælg fraværskode");
    public static SaveFravaer = () => getTranslation("SaveFravaer", "Gem fravær");
    public static Fravaer = () => getTranslation("SaveFravaer", "Fravær");
    public static FravaerSaved = () => getTranslation("FravaerSaved", "Fraværet er gemt");
    public static EndDateChanged = () => getTranslation("FravaerSaved", "Slutdato for det eksisterende forløb er sat til dagen før påbegyndt fravær.");

    public static OrlovBarsel = () => getTranslation("OrlovBarsel", "Orlov ved barsel, Fravær");
    public static OrlovSygdom = ()  => getTranslation("OrlovSygdom", "Fravær pga. sygdom, Fravær");
    public static OrlovForskning = () => getTranslation("OrlovForskning", "Orlov ved forskning, Fravær");
    public static FravaerMerit = () => getTranslation("FravaerMerit", "Fravær pga. meritoverførsel, Fravær");
    public static FratraadtUddannelsesforloeb = () => getTranslation("FratraadtUddannelsesforloeb", "Fratrådt Uddannelsesforløb, Fravær");
    public static OrlovPersonligeAarsager = () => getTranslation("OrlovPersonligeAarsager", "Orlov af personlige årsager, Fravær");
    public static FravaerUdland = () => getTranslation("FravaerUdland", "Ansættelse i udland, Fravær");
    public static Orlov = () => getTranslation("Orlov", "Orlov");
    public static FravaerMidlertidig = () => getTranslation("FravaerMidlertidig", "Foreløbig fravær (retsbaseret)")

    public static exporter = () => getTranslation("Exporter", "Eksportér");
    public static sendmail = () => getTranslation("sendmail", "Send mail");
    public static tilfoejBruger = () => getTranslation("tilfoejBruger", "Tilføj bruger");
    public static exportToPdf = () => getTranslation("exportToPdf", "Exporter til PDF");

    public static kbuRundeSommer = () => getTranslation("kbuRundeSommer", "Sommer");
    public static kbuRundeVinter = () => getTranslation("kbuRundeVinter", "Vinter");
    public static kbuRundeIngen = () => getTranslation("kbuRundeIngen", "Ingen runde angivet");

    public static filterShouldAllMatch = () => getTranslation("filterShouldAllMatch", "Skal alle matche");
    public static filterDefaultInfo = () => getTranslation("filterDefaultInfo", "Der vises som standard læger 12 måneder tilbage i tid og 10 år frem i tid. Ønskes der visning af et større eller mindre tidsinterval, kan filtrering anvendes.");
    public static visOverlappende = () => getTranslation("visOverlappende", "Udvid overlappende bjælker");
    public static timeInterval = () => getTranslation("timeInterval", "Tidsinterval");
    public static forloebVarighed = () => getTranslation("forloebVarighed", "Varighed");
    public static forloebVarighedIMaaneder = (maaneder: number, dage: number) => getTranslation("forloebVarighedIMaaneder", `${maaneder} mdr. og ${dage} dage`);
    public static noCommentsMatchingQuestion = () => getTranslation("noCommentsMatchingQuestion", "Ingen kommentar til det valgte spørgsmålsnummer i filtret");
    public static noCommentsMatchingQuestions = () => getTranslation("noCommentsMatchingQuestions", "Ingen kommentarer til de valgte spørgsmålsnumre i filtret");

    public static linkToSupportpage = () => getTranslation("linkToSupportpage", "Link til supporthjemmeside");
    public static linkToGuidepage = () => getTranslation("linkToGuidepage", "Link til guide");
    public static guide = () => getTranslation("guide", "Guide");
    public static infoBeskedForloebMenuMobilVisning = () => getTranslation("infoBeskedForloebMenuMobilVisning", "Forløbsoverblikket er kun tilgængeligt via PC eller tablet.")

    public static notifikationer_MarkerAlleSomSet = () => getTranslation("notifikationer_MarkerAlleSomSet", "Markér alle som læst");
    public static notifikationer_IngenNotifikationer = () => getTranslation("notifikationer_IngenNotifikationer", "Du har ingen notifikationer");
    public static notifikationer_VisFlere = () => getTranslation("notifikationer_VisFlere", "Vis flere");
    public static notifikationer_VaelgNotifikationstype = () => getTranslation("notifikationer_VaelgNotifikationstype", "Vælg notifikationstype");
    public static notifikationer_Notifikationstype = () => getTranslation("notifikationer_Notifikationstype", "Notifikationstype");
    public static notifikationer_HenterNotifikationshistorik = () => getTranslation("notifikationer_HenterNotifikationshistorik", "Henter notifikationshistorik");
    public static notifikationer_GaaTilHistorik = () => getTranslation("notifikationer_GaaTilHistorik", "Gå til notifikationshistorik");
    public static notifikationer_Notifikationshistorik = () => getTranslation("notifikationer_Notifikationshistorik", "Notifikationshistorik");

    public static notifikationer_KompetenceKlarmeldt_Title = () => getTranslation("notifikationer_KompetenceKlarmeldt_Title", "Kompetence klarmeldt");
    public static notifikationer_KompetenceGodkendt_Title = () => getTranslation("notifikationer_KompetenceGodkendt_Title", "Kompetence godkendt");
    public static notifikationer_VejlederOprettet_Title = () => getTranslation("notifikationer_VejlederOprettet_Title", "Vejleder oprettet");
    public static notifikationer_VejlederAdgangAendret_Title = () => getTranslation("notifikationer_VejlederAdgangAendret_Title", "Vejleder adgang ændret");
    public static notifikationer_BrugerNyRolle_Title = () => getTranslation("notifikationer_BrugerNyRolle_Title", "Ny rolle tildelt");
    public static notifikationer_ForloebKanGodkendes_Title = () => getTranslation("notifikationer_ForloebKanGodkendes_Title", "Forløb kan godkendes");
    public static notifikationer_IndsendStpsDokumentation_Title = () => getTranslation("notifikationer_IndsendStpsDokumentation_Title", "Forbered dokumentation til STPS");
    public static notifikationer_NyhedPubliceret_Title = () => getTranslation("notifikationer_NyhedPubliceret_Title", "Nyhed");
    public static notifikationer_ForloebEvalueringIndsendt_Title = () => getTranslation("notifikationer_ForloebEvalueringIndsendt_Title", "Evaluering indsendt");
    public static notifikationer_AlleKurserIGruppeGodkendt_Title = () => getTranslation("notifikationer_AlleKurserIGruppeGodkendt_Title", "Klar til godkendelse");

    public static notifikationer_NotifikationsTypeEnumFallback = () => getTranslation("notifikationer_NotifikationsTypeEnumFallback", "Notifikation");
    public static notifikationer_NotifikationsIndstillinger = () => getTranslation("notifikationer_NotifikationsIndstillinger", "Notifikationsindstillinger");
    public static notifikationer_NotifikationsIndstillinger_SendEmailLabel = () => getTranslation("notifikationer_NotifikationsIndstillinger_SendEmail", "Få e-mails om denne notifikation")
    public static notifikationer_IngenNotifikationIndstillinger = () => getTranslation("notifikationer_IngenNotifikationIndstillinger", "Der er ingen tilgængelige notifikationsindstillinger på dine roller");
    public static notifikationer_NotifikationsIndstillingerBlevOpdateret = () => getTranslation("notifikationer_NotifikationsIndstillinger", "Indstillinger blev opdateret");
    public static notifikationer_MarkerSomLaestUlaest = () => getTranslation("notifikationer_MarkerSomLaestUlaest", "Markér enkelt som læst/ulæst");
    public static notifikationer_MarkerSomLaest = () => getTranslation("notifikationer_MarkerSomLaest", "Markér læst");
    public static notifikationer_MarkerSomUlaest = () => getTranslation("notifikationer_MarkerSomUlaest", "Markér ulæst");
    public static notifikationer_Laest = () => getTranslation("notifikationer_Laest", "Læst");
    public static notifikationer_Ulaest = () => getTranslation("notifikationer_Ulaest", "Ulæst");
    public static notifikationer_Faerdiggoer = () => getTranslation("notifikationer_Faerdiggoer", "Færdiggør");
    public static notifikationer_FaerdiggoerMarkering = () => getTranslation("notifikationer_FaerdiggoerMarkering", "Færdiggør markering");
    public static notifikationer_MarkeringSummaryTitel = () => getTranslation("notifikationer_MarkeringSummaryTitel", "Du har markeret følgende notifikationer:");
    public static notifikationer_OpdaterStatusSuccess = (selectedNotifications: number, laestUlaestTekst: string) => getTranslation("notifikationer_OpdaterStatusSuccess", `${selectedNotifications} notifikationer blev markeret som ${laestUlaestTekst}`);

    public static notifikationer_NyBrugerRolle_NotFoundTitel = () => getTranslation("notifikationer_NyBrugerRolle_NotFoundTitel", "Rolle ikke fundet");
    public static notifikationer_NyBrugerRolle_NotFoundMessage = () => getTranslation("notifikationer_NyBrugerRolle_NotFoundTitel", "Den tildelte rolle er enten fjernet eller tildelt på ny");

    public static notifikationer_IndsendStpsAnsoegning_NotFoundTitel = () => getTranslation("notifikationer_IndsendStpsAnsoegning_NotFoundTitel", "Ansøgning ikke fundet");
    public static notifikationer_IndsendStpsAnsoegning_NotFoundMessage = () => getTranslation("notifikationer_IndsendStpsAnsoegning_NotFoundMessage", "Ansøgningen er enten fjernet eller ikke oprettet endnu. Tilgå 'Forbered dokumentation til STPS' og forsøg igen.");

    public static dropdown_AngivVenligstXTegnMere = (remainingCharacters: number) => getTranslation("dropdown_AngivVenligstXTegnMere", `Angiv venligst ${remainingCharacters} tegn mere`);

    public static brevMergeFieldsType_forloeb_all = () => getTranslation("brevMergeFieldsType_forloeb_all", "Alle forløb");
    public static brevMergeFieldsType_forloeb_kbu = () => getTranslation("brevMergeFieldsType_forloeb_kbu", "Alle KBU forløb");
    public static brevMergeFieldsType_forloeb_hu = () => getTranslation("brevMergeFieldsType_forloeb_hu", "Alle hoveduddannelse forløb");
    public static brevMergeFieldsType_forloeb_intro = () => getTranslation("brevMergeFieldsType_forloeb_intro", "Alle introduktions forløb");

    public static brevMergeField_tekst_UserName = () => getTranslation("brevMergeField_tekst_UserName", "Brugerens navn");
    public static brevMergeField_tekst_UserEmail = () => getTranslation("brevMergeField_tekst_UserEmail", "Brugerens e-mail");
    public static brevMergeField_tekst_Addresse = () => getTranslation("brevMergeField_tekst_Addresse", "Adresse");
    public static brevMergeField_tekst_Cpr = () => getTranslation("brevMergeField_tekst_Cpr", "CPR-nummer");
    public static brevMergeField_tekst_DagsDato = () => getTranslation("brevMergeField_tekst_DagsDato", "Dags dato");
    public static brevMergeField_tekst_FuldeNavn = () => getTranslation("brevMergeField_tekst_FuldeNavn", "Fulde navn");
    public static brevMergeField_tekst_TelefonNr = () => getTranslation("brevMergeField_tekst_TelefonNr", "Telefonnummer");
    public static brevMergeField_tekst_DitNavn = () => getTranslation("brevMergeField_tekst_DitNavn", "Dit navn");
    public static brevMergeField_tekst_ArbejdsMail = () => getTranslation("brevMergeField_tekst_ArbejdsMail", "Arbejdsmail");
    public static brevMergeField_tekst_PrivatMail = () => getTranslation("brevMergeField_tekst_PrivatMail", "Privat mail");
    public static brevMergeField_tekst_SidsteForloeb = () => getTranslation("brevMergeField_tekst_SidsteForloeb", "Sidste forløb");
    public static brevMergeField_tekst_SenderEmail = () => getTranslation("brevMergeField_tekst_SenderEmail", "Afsenderens e-mail");

    public static aria_label_EditKompetence = () => getTranslation("aria_label_EditKompetence", "Ændre kompetence");
    public static aria_label_EditLeagekursus = () => getTranslation("aria_label_EditLeagekursus", "Ændre laegekursus");
    public static aria_label_ToggleLeftMenuHamburger = () => getTranslation("aria_label_ToggleLeftMenuHamburger", "Toggle venstre menu");
    public static aria_label_ShowNotifications = () => getTranslation("aria_label_ShowNotifications", "Vis notifikationer");
    public static aria_label_ExternalLink = () => getTranslation("aria_label_ExternalLink", "Eksternt link");
    public static aria_label_SetAsFavorite = () => getTranslation("aria_label_SetAsFavorite", "Vælg som favorit");
    public static aria_label_DeselectAsFavorite = () => getTranslation("aria_label_DeselectAsFavorite", "Fjern som favorit");
    public static aria_label_GetFile = () => getTranslation("aria_label_GetFile", "Hent fil");
    public static aria_label_GetNamedFile = (name: string) => getTranslation("aria_label_GetNamedFile", `Hent ${name}`);
    public static aria_label_GetNotificationForTitle = (title: string) => getTranslation("aria_label_GetNotificationForTitle", `Få notifikationer for ${title}`);
    public static aria_label_NamedAmountToShow = (name: string) => getTranslation("aria_label_AmountToShow", `Antal ${name} som vises`);
    public static aria_label_GoTo = () => getTranslation("aria_label_GoTo", "Gå til");
    public static aria_label_ClickTo = (action: string) => getTranslation("aria_label_ClickTo", `Klik for ${action}`);
    public static aria_label_ClickToNegate = () => getTranslation("aria_label_ClickToNegate", "Klik for at annullere");
    public static aria_label_ClickToConfirm = () => getTranslation("aria_label_ClickToConfirm", "Klik for at bekræfte");
}
