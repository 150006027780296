import classNames from 'classnames';
import React from 'react';
import Tooltip from '../tooltips/Tooltip';
import {EmblaIcon} from '../emblaIcon/emblaIcon';

type CheckboxProps = {
    id: string,
    label?: string;
    labelInfoTooltipText?: string;
    inputHtml?: JSX.Element;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    reverseTextAndSwitch?: boolean;
    defaultValue?: boolean;
    additionalSliderClasses?: string;
    additionalSwitchContainerClasses?: string;

    // For accessibility
    ariaLabel?: string;
}

export const CheckboxSwitch = ({ additionalSliderClasses, additionalSwitchContainerClasses, reverseTextAndSwitch, ...props }: CheckboxProps) => {

    const inputGroupClasses = classNames("input-group");
    const sliderClasses = classNames('slider', additionalSliderClasses);
    const switchContainerClasses = classNames("switch-container", additionalSwitchContainerClasses)

    const renderSwitch = () => (
        <label className={switchContainerClasses} aria-label={props.ariaLabel}>
            {
                props.inputHtml ??
                <input type="checkbox" className="switch input-success" checked={props.value} defaultChecked={props.defaultValue} onChange={props.onChange} id={props.id} />
            }
            <div className={sliderClasses}></div>
        </label>
    );

    const renderLabel = () => (
        <>
            <label className="mb-0 margin-right-s align-self-center" htmlFor={props.id} aria-label={props.ariaLabel}>
                {props.label}
            </label>
            {props.labelInfoTooltipText !== undefined &&
                <Tooltip className="text-truncate" title={props.labelInfoTooltipText}>
                    <EmblaIcon additionalClasses="subtle small" iconName={"info"} />
                </Tooltip>
            }
        </>
    );

    return (
        <div className={inputGroupClasses} style={{columnGap: "0.5rem"}}>
            {reverseTextAndSwitch ? (
                <>
                    {renderSwitch()}
                    {props.label && renderLabel()}
                </>
            ) : (
                <>
                    {props.label && renderLabel()}
                    {renderSwitch()}
                </>
            )}
        </div>
    );
}
