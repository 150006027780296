import React, {AriaRole, ReactNode, useState} from 'react';
import './asyncButton.scss';
import {Spinner} from "../spinner/spinner";
import useScreenResolution from 'core/hooks/useScreenResolution';

interface AsyncInputProps {
    clickFunction: () => Promise<void>;

    child?: ReactNode;
    buttonText?: string;
    waitText?: string;
    tooltipText?: string;
    disabled?: boolean;
    overrideClasses?: string;
    additionalClasses?: string;
    showWaitTextIfMobile?: boolean;

    // For accessibility
    role?: AriaRole | undefined; 
    ariaLabel?: string;
}

export function AsyncButton({showWaitTextIfMobile=false, ...props}: AsyncInputProps) {
    
    const { isMobile } = useScreenResolution();
    const [loading, setLoading] = useState(false);

    const buttonClicked = () => {
        setLoading(true);
        props.clickFunction().then(x => {
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            throw e;
        })
    }

    const defaultClasses = "btn btn-default";

    return (
        <>
            <button
                className={`${props.overrideClasses !== undefined ? props.overrideClasses : defaultClasses} ${props.additionalClasses ?? ""}`}
                onClick={buttonClicked}
                disabled={loading || props.disabled}
                data-tooltip={!!(!props.disabled && props.tooltipText)}
                data-html={!!(!props.disabled && props.tooltipText)}
                data-title={(!props.disabled && props.tooltipText) ? props.tooltipText : ""}
                role={props.role}
                aria-label={props.ariaLabel}
            >

                {loading &&
                    <div className={"flex-container justify-content-center"}>
                        <Spinner spinnerSize="extra-tiny-small"/>
                        {props.waitText && (!isMobile || (showWaitTextIfMobile && isMobile)) &&
                        
                            <div className='margin-left-s'>{props.waitText}</div>
                        }
                    </div>
                }

                {!loading &&
                    <>
                        {props.child &&
                            props.child
                        }

                        { props.buttonText &&
                            props.buttonText
                        }
                    </>
                }

            </button>
        </>
    );
}
