import useLogbogApi from "core/hooks/useLogbogApi";
import { VejledersamtaleEnum, VejledersamtaleModel } from "../vejledersamtaler/vejledersamtaleModel";
import { Localizer } from "infrastructure/localization/localizer";
import { useCallback, useEffect, useState } from "react";

const useVejledersamtaler = (forloebId: string, shouldFetch: boolean) => {
  const { vejledersamtaleApi  } = useLogbogApi();
  const [vejledersamtaler, setVejledersamtaler] = useState<VejledersamtaleModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadVejledersamtaleList = useCallback(async () => {
    if (!forloebId) {
      setVejledersamtaler([]);
      return;
    }

    setIsLoading(true);

    const samtalerFromApi = await vejledersamtaleApi.getVejledersamtalerByForloeb(forloebId);

    let placeholderVejledersamtaler = new Array<VejledersamtaleModel>();

    if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Introduktion)) {
        const introduktionplaceholder = VejledersamtaleModel.CreatePlaceholder(forloebId, VejledersamtaleEnum.Introduktion, Localizer.global_boerAfholdesIStartenAfForloebet());
        placeholderVejledersamtaler.push(introduktionplaceholder);
    }

    if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Justering)) {
        const justeringplaceholder = VejledersamtaleModel.CreatePlaceholder(forloebId, VejledersamtaleEnum.Justering, Localizer.global_boerAfholdesIndenTreMaaneder());
        placeholderVejledersamtaler.push(justeringplaceholder);
    }

    if (!samtalerFromApi.some(s => s.vejledersamtaleType === VejledersamtaleEnum.Afsluttende)) {
        const afsluttendeplaceholder = VejledersamtaleModel.CreatePlaceholder(forloebId, VejledersamtaleEnum.Afsluttende, Localizer.global_boerAfholdesIndenForloebetErAfsluttet());
        placeholderVejledersamtaler.push(afsluttendeplaceholder);
    }

    //ordering by type: 0: Introduktion, 1: Justerende, 2: Afsluttende, efterfulgt af 3: "Anden"
    const orderedVejledersamtaler = samtalerFromApi.concat(placeholderVejledersamtaler).sortBy(x => x.vejledersamtaleType.toString())
    setVejledersamtaler(orderedVejledersamtaler);

    setIsLoading(false);

  }, [forloebId, vejledersamtaleApi])

  useEffect(() => {
    if (shouldFetch) {
      loadVejledersamtaleList();
    }
  }, [loadVejledersamtaleList, shouldFetch])

  return {
    vejledersamtaler,
    refetch: loadVejledersamtaleList,
    isLoading
  }
}

export default useVejledersamtaler;
