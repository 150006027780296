import { Localizer } from "infrastructure/localization/localizer";
import DynamicButton from "../button/dynamicButton";
import { CheckboxSwitch } from "../checkbox/checkboxSwitch";
import { ReactTooltip } from "../tooltips/ReactTooltip";
import { ForloebOverviewTypeEnum } from "core/sharedmodels/forloeb/forloebOverviewTypeEnum";
import useUser from "core/hooks/useUser";
import {ReactComponent as GearIcon} from "../../../content/icons/gear-solid.svg";

type ForloebOverviewSettingsButtonProps = {
  showHorizontaleLinjer: boolean;
  useOverlappendeVisning: boolean;
  showAnonymized: boolean;
  showUnoccupiedStillinger: boolean;
  onShowHorizontaleLinjerChanged: (checked: boolean) => void;
  onUseOverlappendeVisningChanged: (checked: boolean) => void;
  onShowAnonymizedVisningChanged: (checked: boolean) => void;
  onShowUnoccupiedStillingerChanged: (checked: boolean) => void;

  forloebOverviewType: ForloebOverviewTypeEnum;
}

export const ForloebOverviewSettingsButton = ({
  showHorizontaleLinjer,
  useOverlappendeVisning,
  showAnonymized,
  showUnoccupiedStillinger,
  onShowHorizontaleLinjerChanged,
  onUseOverlappendeVisningChanged,
  onShowAnonymizedVisningChanged,
  onShowUnoccupiedStillingerChanged,
  forloebOverviewType
}: ForloebOverviewSettingsButtonProps) => {

  const { currentUser } = useUser();

  return (
    <div className="margin-left-s">
      <DynamicButton id={"settings-icon-id"} rounded>
          <GearIcon />
      </DynamicButton>
      <ReactTooltip selectId={"settings-icon-id"} delay={100} place="left-start">
          <div className="flex-container-column ">
              <h4 className={"margin-bottom-s flex-start"}>{Localizer.forloebOverviewPage_forloebOverviewSettings()}</h4>
              <p className={"small flex-start margin-0"}>{Localizer.forloebOverviewPage_forloebOverviewSettingsDescription1()}</p>
              <p className={"small margin-bottom-m flex-start"}>{Localizer.forloebOverviewPage_forloebOverviewSettingsDescription2()}</p>
              <div className="border crop-container-border padding-s margin-bottom-s">
                  <div>
                      <CheckboxSwitch
                          id={"forloeboverview-horizontale-linjer-switch"}
                          defaultValue={showHorizontaleLinjer}
                          label={Localizer.forloebOverviewPage_visHorisontaleLinjer()}
                          reverseTextAndSwitch
                          onChange={(e) => onShowHorizontaleLinjerChanged(e.target.checked)}
                      />
                  </div>
                  <div className={"margin-top-s"}>
                      <CheckboxSwitch
                          id={"forloeboverview-overlap-switch"}
                          defaultValue={useOverlappendeVisning}
                          label={Localizer.visOverlappende()}
                          reverseTextAndSwitch
                          onChange={(e) => onUseOverlappendeVisningChanged(e.target.checked)}
                      />
                  </div>
                  {currentUser.IsAdminOrVus() &&
                      <div className={"margin-top-s"}>
                          <CheckboxSwitch
                              id={"forloeboverview-anonymized-switch"}
                              label={Localizer.forloebOverviewPage_showAnonymizedForloeb()}
                              reverseTextAndSwitch
                              onChange={(e) => onShowAnonymizedVisningChanged(e.target.checked)}
                              defaultValue={showAnonymized}
                          />
                      </div>
                  }
                  {forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikStillingerView &&
                      <div className={"margin-top-s"}>
                          <CheckboxSwitch
                              id={"forloeboverview-showUnoccupied-switch"}
                              label={Localizer.global_unoccupied_stillinger()}
                              reverseTextAndSwitch
                              onChange={(e) => onShowUnoccupiedStillingerChanged(e.target.checked)}
                              defaultValue={showUnoccupiedStillinger}
                          />
                      </div>
                  }
              </div>
          </div>
      </ReactTooltip>
    </div>
  )
}
