import React, {useEffect, useRef, useState} from 'react'
import {HttpClientService} from '../../../services/httpClient/httpClientService';
import {Localizer} from '../../../infrastructure/localization/localizer';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
    clearSelectedLaegekompetenceMetas,
    kompetenceCardReducer,
    KompetenceCardState,
    scrollToActiveKompetence,
    setActiveLaegekompetenceMeta,
    setKompetenceCardStateShowCheckboxes,
    toggleLaegekompetenceListRefreshPing
} from '../../../pages/kompetencerPage/cards/kompetenceCardSlice';
import {LogbogLaegekompetenceApi} from '../../../services/api/logbogLaegekompetence/logbogLaegekompetenceApi';
import {LaegekompetenceMeta} from '../../sharedmodels/kompetence/laegekompetenceMeta';
import {LaegekompetenceStatusEnum} from '../../sharedmodels/kompetence/laegekompetenceStatusEnum';
import {NotificationModule} from "ditmer-embla";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {ModalAcceptType, ModalSubmitMessage} from "../../components/modalMessage/modalSubmitMessage";
import {AccountService} from "../../../services/account/accountService";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import DynamicButton from 'core/components/button/dynamicButton';
import {EmblaIcons} from 'core/emblaIcons';

const laegekompetenceApi = new LogbogLaegekompetenceApi(new HttpClientService(), new FileDownloadHandler(), new AccountService())

export interface GodkendButtonProps{
    godkenderCallback?: (godkender: boolean)=>void;
}

export function GodkendKompetenceButton(props: GodkendButtonProps) {
    const dispatch = useAppDispatch();
    const modalId = "godkendModal";
    const userService = new AccountService();
    const _currentUser = useRef(userService.getUser());

    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const [isDisabledState, setIsDisabledState] = useState<boolean>(false);
    const [isGodkendState, setIsGodkendState] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const updateKompetenceStatus = async () => {
        let laegekompetencer: LaegekompetenceMeta[] = [];

        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0) {
            if(props.godkenderCallback){
                props.godkenderCallback(true);
            }
            for (const lkm of kompetenceCardState.selectedLaegekompetenceMetas) {
                let laegekompetenceModel = LaegekompetenceMeta.FromState(lkm, forloebSliceState.forloebState);
                laegekompetenceModel.status = getNewStatus();
                laegekompetencer.push(laegekompetenceModel);

                if (kompetenceCardState.selectedLaegekompetenceMetas.findIndex(lkmState => lkmState.laegekompetenceId === lkm.laegekompetenceId) > -1) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetenceModel.ToState()));
                }
            }
            await laegekompetenceApi.patchStatusMultiple(laegekompetencer);
            if(props.godkenderCallback){
                props.godkenderCallback(false);
            }
        } else {
            let activeKompetence = LaegekompetenceMeta.FromState(kompetenceCardState.activeLaegekompetenceMeta, forloebSliceState.forloebState);
            activeKompetence.status = getNewStatus();
            const updatedLaegekompetenceId = await laegekompetenceApi.patchStatus(activeKompetence);
            const activeKompetenceUpdated = await laegekompetenceApi.getLaegekompetenceById(updatedLaegekompetenceId, activeKompetence.userId);
            dispatch(setActiveLaegekompetenceMeta(activeKompetenceUpdated.ToState()));
        }
        dispatch(scrollToActiveKompetence(true))

        displayNotification();

        //update state
        dispatch(toggleLaegekompetenceListRefreshPing())
        dispatch(clearSelectedLaegekompetenceMetas())
        dispatch(setKompetenceCardStateShowCheckboxes([]))
        setShowModal(false);
    }

    const displayNotification = () => {
        let count = kompetenceCardState.selectedLaegekompetenceMetas.length;
        let message = "";

        if (count === 0)
            count = 1;

        if (isGodkendState) {
            message = Localizer.kompetencePage_kompetencerGodkendt(count.toString());
        } else {
            message = Localizer.kompetencePage_kompetencerGodkendtFjernet(count.toString());
        }

        NotificationModule.showSuccess(message, "");
    }

    const getNewStatus = () => {
        return isGodkendState ? LaegekompetenceStatusEnum.Godkendt : LaegekompetenceStatusEnum.Planlagt;
    }

    useEffect(() => {
        const updateIsDisabledState = () => {
            let isDisabled = false;
            if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0 || kompetenceCardState.showCheckboxOnIds.length > 0) {

                let someHasDifferentStatusses = kompetenceCardState.selectedLaegekompetenceMetas.some(lkm => lkm.status === LaegekompetenceStatusEnum.Godkendt);
                let noKompetencerChosen = kompetenceCardState.selectedLaegekompetenceMetas.length === 0;
                const isGodkend = kompetenceCardState.selectedLaegekompetenceMetas.some(lkm => lkm.status !== LaegekompetenceStatusEnum.Godkendt);

                isDisabled = noKompetencerChosen || (someHasDifferentStatusses && isGodkend);
            }

            setIsDisabledState(isDisabled);
        }

        const updateIsGodkendState = () => {
            let isGodkend = false;

            if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0 || kompetenceCardState.showCheckboxOnIds.length > 0) {
                isGodkend = kompetenceCardState.selectedLaegekompetenceMetas.length === 0 || kompetenceCardState.selectedLaegekompetenceMetas.some(lkm => lkm.status !== LaegekompetenceStatusEnum.Godkendt);
            } else {
                isGodkend = kompetenceCardState.activeLaegekompetenceMeta.status !== LaegekompetenceStatusEnum.Godkendt;
            }

            setIsGodkendState(isGodkend);
        }

        updateIsDisabledState();
        updateIsGodkendState();

    }, [kompetenceCardState.activeLaegekompetenceMeta.status, kompetenceCardState.selectedLaegekompetenceMetas, kompetenceCardState.showCheckboxOnIds.length])

    const render = (
        <>
            {(isGodkendState || _currentUser.current.IsAdminOrVus()) &&
                <DynamicButton
                    isPrimary={isGodkendState}
                    onClick={() => setShowModal(true)}
                    disabled={isDisabledState || ((_currentUser.current.IsUddannelsesansvarlig() || _currentUser.current.IsVejleder()) && _currentUser.current.AuthorizationId === undefined)}
                    modal={{dataTarget: `#${modalId}`}}
                    role='tab'
                >
                    <DynamicButton.TextIconItem
                        iconName={isGodkendState ? EmblaIcons.Success : EmblaIcons.Remove}
                        text={isGodkendState ? Localizer.global_godkend() : Localizer.global_removeGodkend()}
                    />
                </DynamicButton>
            }
            {showModal &&
                <ModalSubmitMessage modalId={modalId}
                                    title={Localizer.areYouSure()}
                                    cancelAction={() => setShowModal(false)}
                                    acceptAction={updateKompetenceStatus}
                                    modalAcceptType={isGodkendState ? ModalAcceptType.primary : ModalAcceptType.danger}
                                    acceptButtonText={isGodkendState ? Localizer.global_godkend() : Localizer.global_removeGodkend()}
                />
            }
        </>
    )

    return <>{render}</>
}
