import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState,} from 'app/store';
import {UddannelseTypeEnum} from './sharedmodels/stilling/uddannelseTypeEnum';
import {StillingTypeEnum} from "./sharedmodels/stilling/stillingTypeEnum";
import {FravaerskodeEnum} from "./sharedmodels/forloeb/fravaerskoderEnum";

export interface ForloebState {
    id: string;
    startDato: string;
    slutDato: string;
    skalIndgaaIEvalueringsstatistik: boolean;
    irrelevant: boolean;
    seAlleKompetencer: boolean;
    stillingId: string;
    afdelingId: string;
    brugerId: string;
    afdelingName: string;
    stillingNummer: string;
    laegeInstitutionName: string;
    laegeInstitutionId: string;
    uddannelseType: UddannelseTypeEnum;
    uddannelseId: string;
    godkendelseId?: string;
    godkendtDen?: string;
    senesteDatoForEvaluering?: string;
    godkendtKommentar?: string;
    godkendtAfNavn: string;
    godkendtAfRolleTitel: string;
    godkendtAfAutorisationId: string;
    stillingsType: StillingTypeEnum;
    manueltAfsluttet: boolean;
    manglerKursusOpdatering: boolean;
    attestationForTidInfoTekst?: string;
    specialeId: string;
    specialeName: string

    hasEnded: boolean;

    //kursusraekke
    brugerKursusraekkeId: string;

    //maalbeskrivelse
    brugerMaalbeskrivelseId: string;
    maalbeskrivelseNavn: string;
    maalbeskrivelseVersionsdato: string | null;

    //Region
    regionId: string;
    regionNavn: string;

    //VidereuddannelsesRegion
    videreuddannelsesRegionId: string;
    videreuddannelsesRegionNavn: string;

    evalueretDato: string;

    //Fravær
    erFravaer: boolean;
    fravaerskodeEnum?: FravaerskodeEnum;
    stillingErAltidFravaer: boolean;
    harTidligereMidlertidigtFravaer: boolean;

    //VuSync
    kommerFraVu: boolean;
    deaktiveretFraVUSync: boolean;
}

export interface ForloebSliceState {
    forloebState: ForloebState,
    refreshForloebPing: boolean
}

const initialState: ForloebSliceState = {
    forloebState: {
        id: "",
        startDato: "",
        slutDato: "",
        skalIndgaaIEvalueringsstatistik: true,
        irrelevant: false,
        stillingId: "",
        brugerId: "",
        afdelingName: "",
        stillingNummer: "",
        laegeInstitutionName: "",
        laegeInstitutionId: "",
        uddannelseType: UddannelseTypeEnum.KBU,
        uddannelseId: "",
        godkendelseId: undefined,
        godkendtDen: "",
        senesteDatoForEvaluering: "",
        godkendtKommentar: "",
        godkendtAfNavn: "",
        godkendtAfRolleTitel: "",
        godkendtAfAutorisationId: "",
        brugerMaalbeskrivelseId: "",
        brugerKursusraekkeId: "",
        maalbeskrivelseNavn: "",
        maalbeskrivelseVersionsdato: null,
        afdelingId: "",
        regionId: "",
        regionNavn: "",
        videreuddannelsesRegionId: "",
        videreuddannelsesRegionNavn: "",
        seAlleKompetencer: false,
        hasEnded: false,
        evalueretDato: "",
        stillingsType: StillingTypeEnum.Sygehus,
        manueltAfsluttet: false,
        manglerKursusOpdatering: false,
        attestationForTidInfoTekst: "",
        erFravaer: false,
        fravaerskodeEnum: undefined,
        stillingErAltidFravaer: false,
        harTidligereMidlertidigtFravaer: false,
        kommerFraVu:false,
        deaktiveretFraVUSync: false,
        specialeId: "",
        specialeName: ""
    },
    refreshForloebPing: false
}

export const forloebSlice = createSlice({
    name: 'forloebSlice',
    initialState,
    reducers: {
        setCurrentForloeb: (state, action: PayloadAction<ForloebState>) => {
            state.forloebState.id = action.payload.id;
            state.forloebState.brugerId = action.payload.brugerId;
            state.forloebState.stillingId = action.payload.stillingId;
            state.forloebState.startDato = action.payload.startDato;
            state.forloebState.slutDato = action.payload.slutDato;
            state.forloebState.laegeInstitutionName = action.payload.laegeInstitutionName;
            state.forloebState.laegeInstitutionId = action.payload.laegeInstitutionId;
            state.forloebState.afdelingName = action.payload.afdelingName;
            state.forloebState.uddannelseType = action.payload.uddannelseType;
            state.forloebState.uddannelseId = action.payload.uddannelseId;
            state.forloebState.godkendelseId = action.payload.godkendelseId ?? undefined;
            state.forloebState.godkendtDen = action.payload.godkendtDen;
            state.forloebState.godkendtKommentar = action.payload.godkendtKommentar;
            state.forloebState.godkendtAfNavn = action.payload.godkendtAfNavn;
            state.forloebState.godkendtAfRolleTitel = action.payload.godkendtAfRolleTitel;
            state.forloebState.godkendtAfAutorisationId = action.payload.godkendtAfAutorisationId;
            state.forloebState.stillingNummer = action.payload.stillingNummer;
            state.forloebState.brugerMaalbeskrivelseId = action.payload.brugerMaalbeskrivelseId;
            state.forloebState.brugerKursusraekkeId = action.payload.brugerKursusraekkeId;
            state.forloebState.maalbeskrivelseNavn = action.payload.maalbeskrivelseNavn;
            state.forloebState.maalbeskrivelseVersionsdato = action.payload.maalbeskrivelseVersionsdato;
            state.forloebState.afdelingId = action.payload.afdelingId;
            state.forloebState.regionId = action.payload.regionId;
            state.forloebState.regionNavn = action.payload.regionNavn;
            state.forloebState.videreuddannelsesRegionId = action.payload.videreuddannelsesRegionId;
            state.forloebState.videreuddannelsesRegionNavn = action.payload.videreuddannelsesRegionNavn;
            state.forloebState.seAlleKompetencer = action.payload.seAlleKompetencer;
            state.forloebState.evalueretDato = action.payload.evalueretDato;
            state.forloebState.stillingsType = action.payload.stillingsType;
            state.forloebState.manueltAfsluttet = action.payload.manueltAfsluttet;
            state.forloebState.manglerKursusOpdatering = action.payload.manglerKursusOpdatering;
            state.forloebState.attestationForTidInfoTekst = action.payload.attestationForTidInfoTekst;
            state.forloebState.erFravaer = action.payload.erFravaer;
            state.forloebState.fravaerskodeEnum = action.payload.fravaerskodeEnum;
            state.forloebState.stillingErAltidFravaer = action.payload.stillingErAltidFravaer;
            state.forloebState.harTidligereMidlertidigtFravaer = action.payload.harTidligereMidlertidigtFravaer;
            state.forloebState.skalIndgaaIEvalueringsstatistik = action.payload.skalIndgaaIEvalueringsstatistik;
            state.forloebState.irrelevant = action.payload.irrelevant;
            state.forloebState.specialeId = action.payload.specialeId;
            state.forloebState.specialeName = action.payload.specialeName;
            state.forloebState.senesteDatoForEvaluering = action.payload.senesteDatoForEvaluering;

            state.forloebState.hasEnded = new Date(action.payload.slutDato) < new Date();
        },

        setBrugerMaalbeskrivelse: (state, action: PayloadAction<string>) => {
            state.forloebState.brugerMaalbeskrivelseId = action.payload;
        },

        setBrugerKursusraekke: (state, action: PayloadAction<string>) => {
            state.forloebState.brugerKursusraekkeId = action.payload;
        },

        pingRefreshForloeb: (state) => {
            state.refreshForloebPing = !state.refreshForloebPing;
        },

        setAttestationForTidInfoTekst: (state, action:PayloadAction<string>) => {
            state.forloebState.attestationForTidInfoTekst = action.payload;

        }
    }
});

export const { setCurrentForloeb, pingRefreshForloeb, setBrugerMaalbeskrivelse, setAttestationForTidInfoTekst } = forloebSlice.actions;
export const forloebReducer = (state: RootState) => state.forloebReducer;
export default forloebSlice.reducer;
