import {UserProfileAvatarSizeEnum} from "./userProfileAvatar";
import {ReactComponent as AgentSVG} from "../../../content/icons/agent.svg";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {EmblaIcons} from "../../emblaIcons";
import React, {useCallback, useState} from "react";
import classNames from "classnames";
import ImageWithAuth from "./imageWithAuth";
import {FileUploader} from "../fileUpload/fileUploader";
import {FileArchiveEnum} from "../../sharedmodels/fileMetaData/fileArchiveEnum";
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import './user-profile.scss';

export const resolveSizeClassName = (size?: UserProfileAvatarSizeEnum) => {
    switch (size) {
        case UserProfileAvatarSizeEnum.large:
            return "";
        case UserProfileAvatarSizeEnum.medium:
            return "medium";
        case UserProfileAvatarSizeEnum.small:
            return "small";
        case UserProfileAvatarSizeEnum.extraSmall:
            return "extra-small";
        case UserProfileAvatarSizeEnum.extraExstraSmall:
            return "extra-extra-small";
        default:
            return "";
    }
}

export const getEmblaIconSize = (size?: UserProfileAvatarSizeEnum) => {
    switch (size) {
        case UserProfileAvatarSizeEnum.large:
        case UserProfileAvatarSizeEnum.medium:
        case UserProfileAvatarSizeEnum.small:
            return "";
        case UserProfileAvatarSizeEnum.extraSmall:
        case UserProfileAvatarSizeEnum.extraExstraSmall:
            return "small";
        default:
            return "";
    }
}

type AvatarBadgeProps = {
    initials?: string;
    size?: UserProfileAvatarSizeEnum;
    colorClassName?: string;
    additionalClasses?: string;
    additionalImageClasses?: string;
    isImpersonating?: boolean;
    isCurrentUser: boolean;
    userAnonymized?: boolean;
    profileImageFileMetadataId?: string;
    profileImageFileData?: Uint8Array[];
    fileUploadedCallback?: (file: ReactFileUploaderFile) => void;
    deleteImageCallback?: () => void;
}

const userProfileAvatarClassName = "user-profile-avatar";

export const AvatarBadge = ({
                                initials,
                                size,
                                colorClassName,
                                isImpersonating,
                                profileImageFileMetadataId,
                                profileImageFileData,
                                fileUploadedCallback,
                                deleteImageCallback,
                                additionalClasses,
                                additionalImageClasses,
                                isCurrentUser = false,
                                userAnonymized = false,
                            }: AvatarBadgeProps) => {

    const sizeClass = resolveSizeClassName(size);
    const emblaIconSizeClass = getEmblaIconSize(size);

    const [isHovered, setIsHovered] = useState(false);
    const [profileImageFetchFailed, setProfileImageFetchFailed] = useState(false);

    const showProfileImage = !!profileImageFileMetadataId && !profileImageFetchFailed;
    const showInitials = !showProfileImage && !!initials;
    const showDefaultUserImage = !showProfileImage && !showInitials;

    const showDeleteImage = isHovered && isCurrentUser;
    // Show upload-button if profile-image is not shown - Should be shown even if existing profile image failed on fetch (should be able to overwrite the failing image):
    const showUploadImage = !showProfileImage && fileUploadedCallback && isCurrentUser;

    // Should be useCallback, because it is used as dependency for profileImageFetch hook:
    const onFetchCallback = useCallback((succeded: boolean) => {
        setProfileImageFetchFailed(!succeded)
    }, []);

    return (
        <div
            className={classNames(userProfileAvatarClassName, sizeClass, (!showProfileImage || isImpersonating) && colorClassName, additionalClasses)}
            onMouseEnter={() => setIsHovered(true)}
            onTouchStart={() => setIsHovered(true)} //Mobile (Android): Fix delete-button wouldn't show intiallly
            onMouseLeave={() => setIsHovered(false)} //Obs.: Mobile: There is a problem where onMouseLeave will not trigger, if confirmation has been activated (clicked on delete).
            >
            {isImpersonating && <AgentSVG/>}
            {!isImpersonating &&
                <>
                    {profileImageFileMetadataId && showProfileImage &&
                        <div className={classNames(userProfileAvatarClassName, emblaIconSizeClass, sizeClass, additionalClasses)}>
                            <ImageWithAuth profileImageFileMetadataId={profileImageFileMetadataId}
                                           additionalClasses={additionalImageClasses}
                                           profileImageData={profileImageFileData}
                                           deleteImageCallback={deleteImageCallback}
                                           showDelete={showDeleteImage}
                                           onFetchCallback={onFetchCallback}/>
                        </div>
                    }
                    {showInitials &&
                        <p className={classNames("profile-initials", userAnonymized && "text-black")}>{initials}</p>
                    }
                    {showDefaultUserImage &&
                        <EmblaIcon iconName={EmblaIcons.User}
                                   additionalClasses={`${emblaIconSizeClass}`}
                                   wrapInSpan={false}/>
                    }
                    {showUploadImage && fileUploadedCallback &&
                        <div
                            className={classNames("flex-row-center image-upload-type-icon", profileImageFileMetadataId && "margin-bottom-s")}>
                            <FileUploader
                                additionalLabelClasses={"image-background rounded-circle image-upload-type-icon-hover"}
                                hideText={true}
                                fileType={FileArchiveEnum.ProfileImage}
                                overrideIcon={EmblaIcons.Camera}
                                filesUploadedCallback={(file: ReactFileUploaderFile[]) => fileUploadedCallback(file[0])}
                                fileLocation={FileLocationEnum.Stamdata}
                            />
                        </div>
                    }
                </>
            }
        </div>
    );
}
