import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BreadCrumb, TitlebarAction} from "./titleBarApi";
import {RootState} from "../../../app/store";
import React from "react";

export interface TitlebarState {
    showTitlebar: boolean;
    titlebarLinks?: React.ReactNode;
    breadcrumbs: BreadCrumb[],
    title: string,
    actions: TitlebarAction[]
}

const initialState: TitlebarState = {
    showTitlebar: true,
    titlebarLinks: undefined,
    breadcrumbs: [{text: "", url: ""}],
    title: "",
    actions: []
};

export const titlebarSlice = createSlice({
    name: 'titlebarSlice',
    initialState,
    reducers: {
        newBreadcrumb: (state, action: PayloadAction<Array<BreadCrumb>>) => {
            state.breadcrumbs = action.payload;
        },

        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },

        setActions: (state, payloadAction: PayloadAction<Array<TitlebarAction>>) => {
            state.actions = payloadAction.payload;
        },

        showTitlebar: (state, payloadAction: PayloadAction<boolean>) => {
            state.showTitlebar = payloadAction.payload;
        },

        setTitlebarLinks: (state, payloadAction: PayloadAction<React.ReactNode>) => {
            state.titlebarLinks = payloadAction.payload;
        },
    }
});

export const {newBreadcrumb, setTitle, setActions, showTitlebar, setTitlebarLinks} = titlebarSlice.actions;
export const titlebarState = (state: RootState) => state.titlebarReducer;
export default titlebarSlice.reducer;
