import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";
import ExternalLink from "../link/ExternalLink";
import React from "react";
import classNames from "classnames";
import {EmblaIcons} from "../../emblaIcons";
import {ConfirmButton} from "../button/confirmButton";
import DynamicButton from "../button/dynamicButton";

type LinkProps = {
    deleteAction?: (link: LinkModel) => void;
    editLinkAction?: (link: LinkModel) => void;
    link: LinkModel;
}

export const Link = ({link, deleteAction, editLinkAction}: LinkProps) => {
    return (
        <div className={classNames("d-flex padding-s")}>
            <ExternalLink link={link.link} text={link.linkTekst ?? link.link}/>
            <div className="flex-align-r">

                {editLinkAction &&
                    <DynamicButton rounded additionalClasses={classNames("margin-right-s")}
                                   onClick={() => editLinkAction(link)}>
                        <DynamicButton.TextIconItem iconName={EmblaIcons.Edit}/>
                    </DynamicButton>
                }
                {deleteAction &&
                    <ConfirmButton
                        additionalClasses={"rounded-circle btn-icon"}
                        key={link.id}
                        icon={EmblaIcons.Delete}
                        asButton={true}
                        confirmCallback={() => deleteAction(link)}
                    />
                }
            </div>
        </div>
    )
}


