import { getTranslation } from "./localizer";

export class LocalizerValidations {
    public static validate_startDatoRequired = () => getTranslation("validate_startDatoRequired", "Startdato er påkrævet");
    public static validate_slutDatoRequired = () => getTranslation("validate_slutDatoRequired", "Slutdato er påkrævet");
    public static validate_praksisserRequired = () => getTranslation("validate_praksisserRequired", "Praksisser påkrævet");
    public static validate_afdelingerRequired = () => getTranslation("validate_afdelingerRequired", "Afdelinger påkrævet");
    public static validate_datoRequired = () => getTranslation("validate_datoRequired", "Dato er påkrævet");
    public static validate_startDatoBeforeNow = () => getTranslation("validate_startDatoBeforeNow", "Start dato må ikke være før i tiden");
    public static validate_slutDatoBeforeNow = () => getTranslation("validate_slutDatoBeforeNow", "Slut dato må ikke være før i tiden");
    public static validate_startDatoOverlapOtherForloeb = () => getTranslation("validate_startDatoOverlapOtherForloeb", "start datoen overlapper med et andet forloeb");
    public static validate_sluttidspunktBeforeStarttidspunkt = () => getTranslation("validate_sluttidspunktBeforeStarttidspunkt", "Sluttidspunkt er før starttidspunkt");
    public static validate_slutDatoBeforeStartdate = () => getTranslation("validate_slutDatoBeforeStartdate", "Slut dato er før start datoen");
    public static validate_slutDatoOverlapOtherForloeb = () => getTranslation("validate_slutDatoOverlapOtherForloeb", "slut datoen overlapper med et andet forloeb");
    public static validate_dateRequired = () => getTranslation("validate_dateRequired", "Dato er påkrævet");
    public static validate_starttidspunktRequired = () => getTranslation("validate_starttidspunktRequired", "Starttidspunkt er påkrævet");
    public static validate_sluttidspunktRequired = () => getTranslation("validate_sluttidspunktRequired", "Sluttidspunkt er påkrævet");
    public static validate_stillingRequired = () => getTranslation("validate_stillingRequired", "Stilling er påkrævet");
    public static validate_dateInvalid = () => getTranslation("validate_dateInvalid", "Indtast valid dato");
    public static validate_kommentarRequiredNaarDerGodkendesPaaAndetGrundlag = () => getTranslation("validate_kommentarRequiredNaarDerGodkendesPaaAndetGrundlag", "Kommentar er påkrævet, når der godkendes på andet grundlag");
    public static validate_tidspunktInvalid = () => getTranslation("validate_tidspunktInvalid", "Indtast valid tidspunkt");
    public static validate_sygehusRequired = () => getTranslation("validate_sygehusRequired", "Sygehus er påkrævet");
    public static validate_laegeinstitutionstypeRequired = () => getTranslation("validate_laegeinstitutionstypeRequired", "Lægeinstitutionstype er påkrævet");
    public static validate_uddannelseRequired = () => getTranslation("validate_uddannelseRequired", "Uddannelse er påkrævet");
    public static validate_sygehusInvalid = () => getTranslation("validate_sygehusInvalid", "Vælg validt sygehus");
    public static validate_afdelingRequired = () => getTranslation("validate_afdelingRequired", "Afdeling er påkrævet");
    public static validate_praksisRequired = () => getTranslation("validate_praksisRequired", "Praksis er påkrævet");
    public static validate_vejlederRequired = () => getTranslation("validate_vejlederRequired", "Vejleder er påkrævet");
    public static validate_newMaalbeskrivelseRequired = () => getTranslation("validate_newMaalbeskrivelseRequired", "Ny målbeskrivelse er påkrævet");
    public static validate_gammelMaalbeskrivelseRequired = () => getTranslation("validate_gammelMaalbeskrivelseRequired", "Gammel målbeskrivelse er påkrævet");
    public static validate_afdelingInvalid = () => getTranslation("validate_afdelingInvalid", "Vælg valid afdeling");
    public static validate_brugerenSkalTilknyttesMindstEnAfdeling = () => getTranslation("validate_brugerenSkalTilknyttesMindstEnAfdeling", "Brugeren skal tilknyttes mindst en afdeling");
    public static validate_laegeRequired = () => getTranslation("validate_laegeRequired", "Læge er påkrævet");
    public static validate_laegerRequired = () => getTranslation("validate_laegerRequired", "Læger er påkrævet");
    public static validate_visibilityRequired = () => getTranslation("validate_visibilityRequired", "Synlighed er påkrævet");
    public static validate_titelRequired = () => getTranslation("validate_titelRequired", "Titel er påkrævet");
    public static validate_descriptionRequired = () => getTranslation("validate_descriptionRequired", "Beskrivelse er påkrævet");
    public static validate_MessageRequired = () => getTranslation("validate_MessageRequired", "Besked er påkrævet");
    public static validate_SubjectRequired = () => getTranslation("validate_SubjectRequired", "Emne er påkrævet");
    public static validate_ContentRequired = () => getTranslation("validate_ContentRequired", "Indhold er påkrævet");
    public static validate_publiceringsdatoMessageRequired = () => getTranslation("validate_publiceringsdatoMessageRequired", "publiceringsdato påkrævet");
    public static validate_phone = () => getTranslation("validate_phone", "Telefon nr. er påkrævet");
    public static validate_email = () => getTranslation("validate_email", "Email er påkrævet");
    public static validate_emailInvalid = () => getTranslation("validate_emailInvalid", "Indtast gyldig email");
    public static validate_stillingsbetegnelseRequired = () => getTranslation("validate_stillingsbetegnelseRequired", "Stillingsbetegnelse er påkrævet");
    public static validate_stillingsNummerRequired = () => getTranslation("validate_stillingsNummerRequired", "Stillingsnummer er påkrævet");
    public static validate_AnsaettelsesstedRequired = () => getTranslation("validate_AnsaettelsesstedRequired", "Ansættelsessted er påkrævet");
    public static validate_MaanederRequired = () => getTranslation("validate_MaanederRequired", "Måneder er påkrævet");
    public static validate_fravaerStartBeforeForloebStart = () => getTranslation("validate_fravaerStartBeforeForloebStart", "Fravær startdato er mindre end forløbets startdato");
    public static validate_HovedvejlederRequired = () => getTranslation("validate_HovedvejlederRequired", "Hovedvejleder er påkrævet");
    public static validate_klarmeldTilEnEllerFlere = () => getTranslation("validate_klarmeldTilEnEllerFlere", "Klarmeld til én eller flere");
    public static validate_nedsatTidAntalTimerMin = () => getTranslation("validate_nedsatTidAntalTimerMin", "Det angivede tal skal være større eller lig med 0");
    public static validate_nedsatTidAntalTimerMax = () => getTranslation("validate_nedsatTidAntalTimerMax", "Det angivede tal skal være mindre end 168");
    public static validate_nedsatTidAntalTimerDecimalPrecision = () => getTranslation("validate_nedsatTidAntalTimerDecimalPrecision", "Tallet må maks have 2 decimaler");
    public static validate_numberTypeError = () => getTranslation("validate_numberTypeError", "Der skal angives et tal");
    public static validate_ugentligtTimetal = () => getTranslation("validate_ugentligtTimetal", "Ugentligt timetal er påkrævet");
    public static validate_fieldRequired = (fieldName: string) => getTranslation("validate_fieldRequired", `${fieldName} er påkrævet`);
    public static validate_fieldMustBeDefined = (fieldName: string) => getTranslation("validate_fieldMustBeDefined", `"${fieldName}" skal udfyldes`);
    public static validate_emailSentToRequired = () => getTranslation("validate_emailSentToRequired", `Der skal vælges mindst 1 bruger`);
    public static validate_emailBulkAllowanceExeededError = (maxAmountOfEmails: number)  => getTranslation("validate_emailBulkAllowanceExeededError", `Det tilladte antal e-mails der ønskes afsendt er overskredet. Det tilladte antal er: ${maxAmountOfEmails} e-mails.`);
    public static validate_emailBulkAllowanceMessage = (maxAmountOfEmails: number)  => getTranslation("validate_emailBulkAllowanceMessage", `Det tilladte antal e-mails der kan afsendes er ${maxAmountOfEmails}.`);
    public static validate_mappeRequired = () => getTranslation("validate_mappeRequired", "Mappe er påkrævet");
    public static validate_linkRequired = () => getTranslation("validate_linkRequired", "Link er påkrævet");
    public static validate_textRequired = () => getTranslation("validate_textRequired", "Tekst er påkrævet");
}
