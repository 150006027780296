import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import PdfComponent from "core/components/pdf/pdfComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import PdfRow from "./pdfRow";
import {setDocumentPdfAsReady} from "./helpers/pdfHelper";
import {GenericIdPath} from "infrastructure/route/routePathConstants";
import IdAndModelsContainer from "core/sharedmodels/containers/IdAndModelsContainer";
import {KompetenceEvalueringModel} from "core/sharedmodels/evaluering/kompetenceEvalueringModel";
import Title from "../titels/title";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import HtmlHeading from "../titels/HtmlHeadings";
import {svarReadTypeCompareMap} from "../../../pages/kompetencerPage/readSvar/svarReadTypeCompareMap";
import {KvSkemaConstants} from "../../../pages/kompetencerPage/evaluering/KbuSkemaConstants";
import PdfHeaderRow from "./pdfHeaderRow";

const AllVurderingerPdf = () => {

    const { evalueringApi } = useLogbogApi();
    const { id: brugerId } = useRouteMatch<GenericIdPath>().params;

    const [contianers, setContianers] = useState<IdAndModelsContainer<KompetenceEvalueringModel>[]>([]);

    const setError = useFetchWithErrorHandlingPdf();

    const pdfRowMargin = "margin-top-l";
    const pdfRenderId = "pdfRenderId";

    useEffect( () => {

        const fetchData = async () : Promise<IdAndModelsContainer<KompetenceEvalueringModel>[]> => {
            const idAndModels = await evalueringApi.getAllKompetenceEvalueringerForPdf(brugerId);
            idAndModels.forEach(c => c.models.sortNaturallyBy(x => x.titel));

            return idAndModels;
        };

        fetchData()
            .then(data => setContianers(data))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());

    }, [brugerId, evalueringApi, setError]);

    return (
        <PdfComponent>

            {contianers.map(({id: brugerMaalbeskrivelseId, models: evaluerings}) => (
                <PdfComponent.PageBreakAfter key={brugerMaalbeskrivelseId}>

                    <PdfKompetenceHeaderComponent
                        brugerId={brugerId}
                        brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                        disableUserInfo
                    />

                    <PdfComponent.BlockItem className={"card shadow-none"}>

                        <PdfComponent.CardHeader>
                            <PdfHeaderRow asFragment>
                                <PdfHeaderRow.Col width={12}>{Localizer.Kompetencevurderinger().appendCounter(evaluerings.length)}</PdfHeaderRow.Col>
                            </PdfHeaderRow>
                        </PdfComponent.CardHeader>

                        {evaluerings.map((ke, idx) => (
                            <PdfComponent.PageBreakAfter>
                                <PdfComponent.CardBody key={idx}>

                                    <PdfRow>
                                        <PdfRow.Col>
                                            <Title title={ke.titel} heading={HtmlHeading.H4}/>
                                        </PdfRow.Col>
                                    </PdfRow>

                                    <PdfRow.Col width={12}>
                                        <Title title={Localizer.global_kompetence()}
                                               subTitle={ke.kompetenceTitel}
                                               largeSubTitle nonSubtleSubTitle/>
                                    </PdfRow.Col>

                                    <PdfRow className={pdfRowMargin}>
                                        <PdfRow.Col width={4}>
                                            <Title title={Localizer.UdfyldtAf()}
                                                   subTitle={`${ke.createdByUser?.firstName} ${ke.createdByUser?.lastName}`}
                                                   largeSubTitle nonSubtleSubTitle/>
                                        </PdfRow.Col>
                                        <PdfRow.Col width={4}>
                                            <Title title={Localizer.dato()}
                                                   subTitle={ke.indsendtDato ? ke.indsendtDato.dateWithoutTimeFormat(true) : ""}
                                                   largeSubTitle nonSubtleSubTitle/>
                                        </PdfRow.Col>
                                    </PdfRow>

                                    <PdfRow className={pdfRowMargin}>
                                        <PdfRow.Col width={12}>
                                            <Title title={Localizer.Kompetencevurdering()}
                                                   subTitle={ke.tekst}
                                                   largeSubTitle nonSubtleSubTitle/>
                                        </PdfRow.Col>
                                    </PdfRow>

                                    <PdfRow className={pdfRowMargin}>
                                        <PdfRow.Col width={12}>
                                            <Title title={Localizer.VejledersVurdering()}>
                                                <div className="flex-column margin-bottom-m">
                                                    {ke.sektioner.filter(s => s.includedInProgress).map(sektion => sektion.spoergsmaal.map(spg =>
                                                            <PdfComponent.NonBreakableItem key={spg.id + sektion.id}>
                                                                <div className="margin-bottom-m">
                                                                    {spg.isFeedback ?
                                                                        <>
                                                                            <h5>{Localizer.global_feedback()}</h5>
                                                                            <i><p>{spg?.titel}</p></i>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <p>{spg?.titel}</p>
                                                                            <p className={"subtle"}>{spg.tekst}</p>
                                                                        </>
                                                                    }
                                                                    {spg.evalueringSvar?.map(es => {
                                                                        const Component = svarReadTypeCompareMap[spg.spoergsmaalTypeEnum];
                                                                        return Component ?
                                                                            <Component spoergsmaal={spg}
                                                                                       evalueringSvar={es}
                                                                                       excludeAnswerText={KvSkemaConstants.InitialKbuSkemaIds.includes(ke.evalueringsSkemaId)}
                                                                                       renderId={pdfRenderId}/> : <></>;
                                                                    })}
                                                                </div>
                                                                <div className="border margin-bottom-m"/>
                                                            </PdfComponent.NonBreakableItem>
                                                        )
                                                    )}
                                                </div>
                                            </Title>
                                        </PdfRow.Col>
                                    </PdfRow>
                                </PdfComponent.CardBody>
                            </PdfComponent.PageBreakAfter>
                        ))}

                        {evaluerings?.length === 0 &&
                            <PdfComponent.CardBody>
                                <div className={"card-list-modal"}>
                                    <Title
                                        title={Localizer.IngenVejlederKompetencevurderinger()}
                                        titleClasses="subtle"
                                    />
                                </div>
                            </PdfComponent.CardBody>
                        }

                    </PdfComponent.BlockItem>
                </PdfComponent.PageBreakAfter>
            ))}

        </PdfComponent>
    );
}

export default AllVurderingerPdf;
