import React, {useEffect, useState} from "react";
import {BuildNew, CreateMailInputModel, CreateMailValidationSchema} from "./createMailInputModel";
import {ValidationForm} from "../validation/components/validationForm";
import {MailInputFields} from "./mailInputFields";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";

import {nameof} from "ts-simple-nameof";
import {ValidationDropdown} from "../validation/components/validationDropdown";
import useUserEmail from "../../hooks/useUserEmail";
import {UserSendEmailResponseModel} from "../../../services/api/stamdataUser/UserSendEmailResponseModel";
import {DropdownOption} from "../dropdown/dropdown";
import {UserEmailModel} from "./UserEmailModel";
import useUser from "../../hooks/useUser";
import {CheckboxSwitch} from "../checkbox/checkboxSwitch";

interface EmailSlideInMultipleUsersProps {
    users: UserSendEmailResponseModel[];
    actionFinishedCallback?: () => void;
}

const modalId = "send-email-modal";

export function EmailSlideInModalMultipleUsers({users, actionFinishedCallback}: EmailSlideInMultipleUsersProps) {
    const {currentUser} = useUser();
    const [userEmailModels, setUserEmailModels] = useState<UserEmailModel[]>(users.map(x => ({ email: x.email, userId: x.userId })));
    const emailOptions: DropdownOption<string>[] = users.map(x => ({ label: x.navn, value: x.userId})).sortBy(x => x.label);
    const [sendToSelf, setSendToSelf] = useState(true);

    useEffect(() => {
        if (sendToSelf)
            setUserEmailModels(prev => [...prev, { email: currentUser.Email, userId: currentUser.UserId }]);
        else
            setUserEmailModels(prev => prev.filter(x => x.userId !== currentUser.UserId));
    }, [currentUser.Email, currentUser.UserId, sendToSelf]);

    const {
        fileDeletedCallback,
        CreateMail,
        filesUploadedCallback,
        setSelectedTemplateType
    } = useUserEmail(userEmailModels, modalId, actionFinishedCallback, false);

    return (
        <>
            <ValidationForm
                key={modalId}
                initialValues={BuildNew(users.map(x => x.userId))}
                validationSchema={CreateMailValidationSchema}
                onSubmit={async (values) => {
                    if (sendToSelf) {
                        values.userIds.push(currentUser.UserId);
                    }
                    await CreateMail(values)
                }}>
                {
                    (formik) => (
                        <>
                            <MailInputFields modalId={modalId}
                                             formik={formik}
                                             templateTypeCallBack={setSelectedTemplateType}
                                             fileUploadedCallback={filesUploadedCallback}
                                             fileDeletedCallback={fileDeletedCallback}/>
                            <CheckboxSwitch
                                id={"send-mail-to-self"}
                                label={Localizer.global_sendMailTilEgenAdresse()}
                                defaultValue={sendToSelf}
                                onChange={() => setSendToSelf(!sendToSelf)}
                            />
                            {sendToSelf &&
                                <div className="alert alert-info margin-top-m" role={"alert"}>
                                    {Localizer.brugerePage_emailSendesTilAfsender(currentUser.Email)}
                                </div>
                            }
                            <ValidationDropdown
                                model={{
                                    label: Localizer.createMail_MailSentTo(),
                                    placeholder: Localizer.createMail_MailSentTo(),
                                    htmlName: nameof<CreateMailInputModel>(x => x.userIds)
                                }}
                                options={emailOptions}
                                renderMultiValuesAsText={emailOptions.length > 10}
                                formikProps={formik}
                                isMulti
                            />
                            <div className="modal-actions">
                                <ValidationFormSubmitButton formikProps={formik}>
                                    {Localizer.createMail_SendMail()}
                                </ValidationFormSubmitButton>
                            </div>
                        </>
                    )
                }
            </ValidationForm>
        </>
    );
}
