import useLogbogApi from "core/hooks/useLogbogApi";
import useUser from "core/hooks/useUser";
import { useCallback, useEffect, useState } from "react";
import { LaegekursusGruppeModel } from "services/api/laegekursus/laegekursusGruppeModel";
import { LaegekursusModel } from "services/api/laegekursus/laegekursusModel";

const useLaegeKursusGrupper = (uddannelseslaegeId: string, brugerKursusRaekkeId: string, uddannelseId: string, shouldFetch: boolean) => {
  const { currentUser } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [kursusGrupper, setKursusGrupper] = useState<LaegekursusGruppeModel[]>([]);
  const [deletedLageKurser, setDeletedLaegekurser] = useState<LaegekursusModel[]>([]);
  
  const { logbogLaegekursusApi, logbogLaegekursusGruppeApi } = useLogbogApi();

  const fetchKursusGrupper = useCallback(async () => {
    logbogLaegekursusGruppeApi.getKursusGrupperByBrugerKursusraekke(brugerKursusRaekkeId)
      .then(result => setKursusGrupper(result))
      .finally(() => setIsLoading(false));
  }, [brugerKursusRaekkeId, logbogLaegekursusGruppeApi])

  const fetchDeletedLaegekurser = useCallback(async () => {
    if (!currentUser.IsAdminOrVus()) return;

    const deletedLaegekurserForUser = await logbogLaegekursusApi.getDeletedKurserForUser(uddannelseslaegeId, uddannelseId);
    setDeletedLaegekurser(deletedLaegekurserForUser);
  }, [currentUser, logbogLaegekursusApi, uddannelseId, uddannelseslaegeId]);

  useEffect(() => {
    if (shouldFetch) {
      fetchKursusGrupper();
    }
  }, [fetchKursusGrupper, shouldFetch])

  useEffect(() => {
    if (shouldFetch) {
      fetchDeletedLaegekurser();
    }
  }, [fetchDeletedLaegekurser, shouldFetch])

  const kursusGruppeHalfLength = Math.ceil(kursusGrupper.length / 2);

  return {
    kursusGrupper,
    kursusGruppeHalfLength,
    deletedLageKurser,
    isLoading,
    refetch: () => {
      fetchKursusGrupper();
      fetchDeletedLaegekurser();
    }
  }


}

export default useLaegeKursusGrupper;
