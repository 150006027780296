import {Localizer} from "../../../infrastructure/localization/localizer";

export enum UddannelseTypeEnum {
    KBU = 1,
    Introduktion = 2,
    Hoveduddannelse = 3
}

export function GetUddannelsestypeTranslation(type?: UddannelseTypeEnum) {
    switch (type) {
        case UddannelseTypeEnum.KBU:
            return Localizer.global_KBU();
        case UddannelseTypeEnum.Introduktion:
            return Localizer.global_introduktion();
        case UddannelseTypeEnum.Hoveduddannelse:
            return Localizer.global_hoveduddannelse();
        default:
            return "";
    }
}
export function GetUddannelsestypeFromIdTranslation(id: string) {
    switch (id.toUpperCase()) {
        case "3ABC3B00-E8AD-4473-9F61-AC35752039F9":
            return Localizer.global_KBU();
        case "5C162F19-D8DF-4306-884D-B393EE628C5C":
            return Localizer.global_introduktion();
        case "65A715A9-9BA4-48F5-99F5-57722AF02C41":
            return Localizer.global_hoveduddannelse();
        default:
            return "";
    }
}
