import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import useScreenResolution from "core/hooks/useScreenResolution";
import {EmblaIconsType} from "core/emblaIcons";
import ExternalLink from "../../components/link/ExternalLink";

type LeftMenuLinkProps = {
    routePath: string;
    emblaIconName: EmblaIconsType;
    linkText: string;
    disabled?: boolean;
}

export const LeftMenuExternalLink = ({emblaIconName, ...props}: LeftMenuLinkProps) => {
    const {isMobile} = useScreenResolution();
    return (
        <ExternalLink link={props.routePath}
                      classes={"menu-link"}>
            <div className={`flex-container ${isMobile ? "menu-link-item" : ""}`}>
                <div className="menu-link-icon">
                    <EmblaIcon iconName={emblaIconName}/>
                </div>
                <div className={`menu-link-text ${props.disabled ? " disabled" : ""}`}>{props.linkText}</div>
            </div>
        </ExternalLink>

    );
};

