import React from "react";
import {AccountService} from "../../services/account/accountService";
import {SetTitleBarWithActions} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "../../infrastructure/localization/localizer";
import {RoutePaths} from "../../infrastructure/routes";
import {CreateEvalueringsstatistik} from "./createEvalueringsstatistik";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {Spoergeramme} from "./components/spoergeramme";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import {useAppDispatch} from "../../app/hooks";
import {GenericDownloadButton} from "core/components/fileMetadata/genericDownloadButton";
import {LogbogExportApi} from "services/api/logbogExport/logbogExportApi";
import {HttpClientService} from "services/httpClient/httpClientService";
import {FileDownloadHandler} from "services/api/logbogFile/fileDownloadHandler";
import {EvalueringStatistikApi} from "../../services/api/evalueringStatistik/evalueringStatistikApi";
import {EmblaIcons} from "core/emblaIcons";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {DispatchShowTitlebar, ShouldBeFullscreen} from "../../core/layout/responsive";

const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());

export function CreateEvalueringsstatistikPage() {
    const evalueringStatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());
    const userService = new AccountService();
    const user = userService.getUser();

    const spoergerammeModalTarget = "see-spoergeramme-modal-create-page";
    const dispatch = useAppDispatch();

    const setLuk = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: spoergerammeModalTarget}));
    }

    const path = RoutePaths.CreateEvalueringsstatistik.path;
    DispatchShowTitlebar(path);

    if (user.Authenticated) {
        SetTitleBarWithActions(Localizer.evalueringsstatistikPage_createStatistic(),
            [{text: Localizer.global_evalueringsstatistik(), url: RoutePaths.Evalueringsstatistik.url}, {text: Localizer.evalueringsstatistikPage_createStatistic(), url: RoutePaths.CreateEvalueringsstatistik.url}],
            [{text: Localizer.evalueringsstatistikPage_seeSpoergeramme(), dataTarget: spoergerammeModalTarget}]);
    } else {
        SetTitleBarWithActions(Localizer.evalueringsstatistikPage_createStatistic(),
            [{text: Localizer.global_forside(), url: "/"}, {text: Localizer.global_evalueringsstatistik(), url: RoutePaths.Evalueringsstatistik.url}, {text: Localizer.evalueringsstatistikPage_createStatistic(), url: RoutePaths.CreateEvalueringsstatistik.url}],
            [{text: Localizer.evalueringsstatistikPage_seeSpoergeramme(), dataTarget: spoergerammeModalTarget}]);
    }

    return (
        <React.Fragment>
            <CreateEvalueringsstatistik/>
            <SlideIn
                id={spoergerammeModalTarget}
                title={Localizer.spoergerammeModal_spoergeramme()}
                actionText={Localizer.spoergerammeModal_spoergeramme()}
                actionFinishedCallback={() => setLuk()}
                actionOnCloseCallback={() => setLuk()}
                bodyContent={<Spoergeramme modalId={spoergerammeModalTarget}/>}
                modalSize={ModalSize.md}
                additionalHeaderItems={
                    <>
                        {user.Authenticated && // Remove when opening up for download on public-site
                            <GenericDownloadButton
                                additionalClasses={`margin-left-m`}
                                clickFunction={async () => exportApi.getEvalueringSpoergerammePdf()}
                                child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                            />
                        }
                        {!user.Authenticated && // Get pdf from blobstorage when using public-site
                            <GenericDownloadButton
                                additionalClasses={`margin-left-m`}
                                clickFunction={async () => evalueringStatistikApi.downloadPublicSpoergeRamme()}
                                child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                            />
                        }
                    </>
                }
            />
        </React.Fragment>

    );
}
