import {Localizer} from "../../../infrastructure/localization/localizer";

export enum FravaerskodeEnum {
    Orlov = 91000,
    OrlovBarsel = 91001,
    OrlovSygdom = 91002,
    OrlovForskning = 91003,
    FravaerMerit = 91004,
    FratraadtUddannelsesforloeb = 91005,
    OrlovPersonligeAarsager = 91006,
    FravaerUdland = 91007,
    FravaerMidlertidig = 91008,
}

export function GetFravaerskodeTranslation(kode: FravaerskodeEnum | undefined) {

    switch (kode) {
        case FravaerskodeEnum.Orlov:
            return Localizer.Orlov();
        case FravaerskodeEnum.OrlovBarsel:
            return Localizer.OrlovBarsel();
        case FravaerskodeEnum.OrlovSygdom:
            return Localizer.OrlovSygdom();
        case FravaerskodeEnum.OrlovForskning:
            return Localizer.OrlovForskning();
        case FravaerskodeEnum.FravaerMerit:
            return Localizer.FravaerMerit();
        case FravaerskodeEnum.FratraadtUddannelsesforloeb:
            return Localizer.FratraadtUddannelsesforloeb();
        case FravaerskodeEnum.OrlovPersonligeAarsager:
            return Localizer.OrlovPersonligeAarsager();
        case FravaerskodeEnum.FravaerUdland:
            return Localizer.FravaerUdland();
        case FravaerskodeEnum.FravaerMidlertidig:
            return Localizer.FravaerMidlertidig();
        default:
            return Localizer.Fravaer();
    }
}
