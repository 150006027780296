import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {AccountService} from "../../account/accountService";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import {ForloebOverviewFilterModel} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";
import {forloebOverviewFilterRequestModel} from "../forloeb/forloebOverviewFilterRequestModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {FilterParamsModel} from "../../../core/sharedmodels/filter/FilterParamsModel";
import {BrugerListFilter} from "../../../pages/bruger/brugerListFilter";
import {userListExportRequestModel} from "../logbogUser/logbogUserListExportRequest";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {EvalueringsstatistikRequestModel} from "../evalueringStatistik/evalueringsstatistikRequestModel";
import {EvalueringsstatistikInputModel} from "core/componentsPage/evalueringsstatistik/evalueringsstatistikInputModel";
import {Localizer} from "infrastructure/localization/localizer";

export class LogbogExportApi {
    constructor(httpClientService: HttpClientService, userService: AccountService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;
    private fileDownloadHandler: FileDownloadHandler;

    private baseUrl = () => config.logbogApiUrl + "api/export/";
    private publicBaseUrl = () => config.logbogApiUrl + "api/exportpublic/";

    private getPdfKompetenceUrl = () => this.baseUrl() + "pdf/kompetence/";
    private getPdfKompetencerUrl = () => this.baseUrl() + "pdf/kompetencer/";
    private getPdfKursusUrl = () => this.baseUrl() + "pdf/kursus/";
    private getBeskaeftigelseUrl = () => this.baseUrl() + "pdf/beskaeftigelse/";
    private getAttesationPdfUrl = () => this.baseUrl() + "pdf/attestation/";
    private getEvalueringStatistikPdfUrl = () => this.baseUrl() + "pdf/evalueringStatistik/";
    private getEvalueringSpoergerammePdfUrl = () => this.baseUrl() + "pdf/evalueringSpoergeramme/";
    private getVejledersamtalerPdfUrl = () => this.baseUrl() + "pdf/vejledersamtaler/";
    private getUddannelsesplanerPdfUrl = () => this.baseUrl() + "pdf/uddannelsesplaner/";
    private getLaegerWithForloebExcelUrl = () => this.baseUrl() + "excel/laegerWithForloeb/";
    private getStillingsOverviewExcelUrl = () => this.baseUrl() + "excel/stillingsOverview/";
    private getLaegeWithForloebExcelUrl = () => this.baseUrl() + "excel/laegeWithForloeb/";
    private getBrugereExcelUrl = () => this.baseUrl() + "excel/totalBrugerliste/";
    private getEvalueringStatistikComplexUrl = () => this.baseUrl() + "excel/evalueringStatistik/";
    private getEvalueringStatistikUrl = () => this.publicBaseUrl() + "evaluering/evalueringStatistik/";
    private getEvalueringStatistikPdfByForloebIdUrl = () => this.baseUrl() + "pdf/evalueringStatistikByForloebId/";
    private getAllDataForUserUrl = () => this.baseUrl() + "pdf/getalldataforuser/";
    private getKompetenceEvalueringPdfUrl = () => this.baseUrl() + "pdf/kompetenceEvaluering/";

    async getAttesationPdf (brugerId: string, brugerMaalbeskrivelseId: string, forloebId?: string): Promise<void> {
        const url = this.getAttesationPdfUrl() + brugerId + "/" + brugerMaalbeskrivelseId + "/" + forloebId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_attestationForTidsmaessigtGennemfoertUddannelse());
    }

    async getPdfKompetence (kompetenceId: string, brugerId: string, brugerMaalbeskrivelseId: string): Promise<void> {
        const url = this.getPdfKompetenceUrl() + kompetenceId + "/" + brugerId + "/" + brugerMaalbeskrivelseId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_kompetence());
    }

    async getPdfVejledersamtaler ( brugerId: string, brugerMaalbeskrivelseId: string, forloebId: string, stillingId: string ): Promise<void> {
        const url = this.getVejledersamtalerPdfUrl() + brugerId + "/brugerMaalbeskrivelse/" + brugerMaalbeskrivelseId + "/forloeb/" + forloebId+ "/stilling/" + stillingId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_vejledersamtaler());
    }

    async getPdfUddannelsesplaner ( brugerId: string, brugerMaalbeskrivelseId: string, forloebId: string, stillingId: string ): Promise<void> {
        const url = this.getUddannelsesplanerPdfUrl() + brugerId + "/brugerMaalbeskrivelse/" + brugerMaalbeskrivelseId + "/forloeb/" + forloebId+ "/stilling/" + stillingId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_uddannelsesplaner());
    }

    async getPdfKompetencer (brugerId: string, brugerMaalbeskrivelseId: string): Promise<void> {
        const url = this.getPdfKompetencerUrl() + brugerId + "/" + brugerMaalbeskrivelseId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_kompetencer());
    }

    async getPdfKursus (laegekursusgruppeid: string, brugerId: string, brugerMaalbeskrivelseId:string,): Promise<void> {
        const url = this.getPdfKursusUrl()  + "laegekursusgruppeId/"+ laegekursusgruppeid  + "/brugerId/" + brugerId + "/brugerMaalbeskrivelseId/" + brugerMaalbeskrivelseId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_kursus());
    }

    async getBeskaeftigelse (ansoegningsId: string, brugerId: string, brugerMaalbeskrivelseId: string, uddannelseTypeEnum: UddannelseTypeEnum): Promise<void> {
        const url = this.getBeskaeftigelseUrl() + ansoegningsId + "/" + brugerId + "/" + brugerMaalbeskrivelseId + "/" + uddannelseTypeEnum;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_beskaeftigelse());
    }

    async getFilteredLaegerWithForloebDetails(filter: ForloebOverviewFilterModel, filterParams: FilterParamsModel): Promise<void> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParams);
        const url = this.getLaegerWithForloebExcelUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.DownloadFile(url, ".xlsx");
        this.fileDownloadHandler.DownloadFile(result, "LaegerMedForloeb.xlsx");
    }

    async getFilteredStillingerWithForloebDetails(filter: ForloebOverviewFilterModel, filterParams: FilterParamsModel): Promise<void> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParams);
        const url = this.getStillingsOverviewExcelUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.DownloadFile(url, ".xlsx");
        this.fileDownloadHandler.DownloadFile(result, "StillingerMedForloeb.xlsx");
    }

    async getLaegeWithForloebDetails(forloebUserId: string): Promise<void> {
        const result = await this.httpClientService.DownloadFile(this.getLaegeWithForloebExcelUrl() + forloebUserId, ".xlsx");
        this.fileDownloadHandler.DownloadFile(result, "LaegeMedForloeb.xlsx");
    }

    async getBrugereDetails(filter: BrugerListFilter, search?: string, order?: string): Promise<void> {
        const requestModel = new userListExportRequestModel(filter, search, order);
        const url = this.getBrugereExcelUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.DownloadFile(url, ".xlsx");
        this.fileDownloadHandler.DownloadFile(result, "Brugere.xlsx");
    }

    async getEvalueringStatistikExcel (input: EvalueringsstatistikInputModel): Promise<void> {
        const postModel = new EvalueringsstatistikRequestModel(input.startDato, input.slutDato, input.uddannelsesstedIds, input.specialer, input.uddannelsestyper);
        const url = this.getEvalueringStatistikComplexUrl()
        const blob = await this.httpClientService.DownloadFileWithBody(url, postModel, "application/json");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.global_evalueringsstatistik());
    }

    async getEvalueringStatistikPdf(forloebEvalueringId: string): Promise<void> {
        const url = this.getEvalueringStatistikPdfUrl() + forloebEvalueringId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.evalueringsstatistikPage_konkretEvaluering());
    }

    async getEvalueringSpoergerammePdf(): Promise<void> {
        const url = this.getEvalueringSpoergerammePdfUrl();
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.spoergerammeModal_spoergeramme());
    }

    async getEvalueringStatistikPdfByForloebId(forloebId: string): Promise<void> {
        const url = this.getEvalueringStatistikPdfByForloebIdUrl() + forloebId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.evalueringsstatistikPage_konkretEvaluering());
    }

    async getAllDataForUserByUserId(brugerId: string, zipFileName: string): Promise<void> {
        const url = this.getAllDataForUserUrl() + brugerId + "/" + zipFileName;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, zipFileName);
    }

    async getKompetenceEvalueringPdf(laegekompetenceEvalueringId: string, brugerMaalbeskrivelseId: string, brugerId: string, ): Promise<void> {

        const url = this.getKompetenceEvalueringPdfUrl()  + laegekompetenceEvalueringId  + "/brugerMaalbeskrivelse/" + brugerMaalbeskrivelseId + "/bruger/" + brugerId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, Localizer.Kompetencevurdering());
    }
}
