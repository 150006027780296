import {ForloebState} from "../../forloebSlice";
import {dateTimeStringFromApiToDate} from "../../../index";
import {UddannelseTypeEnum} from "../stilling/uddannelseTypeEnum";
import {ForloebRichResponseModel} from "../../../services/api/forloeb/forloebRichResponseModel";
import {StillingTypeEnum} from "../stilling/stillingTypeEnum";
import {OverlapCompareModel} from "../OverlapCompareModel";
import {FravaerskodeEnum, GetFravaerskodeTranslation} from "./fravaerskoderEnum";
import StringBuilder from "../stringBuilder/stringBuilder";
import { appendGenerelForloebsoverviewPillData } from "../stringBuilder/customFunctions/appendGenerelForloebsoverviewPillData";

export class ForloebModel implements OverlapCompareModel{
    public ToState(): ForloebState {
        if(!this.id)
            return {} as ForloebState;

        return {
            id: this.id,
            brugerId: this.brugerId,
            stillingId: this.stillingId,
            startDato: this.startDate.toString(),
            slutDato: this.endDate.toString(),
            skalIndgaaIEvalueringsstatistik: this.skalIndgaaIEvalueringsstatistik,
            irrelevant: this.irrelevant,
            afdelingName: this.afdelingName,
            stillingNummer: this.stillingNummer,
            laegeInstitutionName: this.laegeInstitutionName,
            laegeInstitutionId: this.laegeInstitutionId,
            uddannelseType: this.uddannelseType,
            uddannelseId: this.uddannelseId,
            specialeId: this.specialeId,
            godkendelseId: this.godkendelseId,
            godkendtDen: this.godkendtDen?.toString(),
            godkendtKommentar: this.godkendtKommentar,
            godkendtAfNavn: this.godkendtAfNavn,
            godkendtAfRolleTitel: this.godkendtAfRolleTitel,
            godkendtAfAutorisationId: this.godkendtAfAutorisationId,
            brugerMaalbeskrivelseId: this.brugerMaalbeskrivelseId,
            brugerKursusraekkeId: this.brugerKursusraekkeId,
            maalbeskrivelseNavn: this.maalbeskrivelseNavn,
            maalbeskrivelseVersionsdato: this.maalbeskrivelseVersionsdato?.toString(),
            afdelingId: this.afdelingId,
            regionId: this.regionId,
            videreuddannelsesRegionId: this.videreuddannelsesRegionId,
            videreuddannelsesRegionNavn: this.videreuddannelsesRegionNavn,
            regionNavn: this.regionNavn,
            seAlleKompetencer: this.seAlleKompetencer,
            senesteDatoForEvaluering: this.senesteDatoForEvaluering?.toString(),
            evalueretDato: this.evalueretDato?.toString(),
            stillingsType: this.stillingsType,
            manueltAfsluttet: this.manueltAfsluttet,
            manglerKursusOpdatering: this.manglerKursusOpdatering,
            attestationForTidInfoTekst: this.attestationForTidInfoTekst,
            erFravaer: this.erFravaer,
            fravaerskodeEnum: this.fravaerskodeEnum,
            stillingErAltidFravaer: this.stillingErAltidFravaer,
            harTidligereMidlertidigtFravaer: this.harTidligereMidlertidigtFravaer,
            kommerFraVu: this.kommerFraVU,
            deaktiveretFraVUSync: this.deaktiveretFraVUSync,
            specialeName: this.specialeName
        } as ForloebState
    }

    public static FromState(state: ForloebState): ForloebModel {
        const model = new ForloebModel();
        model.id = state.id;
        model.startDate = new Date(state.startDato);
        model.endDate = new Date(state.slutDato);
        model.stillingId = state.stillingId;
        model.brugerId = state.brugerId;
        model.skalIndgaaIEvalueringsstatistik = state.skalIndgaaIEvalueringsstatistik;
        model.irrelevant = state.irrelevant;
        model.senesteDatoForEvaluering = state.senesteDatoForEvaluering ? new Date(state.senesteDatoForEvaluering) : undefined;
        model.afdelingName = state.afdelingName;
        model.stillingNummer = state.stillingNummer;
        model.laegeInstitutionName = state.laegeInstitutionName;
        model.laegeInstitutionId = state.laegeInstitutionId;
        model.uddannelseType = state.uddannelseType;
        model.uddannelseId = state.uddannelseId;
        model.godkendelseId = state.godkendelseId ?? undefined;
        model.godkendtKommentar = state.godkendtKommentar;
        model.godkendtDen = state.godkendtDen ? new Date(state.godkendtDen) : undefined;
        model.godkendtAfNavn = state.godkendtAfNavn;
        model.godkendtAfRolleTitel = state.godkendtAfRolleTitel;
        model.godkendtAfAutorisationId = state.godkendtAfAutorisationId;
        model.afdelingId = state.afdelingId;
        model.seAlleKompetencer = state.seAlleKompetencer;
        model.evalueretDato = state.evalueretDato ? new Date(state.evalueretDato) : undefined;
        model.specialeName = state.specialeName;

        model.specialeId = state.specialeId;
        model.brugerKursusraekkeId = state.brugerKursusraekkeId;

        model.brugerMaalbeskrivelseId = state.brugerMaalbeskrivelseId;
        model.maalbeskrivelseNavn = state.maalbeskrivelseNavn;
        model.maalbeskrivelseVersionsdato = state.maalbeskrivelseVersionsdato ? new Date(state.maalbeskrivelseVersionsdato) : null;

        model.regionId = state.regionId;
        model.regionNavn = state.regionNavn;

        model.videreuddannelsesRegionId = state.videreuddannelsesRegionId;
        model.videreuddannelsesRegionNavn = state.videreuddannelsesRegionNavn;
        model.stillingsType = state.stillingsType;

        model.manueltAfsluttet = state.manueltAfsluttet;
        model.manglerKursusOpdatering = state.manglerKursusOpdatering;
        model.attestationForTidInfoTekst = state.attestationForTidInfoTekst;

        model.erFravaer = state.erFravaer;
        model.fravaerskodeEnum = state.fravaerskodeEnum;
        model.stillingErAltidFravaer = state.stillingErAltidFravaer;
        model.harTidligereMidlertidigtFravaer = state.harTidligereMidlertidigtFravaer;

        model.kommerFraVU = state.kommerFraVu;
        model.deaktiveretFraVUSync = state.deaktiveretFraVUSync;

        return model;
    }

    public static FromResponseModel(responseModel: ForloebRichResponseModel): ForloebModel {
        const model = new ForloebModel();

        model.id = responseModel.id;
        model.startDate = dateTimeStringFromApiToDate(responseModel.startDate);
        model.endDate = dateTimeStringFromApiToDate(responseModel.endDate);
        model.stillingId = responseModel.stillingId;
        model.brugerId = responseModel.brugerId;
        model.skalIndgaaIEvalueringsstatistik = responseModel.skalIndgaaIEvalueringsstatistik;
        model.irrelevant = responseModel.irrelevant;
        model.laegeInstitutionName = responseModel.laegeInstitutionName;
        model.laegeInstitutionId = responseModel.laegeInstitutionId;
        model.afdelingName = responseModel.afdelingName;
        model.stillingNummer = responseModel.stillingNummer;
        model.uddannelseType = responseModel.uddannelseType;
        model.uddannelseId = responseModel.uddannelseId;
        model.stillingName = responseModel.stillingName;
        model.specialeId = responseModel.specialeId;
        model.specialeName = responseModel.specialeName;
        model.godkendelseId = responseModel.godkendelseId ?? undefined;
        model.godkendtKommentar = responseModel.godkendtKommentar;
        model.godkendtDen = responseModel.godkendtDen ? dateTimeStringFromApiToDate(responseModel?.godkendtDen) : undefined;
        model.senesteDatoForEvaluering = responseModel.senesteDatoForEvaluering ? dateTimeStringFromApiToDate(responseModel?.senesteDatoForEvaluering) : undefined;
        model.godkendtAfNavn = responseModel.godkendtAfNavn;
        model.godkendtAfRolleTitel = responseModel.godkendtAfRolleTitel;
        model.godkendtAfUddannelsessted = responseModel.godkendtAfUddannelsessted;
        model.godkendtAfAutorisationId = responseModel.godkendtAfAutorisationId;
        model.brugerKursusraekkeId = responseModel.brugerKursusraekkeId;
        model.brugerMaalbeskrivelseId = responseModel.brugerMaalbeskrivelseId;
        model.maalbeskrivelseNavn = responseModel.maalbeskrivelseNavn;
        model.maalbeskrivelseVersionsdato = responseModel.maalbeskrivelseVersionsdato ? dateTimeStringFromApiToDate(responseModel.maalbeskrivelseVersionsdato) : null;
        model.afdelingId = responseModel.afdelingId;
        model.seAlleKompetencer = responseModel.seAlleKompetencer;
        model.stillingsType = responseModel.stillingsType;
        model.manueltAfsluttet = responseModel.manueltAfsluttet;
        model.manglerKursusOpdatering = responseModel.manglerKursusOpdatering;
        model.attestationForTidInfoTekst = responseModel.attestationForTidInfoTekst;

        model.regionId = responseModel.regionId;
        model.regionNavn = responseModel.regionNavn;
        model.omraadeNavn = responseModel.omraadeNavn;

        model.videreuddannelsesRegionId = responseModel.videreuddannelsesRegionId;
        model.videreuddannelsesRegionNavn = responseModel.videreuddannelsesRegionNavn;

        model.evalueretDato = responseModel.evalueretDato
            ? dateTimeStringFromApiToDate(responseModel?.evalueretDato)
            : undefined;

        model.erFravaer = responseModel.erFravaer;
        model.fravaerskodeEnum = responseModel.fravaerskodeEnum ?? undefined;
        model.stillingErAltidFravaer = responseModel.stillingErAltidFravaer;
        model.harTidligereMidlertidigtFravaer = responseModel.harTidligereMidlertidigtFravaer;

        model.kommerFraVU = responseModel.kommerFraVU;
        model.deaktiveretFraVUSync = responseModel.deaktiveretFraVUSync;
        model.uddannelseType = responseModel.uddannelseType;

        model.compareId = responseModel.brugerId;

        return model;
    }

    public GetStedInfo(useOmraadeInfo=false) {
        return this.stillingsType === StillingTypeEnum.Sygehus
            ? `${this.laegeInstitutionName}, ${this.afdelingName}`
            : useOmraadeInfo
                ? `${this.laegeInstitutionName}, ${this.omraadeNavn}`
                : this.laegeInstitutionName;
    }

    public DaysLeft(){
        const diff = this.endDate.getTime() - new Date().getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    public IsActiveForloeb(){
        const todayDate = new Date().getWithoutTime();
        const forloebStart = this.startDate.getWithoutTime();
        const forloebEnd = this.endDate.getWithoutTime();

        const isActive = forloebStart < todayDate && forloebEnd >= todayDate;
        return isActive;
    }

    private getStartEndDateDisplayString = () => `${this.startDate.dateWithoutTimeFormat(true) + " - " + this.endDate.dateWithoutTimeFormat(true)}`;

    private getFravaersDisplayString = () => {
        const fravaerskodeEnumStr = GetFravaerskodeTranslation(this.fravaerskodeEnum);
        const tooltipInfo = new StringBuilder(fravaerskodeEnumStr, '<br/>');

        tooltipInfo.append(this.fravaerskodeEnum?.toString() ?? "", !!this.fravaerskodeEnum);
        tooltipInfo.append(this.getStartEndDateDisplayString());
        return tooltipInfo.toString()
    }

    public ToDataString = () => {
        if(this.erFravaer)
            return this.getFravaersDisplayString();

        return appendGenerelForloebsoverviewPillData(
            this.regionNavn,
            this.specialeName,
            this.GetStedInfo(true),
            this.stillingNummer,
            this.uddannelseType,
            this.startDate,
            this.endDate,
            this.irrelevant,
            this.evalueretDato,
            this.godkendtDen
        ).toString();
    }

    public id: string;
    public startDate: Date;
    public endDate: Date;
    public skalIndgaaIEvalueringsstatistik: boolean;
    public irrelevant: boolean;
    public seAlleKompetencer: boolean;
    public stillingId: string;
    public afdelingId: string;
    public brugerId: string;
    public laegeInstitutionName: string;
    public laegeInstitutionId: string;
    public afdelingName: string;
    public stillingNummer: string;
    public uddannelseType: UddannelseTypeEnum;
    public uddannelseId: string;
    public stillingName: string;
    public specialeId: string;
    public specialeName: string;
    public godkendelseId?: string;
    public godkendtDen?: Date;
    public senesteDatoForEvaluering?: Date;
    public godkendtKommentar?: string;
    public godkendtAfNavn: string;
    public godkendtAfRolleTitel: string;
    public godkendtAfUddannelsessted: string;
    public godkendtAfAutorisationId: string;
    public stillingsType: StillingTypeEnum;
    public manueltAfsluttet: boolean;
    public manglerKursusOpdatering: boolean;
    public attestationForTidInfoTekst?: string;

    //kursusraekke
    brugerKursusraekkeId: string;

    //maalbeskrivelse
    brugerMaalbeskrivelseId: string;
    maalbeskrivelseNavn: string;
    maalbeskrivelseVersionsdato: Date | null;

    //Region
    regionId: string;
    regionNavn: string;

    //Omraade
    omraadeNavn: string;

    //VidereuddannelsesRegion
    videreuddannelsesRegionId: string;
    videreuddannelsesRegionNavn: string;

    public evalueretDato?: Date;

    //Fravaer
    public erFravaer: boolean;
    public fravaerskodeEnum?: FravaerskodeEnum;
    public stillingErAltidFravaer: boolean;
    public harTidligereMidlertidigtFravaer: boolean;

    public kommerFraVU: boolean;
    public deaktiveretFraVUSync: boolean;

    public compareId: string;
}
