import {FoldersResponseModel} from "../../../services/api/stamdataMappe/FoldersResponseModel";
import CollapseFolderWithItems from "./collapsableFolderWithFiles";
import useStamdataApi from "../../hooks/useStamdataApi";
import {NotificationModule} from "ditmer-embla";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";
import useUser from "../../hooks/useUser";

type FolderAndLinksProps = {
    foldersAndLinks: FoldersResponseModel;
    deleteCallback: (link: LinkModel) => void;
    editLink: (link: LinkModel) => void;
}

export const FolderAndLinks = ({foldersAndLinks, deleteCallback, editLink}: FolderAndLinksProps) => {
    const {stamdataLinkApi} = useStamdataApi();
    const {currentUser} = useUser();

    const deleteLink = async (link: LinkModel) => {
        await stamdataLinkApi.deleteLink(link.id).then(() => {
            NotificationModule.showSuccess(Localizer.helpPage_deletedLink(), "")
            deleteCallback(link);
        });

    }

    const showEditAndDelete = currentUser.IsAdminOrVus();

    return (
        <>
            {foldersAndLinks?.mapperMedIndhold?.map((m, index) =>
                <CollapseFolderWithItems
                    folder={m}
                    index={index}
                    deleteLinkAction={showEditAndDelete ? deleteLink : undefined}
                    editLinkAction={showEditAndDelete ? editLink : undefined}
                    expandedDefault={true}
                />
            )}
        </>
    )
}
