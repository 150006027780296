import {Link, useLocation} from "react-router-dom";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import useScreenResolution from "core/hooks/useScreenResolution";
import {NotificationModule} from "ditmer-embla";
import {Localizer} from "../../../infrastructure/localization/localizer";
import { EmblaIconsType } from "core/emblaIcons";
import CounterCircle from "core/components/notifications/counterCircle/counterCircle";

type LeftMenuLinkProps = {
    routePath: string;
    routePathsToHighlight: string[];
    emblaIconName: EmblaIconsType;
    linkText: string;

    showNotification?: boolean;
    showNotificationAmount?: number;
    linkState?: {};
    disabled?: boolean;
}
const notifyForloebOverblikMobilInfo = () =>  {
    NotificationModule.timeout = 5000;
    NotificationModule.showInfoSmall(Localizer.infoBeskedForloebMenuMobilVisning());
    NotificationModule.timeout = 3000;
}

const LeftMenuLink = (props: LeftMenuLinkProps) => {
    const location = useLocation();
    const currentUrl = location.pathname;

    const { isMobile } = useScreenResolution();

    return (
        <Link to={!props.disabled ? {pathname: props.routePath, state: props.linkState} : {}}
              className={`menu-link ${currentUrlMatches(currentUrl, props.routePathsToHighlight) ? "active" : ""} ${props.showNotification ? " flex-space" : ""} ${props.disabled ? " disabled" : ""}`}
              onClick={props.disabled ? () => notifyForloebOverblikMobilInfo() : undefined}>
           <div className={`flex-container ${isMobile ? "menu-link-item" : ""}`}>
               <div className="menu-link-icon">
                   <EmblaIcon iconName={props.emblaIconName}/>
               </div>
               <div className={`menu-link-text ${props.disabled ? " disabled" : ""}`}>{props.linkText}</div>
           </div>
            {props.showNotification && !props.disabled &&
                <CounterCircle
                    count={props.showNotificationAmount}
                    dontShowCountIfZero
                    hideIfZero={false}
                    additionalClasses="margin-right-m"
                    size={!!props.showNotificationAmount ? "small" : "extra-small"}
                    color="color-primary"
                />
            }
        </Link>
    );
};

const currentUrlMatches = (currentUrl: string, paths: string[]): boolean => {
    return paths.some((p) => currentUrl.includes(p));
}

export default LeftMenuLink;
