import {Link} from 'react-router-dom'
import './titleBar.scss';
import {useAppSelector} from 'app/hooks';
import {
    TitlebarState,
    titlebarState
} from './titlebarSlice';
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";

export function TitleBar() {
    const titleBar = useAppSelector(titlebarState) as TitlebarState;

    const renderBreadcrumbs = () => {
        if (titleBar.breadcrumbs.some(breadcrumb => breadcrumb.text)) {
            return titleBar.breadcrumbs.map((breadcrumb, index) => {
                if (breadcrumb.url) {
                    return <div key={index}>
                        <Link to={breadcrumb.url} >
                            {breadcrumb.text}
                        </Link>

                        { titleBar.breadcrumbs.indexOf(breadcrumb) < titleBar.breadcrumbs.length - 1 &&
                            <span>
                                <EmblaIcon iconName={"arrow-right"} additionalClasses={"breadcrumb-arrow"} />
                            </span>
                        }
                    </div>
                }
                else {
                    return <p key={index}>{breadcrumb.text}</p>
                }
            });
        }

        return;
    };

    const renderActions = () => {
        if (titleBar.actions.some(action => action.text)) {
            return titleBar.actions.map((action, index) => {
                if (action.url || action.dataTarget) {
                    return <div key={index}>

                        {action.dataTarget &&
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target={`#${action.dataTarget}`}>{action.text}</button>
                        }

                        {action.url &&
                        <Link to={action.url} className={`btn btn-primary`}>
                            {action.text}
                        </Link>
                        }

                    </div>
                }
            });
        }

        return;
    };

    return (
        <>
            {titleBar.showTitlebar &&
                <div className="title-bar">
                    <div className="breadcrumbs-container">
                        { renderBreadcrumbs() }
                    </div>
                    <div className="title-bar-header">
                        <div className="title-bar-header-text flex-container-row align-items-center">
                            <h2>{titleBar.title}</h2>
                            {titleBar.titlebarLinks &&
                                titleBar.titlebarLinks
                            }
                        </div>
                        <div className="title-bar-actions">
                            { renderActions() }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
