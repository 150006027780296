import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import useEffectAsync from "../../infrastructure/effect";
import {BottomMenu} from "../../core/layout/buttomMenu/bottomMenu";
import {Localizer} from "../../infrastructure/localization/localizer";
import {BrugerList} from "./brugerList";
import {Loading} from "../../core/components/loading/loading";
import {LinkPlacementEnum} from "../../core/components/link/LinkPlacementEnum";
import {HelpLinks} from "../../core/components/link/helpLinks";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";

export function BrugerePage() {
    const [isLoading, setIsLoading] = useState(true);
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    SetTitleBar(Localizer.global_brugere(), [],
        <HelpLinks linkPlacement={LinkPlacementEnum.BrugerListPage} additionalClasses={"margin-left-s"}/> );

    useEffectAsync(async () => {
        setIsLoading(false);
    }, [])

    return (
        <div className="row top-level-row">
            <div className="col-md-12">
                <Loading isLoading={isLoading} text={Localizer.henterBrugere()}
                         useAsHidden={true}
                         emptyText={""}>
                    <BrugerList/>
                </Loading>
                {IsMobile &&
                    <BottomMenu/>
                }

            </div>
        </div>
    );
}
