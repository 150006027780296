import Badge from "./evalueringsBagde";

type FilterItemProps = {
    text: string,
    items?: string[]
}

const FilterItemDetails = ({text, items=[]} : FilterItemProps) => {
    return (
        <>
            <p className={"margin-bottom-0 margin-top-s"}>{text}</p>
            { items.map(r => <Badge key={r} text={r} />) }
        </>
    )
}

export default FilterItemDetails;
