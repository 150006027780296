import * as yup from "yup";
import moment from "moment";
import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";

export class CrudLaegekursusInputModel {
    constructor(forloebId: string, brugerId: string, laegekursusGruppeId: string, brugerKursusraekkeId: string, gruppeType: LaegekursusGruppeTypeEnum, titel?: string) {
        this.forloebId = forloebId;
        this.eksterntBrugerId = brugerId;
        this.laegekursusGruppeId = laegekursusGruppeId;
        this.brugerKursusraekkeId = brugerKursusraekkeId;
        this.gruppeType = gruppeType;
        this.titel = titel ?? "";
        this.startDato = new Date();
        this.slutDato = new Date();
        this.eventuelSpecifikation = "";
        this.godkendelseKommentar = "";
        this.status = LaegekursusStatusEnum.ManglerHandling;
        this.aendretEllerOprettetAfLaege = true;
        this.godkendtPaaAndetGrundlag = false;
        this.sortOrder = 0;
    }

    public id: string;
    public forloebId: string;
    public eksterntBrugerId: string;
    public laegekursusGruppeId: string;
    public brugerKursusraekkeId: string;
    public titel: string;
    public startDato?: Date;
    public slutDato?: Date;
    public eventuelSpecifikation?: string;
    public godkendelseKommentar?: string;
    public godkendtPaaAndetGrundlag: boolean;
    public status: LaegekursusStatusEnum;
    public aendretEllerOprettetAfLaege: boolean;
    public gruppeType: LaegekursusGruppeTypeEnum;
    public sortOrder: number;
}

export const BuildExisting = (id: string, forloebId: string, brugerId: string, laegekursusGruppeId: string, brugerKursusraekkeId: string, titel: string, status: LaegekursusStatusEnum, aendretEllerOprettetAfLaege: boolean, gruppeType: LaegekursusGruppeTypeEnum, godkendtPaaAndetGrundlag: boolean, sortOrder: number, eventuelSpecifikation?: string, startDato?: Date, slutDato?: Date, godkendelseKommentar?: string): CrudLaegekursusInputModel => {
    const inputModel = new CrudLaegekursusInputModel(forloebId, brugerId, laegekursusGruppeId, brugerKursusraekkeId, gruppeType);
    inputModel.id = id;
    inputModel.titel = titel;
    inputModel.startDato = startDato;
    inputModel.slutDato = slutDato;
    inputModel.eventuelSpecifikation = eventuelSpecifikation ?? "";
    inputModel.godkendelseKommentar = godkendelseKommentar ?? "";
    inputModel.status = status;
    inputModel.gruppeType = gruppeType;
    inputModel.aendretEllerOprettetAfLaege = aendretEllerOprettetAfLaege;
    inputModel.godkendtPaaAndetGrundlag = godkendtPaaAndetGrundlag;
    inputModel.sortOrder = sortOrder;
    return inputModel;
}

export const crudKursusValidationSchema = createValidationSchema<CrudLaegekursusInputModel>({
    id: yup.string().notRequired(),
    forloebId: yup.string().notRequired(),
    laegekursusGruppeId: yup.string().notRequired(),
    brugerKursusraekkeId: yup.string().required(),
    status: yup.number().notRequired(),
    aendretEllerOprettetAfLaege: yup.boolean().notRequired(),
    eksterntBrugerId: yup.string().notRequired(),
    titel: yup.string().required(Localizer.validate_titelRequired()),
    gruppeType: yup.number().notRequired(),
    sortOrder: yup.number().notRequired(),
    startDato: yup.date().when("gruppeType", {
        is: LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi,
        then: yup.date().notRequired(),
        otherwise: yup.date().test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            function (value) {
                return moment(value).isValid()
            }),
    }),
    slutDato: yup.date().when("gruppeType", {
        is: LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi,
        then: yup.date().notRequired(),
        otherwise: yup.date().test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            function (value) {
                return moment(value).isValid()
            })
            .test(
                "date-validation",
                Localizer.validate_slutDatoBeforeStartdate(),
                function (value) {
                    const parent = this.parent as CrudLaegekursusInputModel;
                    return !value || !parent.startDato || value >= parent.startDato
                }
            )
    }),
    eventuelSpecifikation: yup.string().notRequired(),
    godkendelseKommentar: yup.string().notRequired(),
    godkendtPaaAndetGrundlag: yup.boolean().notRequired()
});
