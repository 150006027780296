import {useAppSelector} from "app/hooks";
import classNames from "classnames";
import {ActionIcon} from "core/components/actionIcon/actionIcon";
import {FileMetadataList} from "core/components/fileMetadata/fileMetadataList";
import {Loading} from "core/components/loading/loading";
import {EmblaIcons} from "core/emblaIcons";
import {forloebReducer, ForloebSliceState} from "core/forloebSlice";
import useStamdataApi from "core/hooks/useStamdataApi";
import {FileLocationEnum} from "core/sharedmodels/file/fileLocationEnum";
import {Localizer} from "infrastructure/localization/localizer";
import {GenericIdPath} from "infrastructure/route/routePathConstants";
import {RoutePaths} from "infrastructure/routes";
import NoUddannelsestedFilesInFoldersCard from "pages/forloeb/filePage/components/NoUddannelsesstedFilesCard";
import {ForloebOverviewCard} from "pages/forloeb/forloebOverviews/forloebOverviewCard";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {FileData} from "services/api/stamdataMappe/FoldersModel";

type UddannelsesFilesProps = {
    showRightHeaderContent?: boolean;
    hideContent?: boolean;
}

const UddannelsesstedFiles = ({showRightHeaderContent=false, hideContent=false} : UddannelsesFilesProps) => {

    const {stamdataFileApi} = useStamdataApi();

    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState<FileData[]>([]);
    const [fileCount, setFileCount] = useState<number>(0);

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const { params } = useRouteMatch<GenericIdPath>();
    const uddannelseslaegeId = params.id;

    useEffect(() => {
        const fetch = async () => {
            if(forloebSliceState.forloebState.id) {
                const uddannelsesstedFiles = await stamdataFileApi.getMapperAndUddannelseFilesByForloeb(forloebSliceState.forloebState.id);
                setFileCount(uddannelsesstedFiles.length);
                setFiles(uddannelsesstedFiles);
            }
        }

        fetch().finally(() => setIsLoading(false));
    }, [forloebSliceState.forloebState.id, stamdataFileApi]);


    return (
        <ForloebOverviewCard
            title={Localizer.global_filesFromEducationPlace()}
            rightHeaderContent={
                <>
                    {showRightHeaderContent &&
                        <div className="d-flex">
                            <ActionIcon iconName={EmblaIcons.ArrowRight} routeUrl={RoutePaths.Files(uddannelseslaegeId).url}/>
                        </div>
                    }
                </>
            }
        >
            {!hideContent &&
                <Loading isLoading={isLoading} text={Localizer.forloebpage_getUddannelseFiler()} spinnerClasses={classNames(isLoading && "padding-m")}>
                    {files &&
                        <FileMetadataList
                            fileMetadatas={files.map(x => x.fileMetadata)}
                            fileLocation={FileLocationEnum.Stamdata}
                            withUploader={false}
                            hideBorder
                        />
                    }

                    {fileCount < 1 &&
                        <NoUddannelsestedFilesInFoldersCard />
                    }
                </Loading>
            }
        </ForloebOverviewCard>
    );
}

export default UddannelsesstedFiles;
