import React, {useState} from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import useEffectAsync from "../../infrastructure/effect";
import {Evalueringsstatistik} from "./evalueringsstatistik";
import {AccountService} from "../../services/account/accountService";
import {SetTitleBarWithActions} from "../../core/layout/titleBar/titleBarApi";
import {RoutePaths} from "../../infrastructure/routes";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {Spoergeramme} from "./components/spoergeramme";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import {setSlideinState, SlideInStateEnum} from "../../core/components/slideIn/slideInSlice";
import {useAppDispatch} from "../../app/hooks";
import {GenericDownloadButton} from "../../core/components/fileMetadata/genericDownloadButton";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {LogbogExportApi} from "../../services/api/logbogExport/logbogExportApi";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import {EvalueringStatistikApi} from "../../services/api/evalueringStatistik/evalueringStatistikApi";
import {EmblaIcons} from "core/emblaIcons";

export function EvalueringsstatistikPage() {
    const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
    const evalueringStatistikApi = new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler());
    const [isLoading, setIsLoading] = useState(true);
    const userService = new AccountService();
    const user = userService.getUser();
    const dispatch = useAppDispatch();
    const spoergerammeModalTarget = "see-spoergeramme-modal";

    const path = RoutePaths.Evalueringsstatistik.path;

    if(user.Authenticated){
        SetTitleBarWithActions(Localizer.global_evalueringsstatistik(),
            [],
            [{ text: Localizer.evalueringsstatistikPage_seeSpoergeramme(), dataTarget: spoergerammeModalTarget }]);
    } else {
        SetTitleBarWithActions(Localizer.global_evalueringsstatistik(),
            [{text: Localizer.global_forside(), url: "/"}, {text:Localizer.global_evalueringsstatistik(), url: RoutePaths.Evalueringsstatistik.url}],
            [{ text: Localizer.evalueringsstatistikPage_seeSpoergeramme(), dataTarget: spoergerammeModalTarget }]);
    }

    useEffectAsync(async () => {
        setIsLoading(false);
    }, [])

    const setLuk = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: spoergerammeModalTarget}));
    }

    return (
        <React.Fragment>
            <Evalueringsstatistik/>
            <SlideIn
                id={spoergerammeModalTarget}
                title={Localizer.spoergerammeModal_spoergeramme()}
                actionText={Localizer.spoergerammeModal_spoergeramme()}
                actionFinishedCallback={() => setLuk()}
                actionOnCloseCallback={() => setLuk()}
                bodyContent={<Spoergeramme modalId={spoergerammeModalTarget} />}
                modalSize={ModalSize.md}
                additionalHeaderItems={
                    <>
                        {user.Authenticated && // Generate pdf through exportapi when logged in
                            <GenericDownloadButton
                                additionalClasses={`margin-left-m`}
                                clickFunction={async () => exportApi.getEvalueringSpoergerammePdf()}
                                child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                            />
                        }
                        {!user.Authenticated && // Get pdf from blobstorage when using public-site
                            <GenericDownloadButton
                                additionalClasses={`margin-left-m`}
                                clickFunction={async () => evalueringStatistikApi.downloadPublicSpoergeRamme()}
                                child={<EmblaIcon iconName={EmblaIcons.Download}/>}
                            />
                        }
                    </>
                }
            />

        </React.Fragment>

    );
}
