import React from "react";
import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";
import Tooltip from "../tooltips/Tooltip";
import ExternalLink from "./ExternalLink";
import {Localizer} from "../../../infrastructure/localization/localizer";
import InfoBadgePill from "../badge/infoBadgePill";
import {BadgePaddingSizes, BadgeTypes} from "../badge/badgeConstants";

type LinkInfoProps = {
    links: LinkModel[];
    additionalClasses?: string;
}
export const LinkInfo = ({links, additionalClasses}: LinkInfoProps) => {
    return <div className={additionalClasses}>
        {
            links.map((link, index) =>
                <Tooltip title={link.tooltipTekst}>
                    <ExternalLink link={link.link}>
                        <InfoBadgePill badgeText={Localizer.guide()} badgeType={BadgeTypes.InfoLight} paddingSize={BadgePaddingSizes.Xs} additionalClasses={"margin-top-xs"}/>
                    </ExternalLink>
                </Tooltip>
            )
        }
    </div>
};
