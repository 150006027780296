import { NyhederOverviewFilterModel } from "core/components/nyheder/nyhederOverviewFilterModel";
import { NyhederPageConstants } from "core/components/nyheder/nyhederPageConstants";
import useLogbogApi from "core/hooks/useLogbogApi";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import { NyhedModel } from "core/sharedmodels/nyheder/nyhedModel";
import { RolleEnum } from "infrastructure/enums/userRole";
import { useCallback, useEffect, useState } from "react";

const initialFilterState: NyhederOverviewFilterModel = {
  paginationModel: new PaginationModel(1, NyhederPageConstants.nyhederPageLength, 0),
  search: "",
  advancedSearch: false,
  showUnoccupiedStillinger: false,
  showAnonymized: false,
  roller: []
};

const useNyhederFiltered = () => {
    const [nyhederState, setNyhederState] = useState<NyhedModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState(initialFilterState);
    const [filteredRecords, setFilteredRecords] = useState(0);

    const { logbogNyhederApi } = useLogbogApi();

    const getNyheder = useCallback(async ()  => {
        setIsLoading(true);
        const nyheder = await logbogNyhederApi.getNyheder(filter);
        setFilteredRecords(nyheder.recordsFiltered);
        setNyhederState(nyheder.data);
        setIsLoading(false);
    }, [filter, logbogNyhederApi])

    useEffect(() => {
        getNyheder();
    }, [getNyheder]);

    const changeSearch = (search: string) => {
        setFilter((prev) => (
            {
                ...prev,
                search: search,
                paginationModel: { ...prev.paginationModel, page: 1 }
            }
        ));
    }

    const changeRoles = (roles: RolleEnum[]) => {
        setFilter((prev) => (
            {
                ...prev,
                roller: roles,
                paginationModel: { ...prev.paginationModel, page: 1 }
            }
        ));
    }

    const handlePagination = (page: number) => {
        setFilter((prev) => (
            {
                ...prev,
                paginationModel: { ...prev.paginationModel, page: page},
            }
        ));
    };

    const setPageLenth = (pageLenth: number) => {
        setFilter((prev) => (
            {
                ...prev,
                paginationModel: { ...prev.paginationModel, page: 1, pageLength: pageLenth },
            }
        ));
    };

    return {
        nyhederState,
        paginationModel: { ...filter.paginationModel, recordCount: filteredRecords  } as PaginationModel,
        isLoading,
        changeSearch,
        changeRoles,
        handlePagination,
        setPageLenth,
        refetchNyheder: getNyheder
    }
}

export default useNyhederFiltered;
