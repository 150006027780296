import React from 'react'
import './notatCard.scss'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {FileMetadataList} from "../../components/fileMetadata/fileMetadataList";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {ReactComponent as EyeIcon} from "../../../content/icons/eye-gray.svg";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import {useAppSelector} from "../../../app/hooks";
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../components/userProfileAvatar/userProfileAvatarModel";
import {NotatType} from "../../sharedmodels/notat/notatType";
import Tooltip from "../../components/tooltips/Tooltip";
import NoteTextViewer from './tekstNotat/noteTextViewer';

type BasicNotatCardFieldProps = {
    notatInfo: BasicNotatModel
}

export const BasicNotatCardFields = (props: BasicNotatCardFieldProps) => {
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const isLaege = forloebSliceState.forloebState.brugerId === props.notatInfo.createdById;

    return (
        <>
            <div>
                <h5>{props.notatInfo.title}</h5>
            </div>

            <NoteTextViewer
                id={props.notatInfo.id}
                type={props.notatInfo.type}
                text={props.notatInfo.description}
            />

            <div className='margin-bottom-m margin-top-m'>
                <FileMetadataList fileMetadatas={props.notatInfo.fileMetadatas}
                                  fileLocation={FileLocationEnum.Logbog}/>
            </div>
            <div className='subtle d-flex flex-row flex-wrap'>
                <div className='notat-metadata-field margin-right-m'>
                    <EmblaIcon iconName='calendar'/>
                    <p className='margin-s'>{props.notatInfo.createdAt.dateAndTimeToApiPostFormat()}</p>
                </div>
                <div className={"flex-align-c"}>
                    <UserProfileAvatar size={UserProfileAvatarSizeEnum.extraSmall}
                                       userProfileAvatarModel={new UserProfileAvatarModel(props.notatInfo.createdById, props.notatInfo.createdByName, props.notatInfo.createdByInitials, isLaege)}
                                       userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                    />

                    {props.notatInfo.createdByName !== props.notatInfo.brugerName &&
                    <>
                        <div className={"margin-left-s"}>{'>'}</div>
                        <h5 className={"margin-left-s"}>{props.notatInfo.brugerName}</h5>
                    </>}
                </div>
            </div>

                <div className='notat-metadata-field'>
                    <EyeIcon/>
                    {props.notatInfo.isPrivate &&
                        <p className='small subtle margin-s'>{Localizer.global_private()}</p>
                    }

                    {props.notatInfo.visibleToEveryone &&
                        <p className='small subtle margin-s'>{Localizer.global_visibileForAll()}</p>
                    }

                    {(props.notatInfo.visibleForUserIds?.length > 0 && !(props.notatInfo.type === NotatType.Uddannelse)) &&
                        <p className='small subtle margin-s'>{Localizer.notatPage_synligForUdvalgte()}</p>
                    }

                    {props.notatInfo.type === NotatType.Uddannelse &&
                        <Tooltip title={Localizer.notatPage_ansaettelseOgUddannelsesaftalerAltidSynligForAdmin()}>
                            <p className='small subtle margin-s'>{Localizer.notatPage_synligForUdvalgte()}</p>
                        </Tooltip>
                    }

                    {props.notatInfo.type === NotatType.Uddannelsesprogram &&
                        <Tooltip title={Localizer.notatPage_uddannelsesprogrammerAltidSynligForAdmin()}>
                            <p className='small subtle margin-s'>{Localizer.notatPage_synligForUdvalgte()}</p>
                        </Tooltip>
                    }
                </div>
        </>
    );
}
