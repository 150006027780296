import { useAppDispatch, useAppSelector } from "app/hooks";
import { ForloebSliceState, forloebReducer } from "core/forloebSlice";
import { InitForloebState } from "pages/forloeb/forloebApi";
import { useEffect, useState } from "react";

/** Initializes forloeb-state to correct forloeb (if needed) 
 * 
 * Use this on all forloeb-specific-pages (so that page-refresh works)
*/
const useInitUddannelseslaegeForloebState = (uddannelseslaegeId: string, brugerMaalbeskrivelseId?: string, forloebId?: string) => {
  
  const dispatch = useAppDispatch();
  const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
  const [isInitializing, setIsInitializing] = useState(true);
  
  const isForloebStateInitializedToCorrectLaege = forloebSliceState.forloebState.brugerId === uddannelseslaegeId;

  useEffect(() => {
    const initialize = async () => {
      InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId, forloebId, brugerMaalbeskrivelseId)
        .finally(() => setIsInitializing(false));
    }
    initialize();
  }, [dispatch, forloebSliceState.forloebState, uddannelseslaegeId, brugerMaalbeskrivelseId, forloebId])

  return {
    forloebSliceState: forloebSliceState,
    initializedToCorrectLaege: isForloebStateInitializedToCorrectLaege,
    isInitializing
  }

}

export default useInitUddannelseslaegeForloebState;
