import {LaegeWithForloebModel} from "../../sharedmodels/forloeb/laegeWithForloebModel";
import {LaegeStillingModel} from "../../sharedmodels/stilling/laegeStillingModel";

export class UserProfileAvatarModel {
    constructor(userId: string,
                name: string,
                initials: string,
                isLaege?: boolean,
                userEmail?: string,
                profileImageId?: string,
                profileImageFileData?: Uint8Array[],
                phoneNumber?: string,
                anonymiseret?: boolean) {
        this.Name = name;
        this.UserId = userId;
        this.Initials = initials;
        this.Email = userEmail;
        this.IsLaege = isLaege;
        this.ProfileImageId = profileImageId;
        this.ProfileImageFileData = profileImageFileData;
        this.PhoneNumber = phoneNumber;
        this.Anonymiseret = anonymiseret;
    }

    Name: string;
    UserId: string;
    Initials: string;
    Email?: string;
    IsLaege?: boolean;
    ProfileImageId?: string;
    ProfileImageFileData?: Uint8Array[];
    PhoneNumber?: string;
    Anonymiseret?: boolean;

    static BuildFromLaegeWithForloebModel(laege: LaegeWithForloebModel) {
        return new UserProfileAvatarModel(
            laege.laegeId,
            laege.laegeNavn,
            laege.laegeInitialer,
            true,
            laege.laegeEmail,
            laege.profileImageId,
            undefined,
            laege.laegeTelefonnummer,
            laege.anonymiseret
        )
    }
    static BuildFromLaegeStillingModel(laege: LaegeStillingModel) {
        return new UserProfileAvatarModel(
            laege.userId,
            laege.laegeNavn,
            laege.initialer,
            true,
            laege.laegeEmail,
            laege.profileImageId,
            undefined,
            laege.laegeTelefonnummer,
            laege.anonymiseret
        )
    }
}
