import React, {useEffect, useState} from 'react'
import {LogbogNotatApi} from "../../../../services/api/logbogNotat/logbogNotatApi"
import {HttpClientService} from '../../../../services/httpClient/httpClientService';
import {ValidationForm} from '../../../components/validation/components/validationForm';
import {ValidationFormSubmitButton} from '../../../components/validation/components/validationFormButton';
import {Localizer} from '../../../../infrastructure/localization/localizer';
import {setSlideinState, SlideInStateEnum} from '../../../components/slideIn/slideInSlice';
import {HandleError} from '../../../infrastructure/errors/errorBoundary';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {NotatType} from 'core/sharedmodels/notat/notatType';
import {LaegekompetenceMeta} from 'core/sharedmodels/kompetence/laegekompetenceMeta';
import {
    clearSelectedLaegekompetenceMetas,
    kompetenceCardReducer,
    KompetenceCardState,
    setActiveKompetencePageTab,
    setKompetenceCardStateShowCheckboxes,
    toggleLaegekompetenceListRefreshPing
} from 'pages/kompetencerPage/cards/kompetenceCardSlice';
import {ReactFileUploaderFile} from 'core/components/fileUpload/reactFileUploader';
import {BasicNotatCrudFields} from "../basicNotatCrudFields";
import {FileMetadata} from 'core/sharedmodels/fileMetaData/fileMetaData';
import {
    BuildNewCrudNotatModel,
    crudNotatValidationSchema,
    NotatCrudModel
} from "../../../sharedmodels/notat/notatCrudModel";
import {forloebReducer, ForloebSliceState} from "../../../forloebSlice";
import {AccountService} from "../../../../services/account/accountService";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../layout/responsive";
import Tooltip from 'core/components/tooltips/Tooltip';

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService())

export interface CreateLaegekompetenceNotatProps {
    modalId: string;
    laegekompetencer: LaegekompetenceMeta[];
    notatType: NotatType;
    titel?: string;
}

export function CreateLaegekompetenceNotat(props: CreateLaegekompetenceNotatProps) {
    const dispatch = useAppDispatch();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const [createNotatFailed, setCreateNotatFailed] = useState(false);
    const [laegekompetencerState, setLaegekompetencerState] = useState(props.laegekompetencer);
    const [uploadedFilesState, setUploadedFilesState] = useState<ReactFileUploaderFile[]>([]);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const createLaegekompetenceNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const newNotat = await notatApi.createNotat(inputModel, uploadedFilesState.map(tf => tf.fileMetadata.id));

        if (newNotat) {
            setCreateNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
            dispatch(clearSelectedLaegekompetenceMetas())
            dispatch(setKompetenceCardStateShowCheckboxes([]))
            dispatch(setActiveKompetencePageTab(Localizer.global_notes()))
            dispatch(toggleLaegekompetenceListRefreshPing())
        }
    }

    useEffect(() => {
        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0) {
            setLaegekompetencerState(kompetenceCardState.selectedLaegekompetenceMetas.map(lkm => LaegekompetenceMeta.FromState(lkm, forloebSliceState.forloebState)));
        } else {
            setLaegekompetencerState([LaegekompetenceMeta.FromState(kompetenceCardState.activeLaegekompetenceMeta, forloebSliceState.forloebState)]);
        }
    }, [IsMobile, forloebSliceState.forloebState, kompetenceCardState.activeLaegekompetenceMeta, kompetenceCardState.selectedLaegekompetenceMetas])

    const fileUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        setUploadedFilesState(arr => [...arr, ...uploadedFiles]);
    }

    const fileDeletedCallback = (fileMetaData: FileMetadata) => {
        let updatedFileUploadedState = [...uploadedFilesState];
        let deleteIndex = updatedFileUploadedState.findIndex(file => file.fileMetadata.id === fileMetaData.id);

        updatedFileUploadedState.splice(deleteIndex, 1);
        setUploadedFilesState(updatedFileUploadedState);
    }

    const render = (
        <ValidationForm
            key={laegekompetencerState.map(lk => lk.laegekompetenceId).join()}
            initialValues={BuildNewCrudNotatModel(
                laegekompetencerState.map(x => x.laegekompetenceId),
                props.notatType,
                kompetenceCardState.activeLaegekompetenceMeta.UserId,
                props.titel)
            }
            validationSchema={crudNotatValidationSchema}
            onSubmit={async (values) => {
                await createLaegekompetenceNotat(values)
                    .catch(err => {
                        HandleError(err);
                        setCreateNotatFailed(true);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }));
                    });
            }}>
            {(formik) => (
                <>
                    {laegekompetencerState &&
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            {laegekompetencerState.length > 1 &&
                            <h6>{Localizer.global_tilknyttedeKompetencer()}</h6>
                            }
                            {laegekompetencerState.length === 1 &&
                            <h6>{Localizer.global_tilknyttedeKompetence()}</h6>
                            }
                            {laegekompetencerState.map((laegekompetence, i) => (
                                <React.Fragment key={laegekompetence.kompetenceId + i}>
                                     <Tooltip
                                        className={"col-sm-12 text-truncate badge badge-pill badge-primary margin-right-xs"}
                                        title={laegekompetence.titel}
                                    >
                                        {laegekompetence.titel}
                                    </Tooltip>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>}

                    <BasicNotatCrudFields modalId={props.modalId} formik={formik}
                                          notatType={props.notatType}
                                          notatUserId={kompetenceCardState.activeLaegekompetenceMeta.UserId}
                                          fileUploadedCallbackOverride={fileUploadedCallback}
                                          fileDeletedCallbackOverride={fileDeletedCallback} />

                    <div className="modal-actions">
                        {createNotatFailed &&
                        <div className="form-group margin-bottom-m">
                            <div className="field-validation-error">{Localizer.notatPage_notatCreateFailed()}</div>
                        </div>
                        }

                        <ValidationFormSubmitButton formikProps={formik}>
                            {Localizer.notatPage_createNotat()}
                        </ValidationFormSubmitButton>
                    </div>
                </>
            )}
        </ValidationForm>
    )

    return <>{render}</>
}
