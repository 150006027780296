import React, {useEffect, useState} from "react";

import {LinkInfo} from "./linkInfo";
import {LinkPlacementEnum} from "./LinkPlacementEnum";
import useStamdataApi from "../../hooks/useStamdataApi";
import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";
import useUser from "core/hooks/useUser";

type HelpLinksProps = {
    linkPlacement: LinkPlacementEnum;
    additionalClasses?: string;
}
export const HelpLinks = ({linkPlacement, additionalClasses}: HelpLinksProps) => {
    const {stamdataLinkApi} = useStamdataApi();
    const [links, setLinks] = useState<LinkModel[]>([]);
    const { currentUser } = useUser();

    useEffect(() => {
        const fetch = async () => {
            const helpLinks = await stamdataLinkApi.getHelpLinks(linkPlacement);
            return helpLinks;
        };

        if (currentUser.Authenticated) {
            fetch().then((result) => setLinks(result));
        }
    }, [currentUser.Authenticated, linkPlacement, stamdataLinkApi]);


    return <>
        {links.length > 0 &&
            <LinkInfo links={links} additionalClasses={additionalClasses}/>
        }
    </>

};
