import React, {ReactNode, useState} from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {KompetenceCatagory} from "../../../pages/kompetencerPage/cards/kompetenceCardList";
import {Localizer} from "../../../infrastructure/localization/localizer";
import Title from "../titels/title";
import HtmlHeading from "../titels/HtmlHeadings";
import DynamicButton from "../button/dynamicButton";
import {EmblaIcons} from "../../emblaIcons";
import useUser from "../../hooks/useUser";

export type CollapseTabProps = {
    children: ReactNode;
    collapseTabAction?: () => void
    id: string;
    title: string;
    subTitle?: string;
    expandedDefault: boolean;
    showCircle?: boolean;
    categoryEnum?: KompetenceCatagory;
    toggleCallback?: (id: string, isExpanded: boolean) => void
    leftBorderColor?: string;
    additionalClasses?: string;
    hide?: boolean;
}

export const CollapseTab = ({hide = false, ...props}: CollapseTabProps) => {

    const [isExpanded, setIsExpanded] = useState<boolean>(props.expandedDefault);

    const toggleExpanded = () => {
        $("#" + props.id).collapse("toggle");
        setIsExpanded(!isExpanded);
        if (props.toggleCallback) {
            props.toggleCallback(props.id, !isExpanded)
        }
    }

    return (
        <div hidden={hide}>
            <div className={`d-flex flex-align-c ${props.leftBorderColor ? "dataset-list-item padding-right-m " : "card-list-box "} ${props.additionalClasses ? props.additionalClasses : ""}`} role="button"
                 data-toggle="collapse" aria-expanded={isExpanded} aria-controls={props.id}
                 onClick={toggleExpanded}>
                <div className={`${props.leftBorderColor ? "row " : ""}`}>
                    {props.leftBorderColor ?
                        <div className="col-sm-12 margin-top-s margin-bottom-s">
                            <div className="row">
                                <div className={"dataset-color-border "}
                                     style={{borderColor: props.leftBorderColor}}></div>
                                <div className="margin-left-m">
                                    <Title
                                        heading={HtmlHeading.H4}
                                        title={props.title}
                                        subTitle={props.subTitle}
                                        largeSubTitle
                                        nonSubtleSubTitle
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <h4 className="margin-right-m">
                            {props.title}
                        </h4>
                    }
                </div>

                <div className="d-flex flex-align-r">
                    {props.categoryEnum === KompetenceCatagory.Foraeldede &&
                    <div className="d-flex flex-align-c margin-right-m ">
                        <span className="badge badge-pill badge-warning padding-s"> {Localizer.global_foraeldede()}</span>
                    </div>
                    }
                    {props.collapseTabAction &&
                        <div className={"card-actions margin-right-m"}>
                            <DynamicButton
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    event.stopPropagation();
                                    if (props.collapseTabAction)
                                        props.collapseTabAction()
                                }}
                                rounded>
                                <DynamicButton.TextIconItem iconName={EmblaIcons.Plus}/>
                            </DynamicButton>
                        </div>
                    }
                <div className={`${props?.showCircle ?? "round"}`}>
                    {isExpanded &&
                        <EmblaIcon iconName="arrow-up"/>
                    }
                    {!isExpanded &&
                        <EmblaIcon iconName="arrow-down"/>
                    }
                    </div>
                </div>

            </div>

            <div id={props.id} className={props.expandedDefault ? "show" : "collapse"} aria-labelledby={"heading" + props.id}>
                {props.children}
            </div>
        </div>
    );
}
