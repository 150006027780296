import {Dispatch, useState} from 'react';
import {FileMetadata} from "../sharedmodels/fileMetaData/fileMetaData";
import {ReactFileUploaderFile} from "../components/fileUpload/reactFileUploader";
import {CreateMailInputModel} from "../components/mail/createMailInputModel";
import {UserWithForloebAndStillingId} from "../sharedmodels/email/UserWithForloebAndStillingId";
import {setSlideinState, SlideInStateEnum} from "../components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import useLogbogApi from "./useLogbogApi";
import {UserEmailModel} from "../components/mail/UserEmailModel";
import {ApiResponse} from "../../services/api/apiResponse";
import { EmailTypeEnum } from 'core/sharedmodels/email/emailTypeEnum';
import { NotificationModule } from 'ditmer-embla';
import { Localizer } from 'infrastructure/localization/localizer';
import { HandleError } from 'core/infrastructure/errors/errorBoundary';

const useUserEmail = (listUsers: UserEmailModel[], modalId: string, actionFinishedCallback?: () => void, useForloebContextEmails: boolean = false) => {
    const [fileMetadataState, setFileMetadataState] = useState<FileMetadata[]>([]);
    const [selectedEmailTypeEnum, setSelectedEmailTypeEnum] = useState<EmailTypeEnum>(EmailTypeEnum.Custom);
    const dispatch = useDispatch();

    const {logbogEmailApi} = useLogbogApi()

    const filesUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        const newFileMetadatas = uploadedFiles.map(rfu => rfu.fileMetadata);
        setFileMetadataState(arr => [...arr, ...newFileMetadatas]);
    }

    const fileDeletedCallback = async (fileMetadata: FileMetadata) => {
        let updatedFileList = [...fileMetadataState];
        let deleteIndex = updatedFileList.findIndex(f => f.id === fileMetadata.id);

        updatedFileList.splice(deleteIndex, 1);
        setFileMetadataState(updatedFileList);
    }

    const CreateMail = async (inputModel: CreateMailInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}));

        inputModel.fileMetadata = fileMetadataState.map((x) => x);

        var createEmailResponse: ApiResponse<string>;

        try {

            if (!useForloebContextEmails) {
                const userIdsToSendTo = listUsers.filter(pU => inputModel.userIds.some(hh => hh === pU.userId)).map(x => x.userId);
                createEmailResponse = await logbogEmailApi.CreateUserListEmail(inputModel, userIdsToSendTo, selectedEmailTypeEnum);
            } else {
                const usersWithForloebAndStillingerModels = listUsers.filter(pU => inputModel.userIds.some(hh => hh === pU.userId)).map(x => new UserWithForloebAndStillingId(x.userId, x.forloebId, x.stillingId));
                createEmailResponse = await logbogEmailApi.CreateEmail(inputModel, usersWithForloebAndStillingerModels, selectedEmailTypeEnum);
            }

        } catch(err: any) {
            HandleError(err);
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}));
            return;
        }

        const createEmailResponseMessage = createEmailResponse.apiResponseMessage;

        if (createEmailResponseMessage.errorMessage && createEmailResponseMessage.errorMessage !== "") {
            const errorMessage = createEmailResponseMessage.errorMessage;
            NotificationModule.showErrorSmall(errorMessage);
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}));
            return;

        } else {
            setFileMetadataState([]);

            if(actionFinishedCallback) {
                const successMessage = Localizer.global_emailSentToUsers(inputModel.userIds.length);
                NotificationModule.showSuccessSmall(successMessage);
                actionFinishedCallback();
            }
        }
    };

    const setSelectedTemplateType = (type: EmailTypeEnum) => {
        setSelectedEmailTypeEnum(type);
    }


    return {
        fileDeletedCallback,
        CreateMail,
        filesUploadedCallback,
        setSelectedTemplateType
    }
}
export default useUserEmail;
