const vejledningBasePath = "/vejledning";
const kompetencerBasePath = "/kompetencer";
const brevskabelonerBasePath = "/brevskabeloner";

const editPartialPath = "/rediger";
const createPartialPath = "/opret";
const downloadPartialPath = "/download";

const partialActiveTabIdUrl = "/(activeTabId)?/:activeTabId?";

export type GenericIdPath = {
    id: string;
}

export type RoutePath = {
    basePath?: string,
    pathDescription: string
}

export const uddannelseslaegeRoute: RoutePath = {
    pathDescription: "/uddannelseslaege/:uddannelseslaege",
};

export const sendStpsDocumentationRoute: RoutePath = {
    pathDescription: uddannelseslaegeRoute.pathDescription + "/ansoegning/:ansoegning/(step)?/:step?",
};

export const vejledningRoute: RoutePath = {
    basePath: vejledningBasePath,
    pathDescription: vejledningBasePath + uddannelseslaegeRoute.pathDescription + "/(vejledersamtale)?/:vejledersamtale?/(uddannelsesplan)?/:uddannelsesplan?",
};

export const brevskabelonRoute: RoutePath = {
    basePath: brevskabelonerBasePath,
    pathDescription: brevskabelonerBasePath + uddannelseslaegeRoute.pathDescription,
};

export const brevskabelonCreateRoute: RoutePath = {
    basePath: brevskabelonerBasePath + createPartialPath,
    pathDescription: brevskabelonerBasePath + createPartialPath + uddannelseslaegeRoute.pathDescription,
};

export const brevskabelonEditRoute: RoutePath = {
    basePath: brevskabelonerBasePath + editPartialPath,
    pathDescription: brevskabelonerBasePath + editPartialPath + uddannelseslaegeRoute.pathDescription + "/brev/:brev",
};

export const brevskabelonGeneratePdfRoute: RoutePath = {
    basePath: brevskabelonerBasePath + downloadPartialPath,
    pathDescription: brevskabelonerBasePath + downloadPartialPath + uddannelseslaegeRoute.pathDescription + "/brev/:brev",
};

export const kompetencerRoute: RoutePath = {
    basePath: kompetencerBasePath,
    pathDescription: kompetencerBasePath + uddannelseslaegeRoute.pathDescription + "/(kompetence)?/:kompetence?" + partialActiveTabIdUrl,
};

export const kompetenceEvalueringRoute: RoutePath = {
    basePath: kompetencerBasePath,
    pathDescription: kompetencerBasePath + uddannelseslaegeRoute.pathDescription + "/kompetence/:kompetence/kompetenceevaluering/:kompetenceevaluering",
};

export const filerRoute: RoutePath = {
    pathDescription: "/filer",
};
