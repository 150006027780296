import {EvalueringType} from "./evalueringType";
import {KompetenceSektionModel} from "./kompetenceSektionModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import {UserNameInfoModel} from "../../components/userProfile/userNameInfoModel";
import {
    KompetenceEvalueringResponseModel
} from "../../../services/api/logbogEvaluering/kompetenceEvalueringResponseModel";

export class KompetenceEvalueringModel {
    public static FromResponseModel(kompetenceEvaluering: KompetenceEvalueringResponseModel): KompetenceEvalueringModel{
        let model = new KompetenceEvalueringModel();

        model.evalueringsSkemaId = kompetenceEvaluering.evalueringsSkemaId;
        model.id = kompetenceEvaluering.id;
        model.kompetenceTitel = kompetenceEvaluering.kompetenceTitel;
        model.evalueringId = kompetenceEvaluering.evalueringId;
        model.privat = kompetenceEvaluering.privat;
        model.laegekompetenceEvalueringId = kompetenceEvaluering.kompetenceEvalueringId;
        model.indsendt = kompetenceEvaluering.indsendt;
        model.evalueringTypeEnum = kompetenceEvaluering.evalueringTypeEnum;
        model.titel = kompetenceEvaluering.titel;
        model.tekst = kompetenceEvaluering.tekst;
        model.indsendtDato = dateTimeStringFromApiToDate(kompetenceEvaluering.indsendtDato);
        model.createdBy = kompetenceEvaluering.createdBy;
        model.createdByUser = kompetenceEvaluering.createdByUser;
        model.sektioner = kompetenceEvaluering.sektioner.map(s => KompetenceSektionModel.FromResponseModel(s));

        return model;
    }

    id: string;
    evalueringsSkemaId: string;
    evalueringId: string;
    privat: boolean;
    titel: string;
    evalueringTypeEnum: EvalueringType;
    sektioner: KompetenceSektionModel[];
    laegekompetenceEvalueringId: string;
    kompetenceTitel: string;
    tekst: string;
    indsendt: boolean;
    indsendtDato: Date;
    createdBy: string;
    createdByUser: UserNameInfoModel;
}

