import React from "react";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {BasicNotatCardFields} from "./basicNotatCardFields";
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {NotatType} from "../../sharedmodels/notat/notatType";
import './notatCard.scss'
import classNames from "classnames";
import useUser from "core/hooks/useUser";
import { useRouteMatch } from "react-router-dom";
import { RoutePaths } from "infrastructure/routes";
import { GenericIdPath } from "infrastructure/route/routePathConstants";
import useLinkHistory from "infrastructure/route/useLinkHistory";
import { KompetencerPageLinkHistoryState } from "pages/kompetencerPage/kompetencerPage";

export type NotatCardProps = {
    notat: BasicNotatModel;
    clickEditNotatCallBack: (notat: BasicNotatModel) => void;
    editType?: NotatType;
    disableEdit?: boolean;
    enableGoToKompetenceOnPillClick?: boolean;
}

export const NotatCard = ({notat, enableGoToKompetenceOnPillClick=false, ...props}: NotatCardProps) => {
    const { currentUser } = useUser();
    const { history } = useLinkHistory<KompetencerPageLinkHistoryState>();
    const { params } = useRouteMatch<GenericIdPath>();

    const showKompetenceInfo = notat.type === NotatType.Kompetence || notat.type === NotatType.Merit

    const goToKompetence = (laegeKompetenceId: string) => {
        history.push(RoutePaths.Kompetencer({ uddannelseslaege: params.id }).url, { isLink: true, laegeKompetenceId: laegeKompetenceId });
    };

    return (
        <>
            <div className="card-body d-flex flex-column">
                <div className={"flex-space"}>
                    {showKompetenceInfo &&
                        <div className='d-flex flex-row flex-wrap'>
                            <p>{Localizer.global_kompetencer()}</p>
                            <EmblaIcon iconName='arrow-right'/>
                            <p className='text-lowercase fit-content-min-width'>{notat.notatReleationData?.length} {Localizer.global_tilknyttetKolon()} &nbsp;</p>
                            <div>
                                {notat.notatReleationData?.map((context, i) => (
                                    <React.Fragment key={context.contextId + i}>
                                        {context.contextTitle != null &&
                                            <div
                                                className={classNames("badge badge-pill badge-primary break-space-pills", !!enableGoToKompetenceOnPillClick && "clickable-no-coloring")}
                                                onClick={() => {
                                                    const laegeKompetenceId = context.contextId;
                                                    if(!laegeKompetenceId || !enableGoToKompetenceOnPillClick) return;
                                                    goToKompetence(laegeKompetenceId);
                                                }}
                                            >
                                                {context.contextTitle}
                                            </div>
                                        } &nbsp;
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    }

                    {notat.type === NotatType.TekstNotat &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.global_note()}</p>
                        </div>
                    }

                     {notat.type === NotatType.Uddannelsesprogram &&
                         <div className='d-flex flex-row'>
                             <p>{Localizer.forloebpage_uddannelsesprogram()}</p>
                         </div>
                     }

                    {notat.type === NotatType.AttestationForTid &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.forloebpage_AttestationForTid()}</p>
                        </div>
                    }

                    {notat.type === NotatType.Vejledersamtale &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.global_vejledersamtale()}</p>
                            <EmblaIcon iconName='arrow-right'/>
                            <p className='text-lowercase'>{notat.GetTitel()} </p>
                        </div>
                    }

                    {notat.type === NotatType.Uddannelsesplan &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.global_uddannelsesplan()}</p>
                            <EmblaIcon iconName='arrow-right'/>
                            <p className='text-lowercase'>{notat.GetTitel()} </p>
                        </div>
                    }

                    {notat.type === NotatType.Uddannelse &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.notatPage_ansaettelseOgUddannelsesaftale()}</p>
                        </div>
                    }

                    {notat.type === NotatType.Laegekursus &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.notatPage_laegekursus()}</p>
                        </div>
                    }

                    {notat.type === NotatType.LogbogLegacyNotat &&
                        <div className='d-flex flex-row'>
                            <p>{Localizer.notatPage_logbogLegacyNotat()}</p>
                            <EmblaIcon iconName='arrow-right'/>
                            <p>{notat.GetTitel()} </p>
                        </div>
                    }

                    {((props.editType === notat.type || props.editType === undefined) && notat.createdById === currentUser.UserId) && !props.disableEdit &&
                        <div className="notat-edit" onClick={() => props.clickEditNotatCallBack(notat)} role="button" aria-label={Localizer.notatPage_editNotat()}>
                            <EmblaIcon iconName='edit'/>
                        </div>
                    }
                </div>

                <BasicNotatCardFields notatInfo={notat}/>
            </div>
        </>
    );
}
