import {FileLocationEnum} from "core/sharedmodels/file/fileLocationEnum";
import {
    anyChildrenWithFiles,
    anyChildrenWithLinks,
    RenderFolderChildrenRecursively
} from "./renderFolderChildrenRecursively";
import {CollapseTab} from "../Tabs/collapseTab";
import {FileMetadataList} from "../fileMetadata/fileMetadataList";
import {MappestrukturModel} from "services/api/stamdataMappe/MappestrukturModel";
import {FileData} from "services/api/stamdataMappe/FoldersModel";
import {FileMetadata} from "core/sharedmodels/fileMetaData/fileMetaData";
import React from "react";
import {Link} from "./link";
import {LinkModel} from "../../../services/api/stamdataMappe/LinkModel";

type CollapseFolderWithFilesProps = {
    index: number;
    folder: MappestrukturModel;
    showCustomBadgeText?: (fileMetadataId: string) => string;
    childShowCustomBadgeText?: (fileMetadataId: string, files: FileData[]) => string;
    editFileAction?: (fileMetadataId: string) => void;
    editChildFileAction?: (fileMetadataId: string, files: FileData[]) => void;
    deleteFileAction?: (fileMetadata: FileMetadata) => void;
    deleteLinkAction?: (link: LinkModel) => void;
    editLinkAction?: (link: LinkModel) => void;
    expandedDefault?: boolean;
}

const CollapseFolderWithItems = ({index, folder, showCustomBadgeText, editFileAction, childShowCustomBadgeText, editChildFileAction, deleteFileAction, deleteLinkAction, editLinkAction, expandedDefault = false} : CollapseFolderWithFilesProps) => {

    const amountFilesInFolder = folder.files?.length ?? 0;

    return (
        <>
            {((anyChildrenWithFiles(folder.children) || (folder.files && folder.files?.length > 0)) || (anyChildrenWithLinks(folder.children) || (folder.links && folder.links?.length > 0))) &&
                <CollapseTab
                    id={folder.id + index}
                    title={amountFilesInFolder > 0 ? folder.mappeNavn.appendCounter(amountFilesInFolder) : folder.mappeNavn}
                    expandedDefault={expandedDefault}
                    additionalClasses={"folder"}
                    key={folder.id + index + amountFilesInFolder}
                >
                    <div className={"font-weight-bold padding-left-m"}>
                        <FileMetadataList
                            fileMetadatas={folder.files?.map(x => x.fileMetadata) ?? []}
                            fileLocation={FileLocationEnum.Stamdata}
                            showDeleteButton={!!deleteFileAction}
                            withUploader={false}
                            hideBorder={true}
                            showCustomBadgeText={showCustomBadgeText}
                            editFileOnClick={editFileAction}
                            fileDeletedCallbackOverride={deleteFileAction}
                        />
                    </div>
                    <div className={"font-weight-bold padding-left-m"}>
                        {folder.links?.map((link, index) =>
                            <Link link={link}
                                  deleteAction={deleteLinkAction}
                                  editLinkAction={editLinkAction}/>
                        )}
                    </div>
                    <RenderFolderChildrenRecursively
                        folderChildren={folder?.children}
                        showCustomBadgeText={childShowCustomBadgeText}
                        editFileAction={editChildFileAction}
                        deleteAction={deleteFileAction}
                        editLinkAction={editLinkAction}
                        deleteLinkAction={deleteLinkAction}
                        expandedDefault={expandedDefault}
                    />
                </CollapseTab>
            }
        </>

    )
}

export default CollapseFolderWithItems;
