import { Localizer } from "infrastructure/localization/localizer";
import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import FilterTitle from "core/components/filter/components/filterTitle";
import { DropdownOption, Dropdown } from "../../dropdown";
import { getNumberArrayRangeToDropdownOptions } from "../../dropdownOptions/numberRangeDropdownOptions";
import formatNumericDropdownOptionsToCustomLabel from "../../formatterFunctions/formatNumericDropdownOptionsToCustomLabel";

const all = Number.MAX_VALUE; // This is to force a null in the backend, beacuse the check is if PageLength is sat or not (MAX_VALUE cant be translated into a integer).
const defaultPageSizeDropdownRange = [25, 50, 75, all]; //Matches datatable defaults

const defaultFormatterFuncWithAllValue = (n: number) => n === all ? Localizer.global_all() : n.toString();

type PageSizeDropdownProps = {
    onChange: (newValue: SingleValue<DropdownOption<number>>) => void;
    initialSelected?: number;
    range?: number[];
    showTitle?:boolean;
    formatterFunc?: (n: number) => string;
}

const PageLengthDropdown = ({onChange, initialSelected, range=defaultPageSizeDropdownRange, showTitle=true, formatterFunc=defaultFormatterFuncWithAllValue} : PageSizeDropdownProps) => {

    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption<number>[]>([]);
    const [selectedOption, setSelectedOption] = useState<number>(initialSelected ?? range[0]);

    useEffect(() => {
        const options = getNumberArrayRangeToDropdownOptions(range);
        const formattetOptions = formatNumericDropdownOptionsToCustomLabel(options, formatterFunc);
        setDropdownOptions(formattetOptions);
    }, [formatterFunc, range])

    return (
        <>
            {showTitle &&
                <FilterTitle title={Localizer.global_vis()} />
            }
            <Dropdown
                isClearable={false}
                options={dropdownOptions}
                onChange={(newValue, _) => {
                    setSelectedOption(newValue!.value);
                    onChange(newValue);
                }}
                value={dropdownOptions.find(x => x.value === selectedOption)}
                isSearchable={false}
                ariaLabel={Localizer.global_vis()}
            />
        </>
    );
}

export default PageLengthDropdown;

export {
    defaultPageSizeDropdownRange,
    all
}
