import React, {useEffect, useState} from 'react'
import {VejledersamtaleEnum, VejledersamtaleModel} from "../vejledersamtaleModel";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {EditVejledersamtale} from "./editVejledersamtale";
import {NotificationModule} from "ditmer-embla";
import {SlideIn} from "../../../../core/components/slideIn/slideIn";
import {CreateVejledersamtale} from "./createVejledersamtale";
import {ModalAcceptType, ModalSubmitMessage} from 'core/components/modalMessage/modalSubmitMessage';
import Title from 'core/components/titels/title';
import useLogbogApi from 'core/hooks/useLogbogApi';
import VejlederAppointment from './vejlederAppointment';
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import useUser from "../../../../core/hooks/useUser";
import './vejledersamtale.scss'
import {useRouteMatch} from "react-router-dom";
import {userVejledningQueryParams} from "../../../../infrastructure/route/queryParams";

type VejledersamtaleProps = {
    vejledersamtale: VejledersamtaleModel
    vejledersamtalerUpdatedCallback: () => void;
}

export const Vejledersamtale = ({vejledersamtale, vejledersamtalerUpdatedCallback}: VejledersamtaleProps) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const editmodalId = "vejledersamtale-edit-modal";
    const createmodalId = "vejledersamtale-create-modal";
    const confirmModalId = "vejledersamtale-confirm-modal";

    const {currentUser} = useUser();
    const {vejledersamtaleApi} = useLogbogApi();

    const canEdit = vejledersamtale.currentUserHasAccess;

    const {params} = useRouteMatch<userVejledningQueryParams>();

    useEffect(() => {
        if (!params.vejledersamtale) return;
        setEditModalOpen(vejledersamtale.id === params.vejledersamtale);
    }, [params.vejledersamtale, vejledersamtale.id]);

    const editVejledersamtale = () => {
        if (vejledersamtale.id && canEdit) {
            setEditModalOpen(true);
        }
    }

    const createVejledersamtale = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setCreateModalOpen(true);
    }

    const vejledersamtaleOprettet = async () => {
        vejledersamtalerUpdatedCallback();
        NotificationModule.showSuccess(Localizer.vejledersamtalepage_vejledersamtaleCreated(), "");
    }

    const vejledersamtaleListUpdated = async () => {
        vejledersamtalerUpdatedCallback();
        setEditModalOpen(false)
    }

    const vejledersamtaleSlettet = async () => {
        vejledersamtalerUpdatedCallback();
        NotificationModule.showSuccess(Localizer.vejledersamtalepage_vejledersamtaleDeleted(), "");
    }
    const vejledersamtaleRedigeret = async (afholdt: boolean) => {
        vejledersamtalerUpdatedCallback();
        NotificationModule.showSuccess(afholdt ? Localizer.vejledersamtalepage_vejledersamtaleAfholdt() : Localizer.vejledersamtalepage_vejledersamtaleEditted(), "");
    }

    const fjernGodkendelse = async () => {
        const ikkeAfholdt = await vejledersamtaleApi.fjernGodkendelseVejlederSamtale(vejledersamtale.id);
        if (ikkeAfholdt) {
            NotificationModule.showSuccess(Localizer.vejledersamtalePage_deleteGodkendelseNotification(), "");
        }
        vejledersamtalerUpdatedCallback();
    }

    const toggleKlarmeld = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        await vejledersamtaleApi.toggleKlarmeldtAt(vejledersamtale.id)
        vejledersamtalerUpdatedCallback();
    }

    return (
        <>
            <div className="flex-container" onClick={() => editVejledersamtale()} aria-label={Localizer.vejledersamtaleEdit_EditVejledersamtale()}>

                {!vejledersamtale.id &&
                    <div className="flex-space flex-grow">
                        <div>
                            <Title title={vejledersamtale.titel}/>

                            {vejledersamtale.placeholderText &&
                                <div className="margin-top-s">
                                    <p className="small subtle">{vejledersamtale.placeholderText}</p>
                                </div>
                            }
                        </div>
                        <div className="align-self-center">
                            <button className="btn btn-default"
                                    onClick={(e) => createVejledersamtale(e)}>{Localizer.global_planlaeg()}
                            </button>
                        </div>
                    </div>
                }

                {vejledersamtale.id &&
                    <VejlederAppointment samtale={vejledersamtale}/>
                }

                {currentUser.IsLaege() && vejledersamtale.id && !vejledersamtale.afholdtTilkendegivetDato && !vejledersamtale.afholdtTilkendegivetAf &&
                    <button 
                        className={"btn btn-default rounded-circle btn-icon flex-align-r align-self-center"}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => toggleKlarmeld(e)}
                        aria-label={!!vejledersamtale.klarmeldtAt ? Localizer.global_removeKlarmeld() : Localizer.global_klarmeld()}
                    >
                        <EmblaIcon iconName={!!vejledersamtale.klarmeldtAt ? "remove" : "success"}/>
                    </button>
                }
            </div>

            {createModalOpen &&
                <SlideIn
                    id={createmodalId}
                    title={Localizer.vejledersamtalePage_createVejledersamtale()}
                    actionText={Localizer.global_creating()}
                    actionFinishedCallback={() => vejledersamtaleOprettet()}
                    actionOnCloseCallback={() => setCreateModalOpen(false)}
                    defaultOpen={true}
                    bodyContent={<CreateVejledersamtale modalId={createmodalId}
                                                        vejledersamtaleType={vejledersamtale.vejledersamtaleType ?? VejledersamtaleEnum.Anden}
                                                        titel={vejledersamtale.titel}/>}
                />
            }

            {editModalOpen &&
                <SlideIn
                    id={editmodalId}
                    title={Localizer.vejledersamtaleEdit_EditVejledersamtale()}
                    actionText={Localizer.global_editing()}
                    actionFinishedCallback={() => vejledersamtaleListUpdated()}
                    actionOnCloseCallback={() => setEditModalOpen(false)}
                    defaultOpen={true}
                    bodyContent={<EditVejledersamtale modalId={editmodalId}
                                                      model={vejledersamtale}
                                                      editedCallBack={(afholdt: boolean) => vejledersamtaleRedigeret(afholdt)}
                                                      deletedCallBack={() => vejledersamtaleSlettet()}
                                                      confirmModalId={confirmModalId + vejledersamtale.id}
                                                      openConfirmModal={() => setConfirmModalOpen(true)}
                    />}
                />
            }

            {confirmModalOpen &&
                <ModalSubmitMessage modalId={confirmModalId + vejledersamtale.id}
                                    title={Localizer.areYouSure()}
                                    description={<p>{Localizer.vejledersamtalePage_deleteGodkendelseDescription()}</p>}
                                    cancelAction={() => setEditModalOpen(true)}
                                    acceptAction={() => fjernGodkendelse()}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.global_ikkeAfholdt()}
                />
            }
        </>
    )
}
