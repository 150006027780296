import {PdfEvalueringStatistikHeaderComponent} from "core/components/pdf/pdfEvalueringStatistikHeaderComponent";
import {Localizer} from "infrastructure/localization/localizer";
import {LocalizerPageSpecifics} from "infrastructure/localization/localizerPageSpecifics";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {EvalueringPdfModel} from "../../../core/sharedmodels/evaluering/evalueringPdfModel";
import PdfComponent from "core/components/pdf/pdfComponent";
import {useFetchWithErrorHandlingPdf} from "../../../core/components/pdf/useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";

export function EvalueringStatistikPdf() {
    const [forloebEvaluering, setForloebEvaluering] = useState<EvalueringPdfModel>()

    const routeMatch = useRouteMatch();
    const routeParams = (routeMatch.params as { forloebEvalueringId: string });

    const setError = useFetchWithErrorHandlingPdf();
    const { forloebEvalueringApi } = useLogbogApi();

    useEffect(() => {
        const fetch = async () => {
            const evaluering = await forloebEvalueringApi.getForloebEvalueringForPdf(routeParams.forloebEvalueringId);
            setForloebEvaluering(evaluering);
        };
        fetch().catch(err => setError(err));
        (document as any).pdfReady = true;
    }, [forloebEvalueringApi, routeParams.forloebEvalueringId, setError])

    return (
        <PdfComponent>
            <PdfEvalueringStatistikHeaderComponent evaluering={forloebEvaluering}/>

            <PdfComponent.BlockItem className={"card"}>
                {forloebEvaluering?.svar &&
                    forloebEvaluering.svar.map((s, i) =>
                        <PdfComponent.NonBreakableItem className={"card-body"}>
                                <div className={"flex-container-row align-content-center"}>
                                    <div className={"col-4 pdf-list-row-column-item-center"}>{`${i+1}. ${s.spoergsmaal}`}</div>
                                    <div className={"col-8 pdf-list-row-column-item-center"}>{s.ikkeRelevant ? Localizer.global_notRelevant() : s.svarState}</div>
                                </div>
                                {s.kommentar !== null &&
                                <div className={"card-body"}>
                                    <div className={"flex-container-row align-content-center"}>
                                        <div className={"col-4 pdf-list-row-column-item-center"}>{LocalizerPageSpecifics.evalueringPdf_Kommentar()}</div>
                                        <div className={"col-8 pdf-list-row-column-item-center"}>{s.kommentar}</div>
                                    </div>
                                </div>}
                        </PdfComponent.NonBreakableItem>
                    )
                }
            </PdfComponent.BlockItem>

        </PdfComponent>
    );
}
