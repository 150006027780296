import {useAppDispatch } from 'app/hooks';
import { Localizer } from 'infrastructure/localization/localizer';
import {setValidated } from 'pages/vejledere/vejledereValidateDateSlice';
import { ModalMessage, showModalMessage } from '../modalMessage/modalMessage';
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import useStamdataApi from "../../hooks/useStamdataApi";
import {useEffect, useState} from "react";
import useUser from 'core/hooks/useUser';
import { todayDayjs } from 'index';

const modalId = "vejledere-alert-modal";

export const ValiderVejledereWarning = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { stamdataVejlederApi, stamdataUserApi } = useStamdataApi();
    const { currentUser } = useUser();

    const [showValidationMessage, SetShowValidationMessage] = useState(true);

    const shouldSeeValidateVejledere = currentUser.IsUddannelsesansvarlig() || currentUser.IsSekretaer();

    useEffect(() => {
        const checkShouldShowValidateVejledere = async () => {

            if(shouldSeeValidateVejledere) {
                const today = todayDayjs().toDate();

                const currentValidatedDate = await stamdataUserApi.getValidatedDate();

                if(!currentValidatedDate || currentValidatedDate.getWithoutTime().addMonths(6) < today) {
                    const showMessage = await stamdataVejlederApi.GetHasAccessToVejledere();
                    SetShowValidationMessage(showMessage);
                    if(showMessage) {
                        showModalMessage(modalId);
                    }
                }

                const currentValidatedDateString = currentValidatedDate?.dateWithoutTimeFormat(false) ?? Localizer.notValidated();
                dispatch(setValidated(currentValidatedDateString))
            }
        }
        checkShouldShowValidateVejledere();
    }, [dispatch, shouldSeeValidateVejledere, stamdataUserApi, stamdataVejlederApi]);

    const routeToVejledere = () => {
        history.push(RoutePaths.Vejledere.url);
    }

    return (
        <div>
            {showValidationMessage &&
                <ModalMessage 
                    title={Localizer.validateVejleder()}
                    description={Localizer.validateVejlederBeskrivelse()}
                    primaryButtonText={Localizer.vejlederpage_gennemgaaListenSenere()}
                    secondaryButtonText={Localizer.vejlederpage_gaaTilListenNu()}
                    secondaryButtonAction={routeToVejledere}
                    modalId={modalId} 
                />
            }
        </div>

    );
}
