import React from "react";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import useUser from "../../../core/hooks/useUser";
import {EmblaIcons} from "../../../core/emblaIcons";
import Tooltip from "../../../core/components/tooltips/Tooltip";

export class CompletedEvalueringCardProps {
    kompetenceEvaluering: KompetenceEvalueringModel;
    kompetenceId: string;
    kompetenceBrugerId: string;
    deleteKompetenceEvalueringCallback: (laegekompetenceEvalueringId: string) => void;
}
export const spoergsmaalCount = (evalueringModel: KompetenceEvalueringModel) => {
    const sektionerIncludedInProgress = evalueringModel.sektioner.filter(s => s.includedInProgress);
    const result = sektionerIncludedInProgress.reduce((counts, sektion) => {
        counts.spoergsmaalCount += sektion.spoergsmaal.length;
        counts.svarCount += sektion.spoergsmaal.filter(spoergsmaal => spoergsmaal.evalueringSvar.length > 0).length;
        return counts;
    }, { spoergsmaalCount: 0, svarCount: 0 });

    return `${result.svarCount}/${result.spoergsmaalCount}`;
}

export function KladdeKompetenceEvalueringCard({kompetenceEvaluering, kompetenceId, deleteKompetenceEvalueringCallback, kompetenceBrugerId}: CompletedEvalueringCardProps) {
    const history = useHistory();
    const isMobile = useMediaQuery(mobileMaxWidthQuery);
    const { currentUser } = useUser();

    const currentUserIsLaegeAndSpoergsmaalKanBesvaresAfLaege = kompetenceEvaluering.sektioner.some(s => s.spoergsmaal.some(sp => sp.laegerKanBesvare)) && currentUser.IsLaege();
    const showDeleteBtn = (kompetenceEvaluering.createdBy === currentUser.UserId && !currentUser.IsLaege()) || currentUser.IsAdminOrVus();
    const disableFortsaetBtn = !currentUser.IsAdminOrVus() &&
       kompetenceEvaluering.createdBy !== currentUser.UserId &&
        kompetenceEvaluering.privat &&
        !currentUserIsLaegeAndSpoergsmaalKanBesvaresAfLaege;
    const deleteKompetenceEvaluering = async (kompetenceEvalueringRelationId: string) => {
        deleteKompetenceEvalueringCallback(kompetenceEvalueringRelationId);
    }

    return (
        <div className="margin-top-m margin-bottom-m flex-container wrap">
            <div className="flex-column">
                <div className="flex-container-row">
                    <h5 className="subtle">{Localizer.KompetenceVurderingKladde()}</h5>
                    {kompetenceEvaluering.privat &&
                        <Tooltip title={Localizer.evalueringPage_privatSkema()}>
                            <span className="badge badge-pill badge-light padding-xs margin-left-s">{Localizer.global_private()}</span>
                        </Tooltip>
                    }
                </div>
                <p className="subtle">{`${spoergsmaalCount(kompetenceEvaluering)} ${Localizer.evalueringPage_questionsAnswered()}`}</p>
                {(kompetenceEvaluering.createdByUser) &&
                    <div className="d-flex wrap">
                        <h5 className="subtle">{Localizer.kompetencePage_evalueringOprettetAf(kompetenceEvaluering.createdByUser?.firstName + " " + kompetenceEvaluering.createdByUser?.lastName)} &nbsp;</h5>
                        <UserProfileAvatar
                            size={UserProfileAvatarSizeEnum.extraSmall}
                            subtle={true}
                            userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                            userProfileAvatarModel={new UserProfileAvatarModel(kompetenceEvaluering.createdByUser.userId, kompetenceEvaluering.createdByUser?.firstName + " " + kompetenceEvaluering.createdByUser?.lastName, kompetenceEvaluering.createdByUser.initialer)}
                        />
                    </div>
                }
            </div>
            {(currentUser.IsVejleder() || currentUser.IsUddannelsesansvarlig() || currentUser.IsAdminOrVus() || (kompetenceEvaluering.sektioner.some(s => s.spoergsmaal.some(sp => sp.laegerKanBesvare)))) &&
                <div className={`${isMobile ? "margin-top-m" : "flex-align-r"} d-flex flex-align-c`}>
                    {showDeleteBtn &&
                        <ActionIcon addtionalClassNames="margin-right-m"
                                    iconName={EmblaIcons.Delete}
                                    action={() => deleteKompetenceEvaluering(kompetenceEvaluering.id)}/>
                    }
                    <button className={"btn btn-default "}
                            disabled={disableFortsaetBtn}
                            onClick={() => history.push(RoutePaths.EditKompetenceEvaluering({
                                uddannelseslaege: kompetenceBrugerId,
                                kompetence: kompetenceId,
                                kompetenceevaluering: kompetenceEvaluering.laegekompetenceEvalueringId
                            }).url)}>
                        {Localizer.global_fortsaet()}
                    </button>
                </div>
            }
        </div>
    );
}
