import {ApiQueryParams} from "../apiQueryParams";
import {ForloebOverviewFilterModel} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";
import {FilterParamsModel} from "../../../core/sharedmodels/filter/FilterParamsModel";
import { ForloebOverviewDateFilterPostModel } from "core/components/forloebOverview/ForloebOverviewDateFilterModel";

export class forloebOverviewFilterRequestModel extends ApiQueryParams{
    constructor(inputModel: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel) {
        super(filterParamsModel.paginationModel.page, filterParamsModel.paginationModel.pageLength, filterParamsModel.search);
        this.forloebOverviewDateFilterModel = new ForloebOverviewDateFilterPostModel(inputModel);
        this.regioner = inputModel.regioner;
        this.specialer = inputModel.specialer;
        this.uddannelsetyper = inputModel.uddannelsetyper;
        this.hospitaler = inputModel.hospitaler;
        this.praksis = inputModel.praksis;
        this.afdelinger = inputModel.afdelinger;
        this.omraader = inputModel.omraader;
        this.advancedSearch = filterParamsModel.advancedSearch;
        this.alleRegionerValgt = inputModel.alleRegionerValgt;
        this.showUnoccupiedStillinger = filterParamsModel.showUnoccupiedStillinger
        this.showAnonymized = filterParamsModel.showAnonymized
        this.kbuPeriod = inputModel.kbuPeriod;
        this.kbuPeriodYear = inputModel.kbuPeriodYear
    }

    public forloebOverviewDateFilterModel?: ForloebOverviewDateFilterPostModel;
    public regioner?: string[];
    public specialer?: string[];
    public uddannelsetyper?: number[];
    public hospitaler?: string[];
    public praksis?: string[];
    public afdelinger?: string[];
    public omraader?: string[];
    public advancedSearch: boolean;
    public alleRegionerValgt?: boolean;
    public showUnoccupiedStillinger: boolean
    public showAnonymized: boolean
    public kbuPeriod?: string;
    public kbuPeriodYear?: string;
}
