export const BadgeTypes = {
    Default: "badge-default",
    Primary: "badge-primary",
    PrimaryLight: "badge-primary-light",
    Secondary: "badge-secondary",
    Success: "badge-success",
    Warning: "badge-warning",
    Danger: "badge-danger",
    Info: "badge-info",
    InfoLight: "badge-info-light",
} as const;

export const BadgePaddingSizes = {
    Xs: "padding-xs",
    S: "padding-s",
    M: "padding-m",
    L: "padding-l",
    Xl: "padding-xl",
} as const;
