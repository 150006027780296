import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {MappeAndFilesRequestModel} from "../stamdataMappe/MappeRequestModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {FoldersResponseModel} from "../stamdataMappe/FoldersResponseModel";
import { Build, FoldersModel} from "../stamdataMappe/FoldersModel";
import { ForSelectModel } from "core/sharedmodels/forSelectModel";

export class StamdataMappeApi {

    private baseUrl = config.stamdataApiUrl + "api/mappe/";
    private mapperAndFilesByRegionAndMaalbeskrivelseUrl = this.baseUrl + "byBrugermaalbeskrivelseAndRegion";
    private mapperForSelectUrl = this.baseUrl + "forSelect";
    private mapperAndFilesOnUddannelsesstederUrl = this.baseUrl + "uddannelser";
    private mapperAndFilesOnUddannelsesstederByForloebUrl = this.baseUrl + "uddannelser/byforloeb/";
    private mapperWithLinks = this.baseUrl + "links";

    constructor(private httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getMapperAndFilesByRegionAndMaalbeskrivelse(regionId: string, brugermaalbeskrivelseId: string): Promise<FoldersModel> {
        const requestModel: MappeAndFilesRequestModel = {RegionId: regionId, BrugermaalbeskrivelseId: brugermaalbeskrivelseId};
        const url = this.mapperAndFilesByRegionAndMaalbeskrivelseUrl + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<FoldersResponseModel>>(url);
        return Build(result.data);
    }

    async getForSelects(): Promise<ForSelectModel[]> {
        const url = this.mapperForSelectUrl;
        const result = await this.httpClientService.Get<ApiResponse<ForSelectModel[]>>(url);
        return result.data;
    }

    async getMapperAndFilesOnUddannelsessteder(): Promise<FoldersModel> {
        const url = this.mapperAndFilesOnUddannelsesstederUrl;
        const result = await this.httpClientService.Get<ApiResponse<FoldersModel>>(url);
        return Build(result.data) ;
    }

    async getMapperAndUddannelseFilesByForloeb(forloebId: string): Promise<FoldersModel> {
        const url = this.mapperAndFilesOnUddannelsesstederByForloebUrl + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<FoldersResponseModel>>(url);
        return Build(result.data);
    }

    async getMapperAndLinks(): Promise<FoldersModel> {
        const url = this.mapperWithLinks;
        const result = await this.httpClientService.Get<ApiResponse<FoldersResponseModel>>(url);
        return Build(result.data);
    }
}
