import React from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";

export type SubmitModalInfoProps = {
    tekst: string;
    handleCommentChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    commentPlaceholderText?: string;
}

export const SubmitModalInfo = ({tekst, handleCommentChange, commentPlaceholderText}: SubmitModalInfoProps) => {
    return (
        <>
            <p>{tekst}</p>
            {handleCommentChange &&
                <>
                    <label>{Localizer.global_comment()}: </label>
                    <textarea className="form-control"
                              placeholder={commentPlaceholderText}
                              onChange={handleCommentChange}
                    />
                </>
            }
        </>
    )
}
