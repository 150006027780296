import { PropsWithChildren } from "react";
import LinkTargets, { SecureLinkReferrerPolicy } from "./linkAttributs"
import { Localizer } from "infrastructure/localization/localizer";

type ExternalLinkProps = {
    text?: string;
    link: string;
    classes?: string;

    ariaLabel?: string; // Should be set if text is not set
}

const ExternalLink = ({text, link, classes="", ariaLabel, children}: PropsWithChildren<ExternalLinkProps>) => {
    return (
        <a
            className={classes}
            href={link}
            target={LinkTargets.Blank}
            rel={SecureLinkReferrerPolicy}
            aria-label={!!ariaLabel ? ariaLabel : !!text ? undefined : Localizer.aria_label_ExternalLink()}
        >
            {text &&
                text
            }

            { children }
        </a>
    )
}

export default ExternalLink;