import {useRouteMatch} from "react-router-dom";
import {LaegekompetenceMeta} from "../../sharedmodels/kompetence/laegekompetenceMeta";
import {useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {NotatType} from "../../sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../sharedmodels/notat/basicNotatModel";
import {ReactComponent as EyeIcon} from "../../../content/icons/eye-gray.svg";
import {LaegekompetenceStatusEnum} from "../../sharedmodels/kompetence/laegekompetenceStatusEnum";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../userProfileAvatar/userProfileAvatar";
import {sortKompetenceBySortOrder} from "../../utils";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import PdfComponent from "core/components/pdf/pdfComponent";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import NoteTextViewer from "core/componentsPage/notat/tekstNotat/noteTextViewer";
import { setDocumentPdfAsReady } from "./helpers/pdfHelper";

export function KompetencerPdf() {
    const routeMatch = useRouteMatch();
    const routeParams = routeMatch.params as { brugerId: string, brugerMaalbeskrivelseId: string};

    const [laegeKompetencer, setLaegeKompetencer] = useState<LaegekompetenceMeta[]>();
    const [notater, setNotater] = useState<BasicNotatModel[]>();
    const setError = useFetchWithErrorHandlingPdf();

    const { notatApi, logbogLaegeKompetenceApi } = useLogbogApi();

    useEffect(() => {
        const fetch = async () => {
            let laegekompetencerResponse = await logbogLaegeKompetenceApi.getLaegekompetencerByBrugerId(routeParams.brugerId);
            laegekompetencerResponse = sortKompetenceBySortOrder(laegekompetencerResponse.filter(k => k.brugerMaalbeskrivelseId === routeParams.brugerMaalbeskrivelseId));
            setLaegeKompetencer(laegekompetencerResponse);
            const notatResponse = await notatApi.getNotaterByBrugerId(NotatType.Merit, routeParams.brugerId);
            setNotater(notatResponse);
        }

        fetch().catch(err => setError(err));

        setDocumentPdfAsReady();
    }, [notatApi, logbogLaegeKompetenceApi, routeParams.brugerId, routeParams.brugerMaalbeskrivelseId, setError]);

    return (
        <PdfComponent>
            <PdfKompetenceHeaderComponent
                brugerId={routeParams.brugerId}
                brugerMaalbeskrivelseId={routeParams.brugerMaalbeskrivelseId}
            />
            <div className={"card shadow-none"}>
                <div className={"card-body"}>
                    <div className={"card-list-modal"}>
                        <div className={"row margin-bottom-m"}>
                            <h3 className={"col-6"}>{Localizer.global_kompetencer()}</h3>
                            <h3 className={"col-2"}>{Localizer.global_status()}</h3>
                            <h3 className={"col-4"}>{Localizer.global_godkendtAf()}</h3>
                        </div>
                        <div className={"card-list-border"}/>
                        {laegeKompetencer?.map((laegekompetence, index) =>
                            <>
                                <PdfComponent.NonBreakableItem key={index} className="row margin-top-s margin-bottom-s">
                                    <div className={"col-6 flex-align-c"}>
                                        <div className="flex-row">
                                            <div className="subtle">
                                                {laegekompetence.kompetenceNummer}
                                            </div>
                                            <div className="subtle">
                                                {laegekompetence.fullKompetenceTitle}
                                            </div>
                                        </div>

                                    </div>
                                    <div className={"col-2 flex-align-c"}>
                                        {laegekompetence.godkendtDato &&
                                            <>
                                                {laegekompetence.meritNotat !== null &&
                                                    <div className={"flex-row"}>
                                                        <div>{Localizer.godkendtMedMeritDen() +": "}</div>
                                                        <div>{new Date(laegekompetence.godkendtDato).dateWithTimeFormat()}</div>
                                                        <div>{laegekompetence.meritNotat}</div>
                                                    </div>
                                                }
                                                {laegekompetence.meritNotat === null &&
                                                    <div className={"flex-row"}>
                                                        <div>{Localizer.godkendtDen() +": "}</div>
                                                        <div>{new Date(laegekompetence.godkendtDato).dateWithTimeFormat()}</div>
                                                    </div>
                                                }

                                            </>

                                        }
                                        {!laegekompetence.godkendtDato && laegekompetence.status !== LaegekompetenceStatusEnum.Klarmeldt &&
                                            <>{Localizer.global_ikkeGodkendt()}</>
                                        }
                                        {laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt &&
                                            <>{Localizer.global_klarmeldt()}</>
                                        }
                                    </div>
                                    <div className={"col-4 flex-align-c"}>
                                        {laegekompetence.godkendtAfNavn &&
                                            <div>
                                                {`${Localizer.global_name()}: ${laegekompetence.godkendtAfNavn}`}

                                                {laegekompetence.godkendtAfAuthId &&
                                                    <div className={"flex-container"}>
                                                        <div className={"padding-right-xs"}>{`${Localizer.global_authorisationId()}:`}</div>
                                                        <div>{laegekompetence.godkendtAfAuthId}</div>
                                                    </div>
                                                }
                                                <div className={"flex-container"}>
                                                    <div className={"padding-right-xs"}>{`${Localizer.rolle()}:`}</div>
                                                    <div>{laegekompetence.godkendtAfRolleTitel}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </PdfComponent.NonBreakableItem>

                                {index < (laegeKompetencer.length-1) &&
                                    <div className={"card-list-border"}/>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>

            <PdfComponent.PageBreak/>

            {(notater && notater.length > 0) &&
                notater.filter(n => n.type === NotatType.Merit).map(notat =>
                    <div key={notat.id} className={"card shadow-none"}>
                        <div className={"card-body"}>
                            <div className={"margin-l"}>
                                <div>
                                    <h5>{notat.title}</h5>
                                </div>

                                <NoteTextViewer
                                    id={notat.id}
                                    type={notat.type}
                                    text={notat.description}
                                />

                                <div className='w-50 margin-bottom-m margin-top-m'>
                                </div>
                                <div className='subtle d-flex flex-row'>
                                    <div className='d-flex flex-row margin-right-m'>
                                        <EmblaIcon iconName='calendar'/>
                                        <p className='margin-left-xs'>{notat.createdAt.dateAndTimeToApiPostFormat()}</p>
                                    </div>
                                    <>
                                        <UserProfileAvatar size={UserProfileAvatarSizeEnum.extraSmall}
                                                           userProfileAvatarModel={new UserProfileAvatarModel(notat.createdById, notat.createdByName, notat.createdByInitials, false)}
                                                           userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}/>
                                    </>

                                    {notat.isPrivate &&
                                        <div className='d-flex flex-row margin-left-m'>
                                            <span><EyeIcon/></span>
                                            <p className='margin-left-xs'>{Localizer.global_private()}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </PdfComponent>
    );
}
