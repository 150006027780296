import {RoutePaths} from "infrastructure/routes";
import {useHistory} from "react-router-dom";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import React, {useState} from "react";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {LaegeForloebWithKursusGrupperModel} from "../../../core/sharedmodels/user/laegeForloebWithKursusGrupperModel";
import {LaegekursusGruppeCard} from "../../kurserPage/components/laegekursusGruppeCard";
import {CollapseTab} from "../../../core/components/Tabs/collapseTab";
import {GetLaegekursusGruppeTranslation} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {GetFravaerskodeTranslation} from "../../../core/sharedmodels/forloeb/fravaerskoderEnum";
import {ForloebWithKursusGrupperModel} from "../../../services/api/forloeb/forloebWithKursusGrupperModel";
import {LaegekursusGruppeModel} from "../../../services/api/laegekursus/laegekursusGruppeModel";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {LogbogLaegekursusApi} from "../../../services/api/laegekursus/logbogLaegekursusApi";
import {Loading} from "../../../core/components/loading/loading";
import {Localizer} from "../../../infrastructure/localization/localizer";

type KursistProps = {
    forloebWithKursusGrupper: LaegeForloebWithKursusGrupperModel
    forloeb: ForloebWithKursusGrupperModel
}
const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService())

export function Kursist({forloebWithKursusGrupper, forloeb}: KursistProps) {
    const history = useHistory();
    const [laegekursusGrupperWithKurser, setLaegekursusGrupperWithKurser] = useState<LaegekursusGruppeModel[]>([])

    const handleOnClickUddannelseslaege = (uddannelseslaegeId: string, brugerKursusraekkeId: string) => {
        let routeParamaters = [uddannelseslaegeId, brugerKursusraekkeId];
        history.push(RoutePaths.Kurser(routeParamaters).url)
    };

    async function getLaegekursusGrupper(kursusGruppeId: string, isExpanded: boolean) {
        const laegekurserIkkeHentet = !laegekursusGrupperWithKurser.find(x => x.id === kursusGruppeId)
        if (isExpanded && laegekurserIkkeHentet) {
            const laegekursusGruppeResponse = await laegekursusApi.getLaegeKursusGruppe(kursusGruppeId);
            setLaegekursusGrupperWithKurser(exisitingLaegekursusGrupper => [...exisitingLaegekursusGrupper, laegekursusGruppeResponse]);
        }
    }

    async function updateLaegekursusGruppe(kursusGruppeId: string) {
        // Find the kursusGruppe with the provided id
        const laegekursusGruppeResponse = await laegekursusApi.getLaegeKursusGruppe(kursusGruppeId);
        const kursusGruppeIndex = laegekursusGrupperWithKurser.findIndex((kg) => kg.id === kursusGruppeId);
        const updatedStateList = laegekursusGrupperWithKurser.slice();
        updatedStateList[kursusGruppeIndex] = laegekursusGruppeResponse;
        setLaegekursusGrupperWithKurser(updatedStateList);
    }

    return (
        <div role={"button"} className="flex-container row">
            {forloebWithKursusGrupper.laegeId &&
                <>
                    <div className="flex-container-row col-sm-4"
                         onClick={() => handleOnClickUddannelseslaege(forloebWithKursusGrupper.laegeId, forloeb.brugerKursusraekkeId)}>
                        <div className="flex-align-top margin-right-xl">
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.small}
                                               displayEmail={forloebWithKursusGrupper.laegeEmail}
                                               userProfileAvatarModel={new UserProfileAvatarModel(forloebWithKursusGrupper.laegeId, forloebWithKursusGrupper.laegeNavn, forloebWithKursusGrupper.laegeInitialer, true, forloebWithKursusGrupper.laegeEmail, forloebWithKursusGrupper.laegeProfileImageId)}/>
                            <div className="margin-left-m">
                                <h5>{forloeb.erFravaer ? `${forloebWithKursusGrupper.laegeNavn} - ${GetFravaerskodeTranslation(forloeb.fravaerskodeEnum)}` : forloebWithKursusGrupper.laegeNavn}</h5>
                                <h6>{forloeb.stillingName + ": " + forloeb.specialeName}</h6>
                                <h6>{forloeb.afdelingName === null ? forloeb.laegeInstitutionName : forloeb.laegeInstitutionName + ", " + forloeb.afdelingName}</h6>
                                <h6 className="subtle">{forloeb.startDate?.dateWithoutTimeFormat(false) + " - " + forloeb.endDate?.dateWithoutTimeFormat(false)}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container-column flex-align-r flex-fill col-sm-8">
                        {forloeb.laegekursusGrupper?.length > 0 &&
                            forloeb.laegekursusGrupper?.map((kursusGruppe, index) =>
                                <CollapseTab id={kursusGruppe.id + forloeb.forloebId}
                                             title={GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType)}
                                             expandedDefault={false}
                                             toggleCallback={(id:string, isExpanded: boolean) => getLaegekursusGrupper(kursusGruppe.id, isExpanded)}
                                             key={kursusGruppe.id + forloeb.forloebId}>
                                        <Loading isLoading={!laegekursusGrupperWithKurser.find(x => x.id === kursusGruppe.id)} spinnerClasses={"margin-m"} timeout={0} text={Localizer.kursusPage_getKurser()}>
                                        {laegekursusGrupperWithKurser.find(x => x.id === kursusGruppe.id) !== undefined &&
                                            <LaegekursusGruppeCard
                                                forloebId={forloeb.forloebId}
                                                brugerMaalbeskrivelseId={forloeb.brugerMaalbeskrivelseId}
                                                additionalCardClasses={"shadow-none"}
                                                kursusGruppe={laegekursusGrupperWithKurser.filter(x => x.id === kursusGruppe.id)[0]}
                                                kurserUpdatedCallback={() => updateLaegekursusGruppe(kursusGruppe.id)}
                                            />
                                        }
                                        </Loading>

                                </CollapseTab>
                            )
                        }
                    </div>

                </>
            }
        </div>
    )
}
