import './user-profile.scss';
import {IsLaegeToColorClass} from "../../../infrastructure/enums/userRole";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {UserProfileAvatarModel} from "./userProfileAvatarModel";
import {RolleTitelEnum, UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import {AvatarBadge} from './avatarBadge';
import {ConfirmButton} from '../button/confirmButton';
import {EmblaIcons} from 'core/emblaIcons';
import CounterCircle from '../notifications/counterCircle/counterCircle';
import React, {useState} from 'react';
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import classNames from "classnames";
import {createPortal} from "react-dom";
import {ReactTooltip} from "../tooltips/ReactTooltip";
import {UUID} from "../../../index";
import {Localizer} from "../../../infrastructure/localization/localizer";

export enum UserProfileAvatarSizeEnum {
    large,
    medium,
    small,
    extraSmall,
    extraExstraSmall
}

export enum UserProfileLayoutEnum {
    AsHeaderBelowAvatar,
    AsTextOnRightSide
}

export type UserProfileActions = {
    removeUserAction?: (userId: string) => void;
}

export type IUserProfileAvatarProps = UserProfileActions & {
    userId?: string;
    userProfileAvatarModel?: UserProfileAvatarModel;
    userProfileLayoutEnum?: UserProfileLayoutEnum;
    size?: UserProfileAvatarSizeEnum;
    subtle?: boolean;
    forloebAccessDate?: string;
    displayEmail?: string;
    vejledertilknytningDates?: string[];
    isImpersonating?: boolean;
    userRoleTitles?: RolleTitelEnum[];
    showRedCircle?: boolean;
    showFavoriteIcon?: boolean;
    isFavorite?: boolean;
    isCurrentUser?: boolean;
    avoidHoverTooltip?: boolean;
    favoriserCallback?: (userId: string, isFavorite: boolean) => void;
    fileUploadedCallback?: (file: ReactFileUploaderFile) => void;
    deleteImageCallback?: () => void;
}

const userProfileAvatarWrapperClassName = "user-profile-avatar-wrapper";
export const UserProfileAvatar = ({
                                      showRedCircle = false,
                                      showFavoriteIcon = false,
                                      favoriserCallback,
                                      fileUploadedCallback,
                                      deleteImageCallback,
                                      isCurrentUser = false,
                                      avoidHoverTooltip = false,
                                      size,
                                      displayEmail,
                                      ...props
                                  }: IUserProfileAvatarProps) => {
    const [isFavoriteState, setIsFavoriteState] = useState(props.isFavorite);

    const userAvatarId = "image-id" + props.userProfileAvatarModel?.UserId + UUID();
    const getUserAvatar = () => {
        return (
            <>
                <div id={userAvatarId}>
                    <AvatarBadge
                        additionalClasses={"circle-container"}
                        profileImageFileMetadataId={props.userProfileAvatarModel?.ProfileImageId}
                        profileImageFileData={props.userProfileAvatarModel?.ProfileImageFileData}
                        initials={props.userProfileAvatarModel?.Initials}
                        size={size}
                        isCurrentUser={isCurrentUser}
                        colorClassName={classNames(props.subtle && "user-avatar-subtle", IsLaegeToColorClass(props.userProfileAvatarModel?.IsLaege, !!props.userProfileAvatarModel?.Anonymiseret))}
                        isImpersonating={props.isImpersonating}
                        fileUploadedCallback={fileUploadedCallback}
                        deleteImageCallback={deleteImageCallback}
                        userAnonymized={!!props.userProfileAvatarModel?.Anonymiseret}
                    />
                    {showRedCircle && <CounterCircle positionAbsolute size="extra-small" hideIfZero={false}/>}
                </div>
                {!avoidHoverTooltip &&
                    createPortal(
                        <ReactTooltip
                            selectId={userAvatarId}
                            hidden={avoidHoverTooltip}
                        >
                            <div className={classNames("image-tooltip-center")}
                                 onClick={(event) => event.stopPropagation()}>
                                <AvatarBadge
                                    additionalImageClasses={"image-hover"}
                                    additionalClasses={classNames(!props.userProfileAvatarModel?.ProfileImageId && "userrole-avatar")}
                                    initials={props.userProfileAvatarModel?.Initials}
                                    profileImageFileMetadataId={props.userProfileAvatarModel?.ProfileImageId}
                                    profileImageFileData={props.userProfileAvatarModel?.ProfileImageFileData}
                                    colorClassName={classNames(props.subtle && "user-avatar-subtle", IsLaegeToColorClass(props.userProfileAvatarModel?.IsLaege, !!props.userProfileAvatarModel?.Anonymiseret))}
                                    size={UserProfileAvatarSizeEnum.large}
                                    isCurrentUser={isCurrentUser}
                                    userAnonymized={!!props.userProfileAvatarModel?.Anonymiseret}
                                />
                                {!!props.userProfileAvatarModel?.Anonymiseret
                                    ?
                                    <div className="d-flex align-items-center">
                                        <h6 className={"margin-0"}>{props.userProfileAvatarModel?.Name}</h6>
                                    </div>
                                    :
                                    <>
                                        <div className="d-flex align-items-center">
                                            <EmblaIcon iconName={EmblaIcons.User}
                                                       additionalClasses={"margin-right-xs"}/>
                                            <h6 className={"margin-0"}>{props.userProfileAvatarModel?.Name}</h6>
                                        </div>

                                        {props.userProfileAvatarModel?.Email &&
                                            <div className="d-flex align-items-center">
                                                <EmblaIcon iconName={EmblaIcons.Email}
                                                           additionalClasses={"margin-right-xs"}/>
                                                <a href={"mailto:" + props.userProfileAvatarModel.Email}>
                                                    {props.userProfileAvatarModel.Email}
                                                </a>
                                            </div>
                                        }
                                        {props.userProfileAvatarModel?.PhoneNumber &&
                                            <div className="d-flex align-items-center">
                                                <EmblaIcon iconName={EmblaIcons.Phone}
                                                           additionalClasses={"margin-right-xs"}/>
                                                <h6>{props.userProfileAvatarModel.PhoneNumber}</h6>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </ReactTooltip>,
                document.body
        )
    }
    </>
    )
        ;
    }

    const handleFavorite = () => {
        if (!favoriserCallback || !props.userId) return;

        setIsFavoriteState(!isFavoriteState)
        favoriserCallback(props.userId, !isFavoriteState);
    }

    return (
        <>
            {props.userProfileLayoutEnum === undefined &&
                <div className={"flex-align-c"}>
                    <div className={userProfileAvatarWrapperClassName}>
                        {getUserAvatar()}
                    </div>
                </div>
            }

            {props.userProfileLayoutEnum === UserProfileLayoutEnum.AsHeaderBelowAvatar &&
                <>
                    <div
                        className={`${userProfileAvatarWrapperClassName} margin-top-m margin-bottom-s flex-row-center`}>
                        {getUserAvatar()}
                    </div>

                    <h4 className="flex-container justify-content-center margin-bottom-m text-break padding-left-s padding-right-s">
                        {props.userProfileAvatarModel?.Name}
                    </h4>
                </>
            }

            {props.userProfileLayoutEnum === UserProfileLayoutEnum.AsTextOnRightSide &&
                <div className={"flex-align-c"}>
                    <div className={userProfileAvatarWrapperClassName}>
                        {getUserAvatar()}
                    </div>

                    <div className="user-profile-name">
                        <div className={classNames((props.subtle || !!props.userProfileAvatarModel?.Anonymiseret) && "subtle", props.userRoleTitles && "flex-container-row")}>
                            {props.userProfileAvatarModel?.Name}
                            {props.userRoleTitles &&
                                <div className={"margin-left-xs"}>
                                    ({props.userRoleTitles.map(r => UserRoleTitleToString(r)).join(", ")})
                                </div>}
                        </div>
                        {props.forloebAccessDate &&
                            <>
                                <div>
                                    <EmblaIcon additionalClasses={"subtle margin-right-xs"} iconName="calendar"/>
                                    {props.forloebAccessDate}
                                </div>
                            </>
                        }
                    </div>

                    <div className={"d-flex align-items-center ml-auto"}>
                        {!isFavoriteState && showFavoriteIcon &&
                            <div className="mr-2" onClick={() => handleFavorite()} role="button" aria-label={Localizer.aria_label_SetAsFavorite()}>
                                <EmblaIcon iconName={EmblaIcons.Star} />
                            </div>
                        }

                        {isFavoriteState && showFavoriteIcon &&
                            <div className="mr-2" onClick={() => handleFavorite()} role="button" aria-label={Localizer.aria_label_DeselectAsFavorite()}>
                                <EmblaIcon iconName={EmblaIcons.StarFilled} />
                            </div>
                        }

                        {props.removeUserAction &&
                            <ConfirmButton
                                key={EmblaIcons.Delete + props.userId}
                                icon={EmblaIcons.Delete}
                                asButton={false}
                                confirmCallback={() => {
                                    if (props.userId) {
                                        props.removeUserAction!(props.userId)
                                    }
                                }}
                                additionalClasses={`btn btn-icon ${props.userProfileAvatarModel?.Email ? "padding-right-xl" : ""}`}
                            />
                        }

                        {displayEmail &&
                            <a href={`mailto:${props.userProfileAvatarModel?.Email}`} aria-label={Localizer.sendmail()}>
                                <EmblaIcon iconName={EmblaIcons.Email}/>
                            </a>
                        }
                    </div>
                </div>
            }
        </>
    );
}
