import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {LinkInputModel} from "../../../pages/help/LinkInputModel";
import {LinkModel} from "../stamdataMappe/LinkModel";
import {LinkPlacementEnum} from "../../../core/components/link/LinkPlacementEnum";
import {HelpLinkRequestModel} from "./helpLinkRequestModel";
import {ObjectToQueryString} from "../objectToQueryString";

export class StamdataLinkApi {

    private baseUrl = config.stamdataApiUrl + "api/link/";
    private getLinksByPlacement = this.baseUrl + "byPlacement/";

    constructor(private httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async createLinkInFolder(inputModel: LinkInputModel): Promise<LinkModel> {
        const url = this.baseUrl;
        const result = await this.httpClientService.Post<ApiResponse<LinkModel>, LinkInputModel>(url, inputModel);
        return result.data;
    }

    async deleteLink(linkId: string) {
        const url = this.baseUrl + linkId;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async getHelpLinks(linkPlacement: LinkPlacementEnum): Promise<LinkModel[]> {
        const requestModel: HelpLinkRequestModel = { linkPlacement: linkPlacement };
        const url = this.getLinksByPlacement + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<LinkModel[]>>(url);
        return result.data;
    }

    async editLinkInFolder(linkId: string, model: LinkInputModel): Promise<LinkModel> {
        const url = this.baseUrl + linkId;
        const result = await this.httpClientService.Post<ApiResponse<LinkModel>, LinkInputModel>(url, model);
        return result.data;
    }
}
