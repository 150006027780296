import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {ForloebSimpleInfoModel} from "../../sharedmodels/forloeb/forloebSimpleInfoModel";
import {LaegeStillingModel} from "../../sharedmodels/stilling/laegeStillingModel";

const calculateEndDate = (date: Date) => {
    const sixMonthsFromDate = date.addMonths(6);
    if(!date.isLastDayInMonth()) return sixMonthsFromDate;

    return sixMonthsFromDate.getEndOfMonth();
}

const GetColorClasses = (irrelevant: boolean, erFravaer:boolean, forloebIsActive: boolean, harTidligereMidlertidigtFravaer:boolean, anonymiseret?: boolean) => {
    let classString = ""
    if (anonymiseret) {
        classString = "color-anonymiseret-forloeb";
    } else if (irrelevant) {
        classString = "color-irrelevant-forloeb";
    } else if (erFravaer) {
        classString = "color-fravaer-forloeb";
    } else if (forloebIsActive) {
        classString = "active-forloeb";
    }
    const shadeColorClass = harTidligereMidlertidigtFravaer ? "color-shaded-forloeb" : "";
    return `${classString} ${shadeColorClass}`;
}

const getForloebColor = (forloeb: ForloebModel | ForloebSimpleInfoModel, forloebIsActive: boolean, anonymiseret?: boolean) => {

    return GetColorClasses(forloeb.irrelevant, forloeb.erFravaer, forloebIsActive, forloeb.harTidligereMidlertidigtFravaer, anonymiseret);
}

const getLaegestillingForloebColor = (laegestilling: LaegeStillingModel, anonymiseret?: boolean) => {
    return GetColorClasses(laegestilling.irrelevant, false, false, false, anonymiseret);
}

export {
    calculateEndDate,
    getForloebColor,
    getLaegestillingForloebColor
}
