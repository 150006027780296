import React, {ReactNode, useState} from 'react';
import {Spinner} from "../spinner/spinner";

interface GenericDownloadButtonProps {
    clickFunction: () => Promise<void>;
    child?: ReactNode;
    buttonText?: string;
    waitText?: string;
    titel?: string;
    additionalClasses?: string;
    stopPropagation?: boolean;
}

export function GenericDownloadButton(props: GenericDownloadButtonProps) {
    const [loading, setLoading] = useState(false);

    const buttonClicked = () => {
        setLoading(true);
        props.clickFunction().then(x => {
            setLoading(false);
        })
    }

    return (
        <div className={`clickable-row flex-row flex-align-c justify-content-center ${props.additionalClasses ?? ""}`} onClick={(event) => {
            buttonClicked();
            if (props.stopPropagation)
                event.stopPropagation();
        }} title={props.titel}>
            {loading &&
                <div className={"flex-container"}>
                    <Spinner spinnerSize="extra-tiny-small"/>
                    {props.waitText &&
                        <div>{props.waitText}</div>
                    }
                </div>
            }

            {!loading &&
                <>
                    {props.child &&
                        props.child
                    }

                    {props.buttonText &&
                        props.buttonText
                    }
                </>
            }

        </div>
    );
}
