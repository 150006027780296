import './createEvalueringsstatistik.scss';
import {Localizer} from "../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {filterToState} from "./evalueringsstatistikFilterModel";
import {Loading} from "../../core/components/loading/loading";
import {
    evalueringsstatistikReducer,
    EvalueringsstatistikState,
    setDataset,
    setLastChangedAt,
    setShouldFetchDefaultVisning,
    setShowAllDatasetAverageAndFilters,
} from "./evalueringsstatistikSlice";
import {
    applyFilters,
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "./evalueringsstatistikFiltersSlice";
import {
    GetSpoergsmaalstypeTranslation,
    SpoergsmaalTypeEnum
} from "../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {AddDatasetFilteringActions, AddFilteringDataset} from "./components/addDatasetFiltering";
import {UddannelsesEvalueringCardComponent} from "./components/uddannelsesstederEvalueringCard";
import {EvalueringsstatistikPageConstants} from "./components/EvalueringsstatistikPageConstants";
import {
    AddDatasetUddannelsessteder,
    AddDatasetUddannelsesstederActions
} from "./components/addDatasetUddannelsessteder";
import {useEffectOnUpdate} from "../../infrastructure/effect";
import {EvalueringStatistikModel} from "../../services/api/evalueringStatistik/evalueringStatistikReponseModel";
import {DatasetModel} from "./datasetModel";
import {toState} from "./evalueringsstatistikModel";
import {addTotalAverage, getColor} from "./evalueringsstatistikUtils";
import {toDate} from "index";
import {toReduxIsoDateString} from "../../core/helpers/reduxHelper";
import {Dropdown, DropdownOption} from "../../core/components/dropdown/dropdown";
import useEnumDropdownOptions from "../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {SingleValue} from "react-select";
import NumberRangeDropdownOptions from "../../core/components/dropdown/dropdownOptions/numberRangeDropdownOptions";
import PrintButton from 'core/components/button/printButton/printButton';
import useLogbogApi from 'core/hooks/useLogbogApi';
import useScreenResolution from 'core/hooks/useScreenResolution';
import { EmblaIcons } from 'core/emblaIcons';
import questionNumberTranslator from 'core/sharedmodels/evaluering/questionNumberTranslator';
import formatNumericDropdownOptionsToCustomLabel from 'core/components/dropdown/formatterFunctions/formatNumericDropdownOptionsToCustomLabel';
import useUserFilterPermissions from 'core/hooks/useUserFilterPermissions';
import { UddannelsesstedModel } from 'core/sharedmodels/laegeInstitution/uddannelsesstedModel';
import useUser from 'core/hooks/useUser';
import { RootTypeEnum } from 'core/sharedmodels/laegeInstitution/rootTypeEnum';
import {LinkPlacementEnum} from "../../core/components/link/LinkPlacementEnum";
import {HelpLinks} from "../../core/components/link/helpLinks";

export const CreateEvalueringsstatistik = () => {

    const [addDatasetModalOpen, setAddDatasetModalOpen] = useState(false);
    const [page, setPage] = useState<number>(1);
    const addDatasetModalTarget = "dataset-add-modal";
    const evalueringsstatistikSliceState = useAppSelector(evalueringsstatistikReducer) as EvalueringsstatistikState;
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const dispatch = useAppDispatch();
    const { currentUser } = useUser();

    const [spoergsmaalTypeDropdownOptions] = useEnumDropdownOptions<SpoergsmaalTypeEnum>(SpoergsmaalTypeEnum, GetSpoergsmaalstypeTranslation);
    const [spoergsmaalNumbersToShowDropdownOptions, setSpoergsmaalNumbersToShowDropdownOptions] = useState<DropdownOption<number>[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [datasets, setDatasets] = useState<DatasetModel[]>([]);

    const showDropdowns =  evalueringsstatistikSliceState.dataset.filter(d => d.isDefaultDatasetForUser).length > 0 || evalueringsstatistikSliceState.dataset.filter(x => !x.isDefaultDatasetForUser).length > 0;

    const shouldGetDefaultVisning = currentUser.Authenticated && evalueringsstatistikSliceState.shouldFetchDefaultVisning && evalueringsstatistikSliceState.showDefaultDatasaet && EvalueringsstatistikPageConstants.getDefaultvisning;

    const { isMobile } = useScreenResolution();
    const { evalueringstatistikApi } = useLogbogApi();

    const { permissions, isLoadingPermissions } = useUserFilterPermissions();

    const { slutDato, startDato } = evalueringsstatistikFilterSliceState;

    useEffect(() => {
        const getSpoergsmaalNumbersToShowDropdownOptions = () => {
            //get spørgsmaal dropdown options
            let antalSpoergsmaal = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis
                ? EvalueringsstatistikPageConstants.numberOfQuestionsIncludingOptionalTextInput
                : EvalueringsstatistikPageConstants.numberOfGroups;

            const options = NumberRangeDropdownOptions(antalSpoergsmaal);
            const formattetOptions = formatNumericDropdownOptionsToCustomLabel(options, questionNumberTranslator);
            setSpoergsmaalNumbersToShowDropdownOptions(formattetOptions);
        }
        getSpoergsmaalNumbersToShowDropdownOptions();
    }, [evalueringsstatistikFilterSliceState.spoergsmaalsType, spoergsmaalTypeDropdownOptions])

    useEffect(() => {
        const fetchDefaultDataset = async () => {

            if (shouldGetDefaultVisning && !isLoadingPermissions) {
                setIsLoading(true);

                const regioner = (await evalueringstatistikApi.getRegionerSelect()).filter(x => permissions.regionIds.includes(x.id)).map(x => x.text);
                const sygehuse = (await evalueringstatistikApi.getSygehuseSelect(permissions.regionIds)).filter(x => permissions.sygehusIds.includes(x.id)).map(x => x.text);
                const specialer = (await evalueringstatistikApi.getSpecialerSelect()).filter(x => permissions.specialeIds.includes(x.id)).map(x => x.text);

                const afdelinger = (await evalueringstatistikApi.getAfdelingerSelect(permissions.regionIds, permissions.sygehusIds)).filter(x => permissions.afdelingIds.includes(x.id))
                const praksiser = (await evalueringstatistikApi.getPraksisSelect(permissions.regionIds)).filter(x => permissions.praksisIds.includes(x.id));
                const uddannelsessteder = [...UddannelsesstedModel.FromSelectModel(afdelinger), ...UddannelsesstedModel.FromSelectModel(praksiser)];

                const rootTypes: RootTypeEnum[] = [];
                if (praksiser.length > 0) rootTypes.push(RootTypeEnum.Praksis);
                if (sygehuse.length > 0 || afdelinger.length > 0) rootTypes.push(RootTypeEnum.Sygehus);

                let datasetstemp: DatasetModel[] = []
                for (let i = 0; i <= EvalueringsstatistikPageConstants.antalDefaultVisninger; i++) {
                    const startDate = new Date((new Date().getUTCFullYear() - i), 0, 1);
                    const endDate = i === 0 ? new Date().getUtcWithoutTime().addMonths(1) : new Date((new Date().getUTCFullYear() - i) + 1, 0, 0);
                    const defaultEvalueringStatistik = await evalueringstatistikApi.getDefaultEvalueringStatistik(startDate, endDate);
                    const firstEvalueringStatistik = defaultEvalueringStatistik.first<EvalueringStatistikModel>();
                    let dataset = new DatasetModel(
                        defaultEvalueringStatistik,
                        uddannelsessteder,
                        getColor([...datasetstemp].length, datasetstemp),
                        firstEvalueringStatistik?.maxAntalEvalueringer,
                        startDate.dateWithoutTimeFormat(true) + " - " + endDate.dateWithoutTimeFormat(true),
                        startDate.toIsoDateTimeString(),
                        endDate.toIsoDateTimeString(),
                        specialer,
                        undefined,
                        undefined,
                        [],
                        addTotalAverage(defaultEvalueringStatistik, SpoergsmaalTypeEnum.Enkeltvis),
                        addTotalAverage(defaultEvalueringStatistik, SpoergsmaalTypeEnum.Grupperet),
                        false,
                        true,
                        regioner,
                        sygehuse,
                        rootTypes.length === 1 ? rootTypes[0] : undefined,
                        true,
                        firstEvalueringStatistik?.antalForloebEvalueretTotal
                    );

                    datasetstemp.push(dataset);
                    setDatasets([...datasetstemp]);
                }
                dispatch(setShouldFetchDefaultVisning(false));
                dispatch(setLastChangedAt(toReduxIsoDateString(new Date())));
                setIsLoading(false)
            }
        }

        fetchDefaultDataset();
    }, [dispatch, evalueringstatistikApi, isLoadingPermissions, permissions, shouldGetDefaultVisning])

    useEffectOnUpdate(() => {
        dispatch(setDataset(toState({dataset: datasets})));
    },[datasets])

    const addCreateDatasetModal = () => {
        setAddDatasetModalOpen(true);
    }


    const bodyContent = () => {
        if(page === 1){
            return (<AddDatasetUddannelsessteder modalId={addDatasetModalTarget} />);
        }
        else {
            return (
                <Loading isLoading={isLoading}
                         text={Localizer.evalueringsstatistikPage_addDataset()}
                         timeout={100}
                         extraLoadingInfo={
                            slutDato && startDato && (toDate(slutDato).getUTCFullYear() - toDate(startDato).getUTCFullYear()) > EvalueringsstatistikPageConstants.periodLengthInYears
                                ? Localizer.evalueringPage_henterStortDataset()
                                : ""}>
                    <AddFilteringDataset
                        modalId={addDatasetModalTarget}
                        setIsLoading={setIsLoading}
                        fortryd={() => closeAddDatasetModal()}/>
                </Loading>
            )
        }
    }

    const handleNextClick= () => {
        if(page === 1){
            setPage(2);
        }
    }

    const handleBackClick= () => {
        if(page === 2){
            setPage(1);
        }
    }

    const closeAddDatasetModal = () => {
        setAddDatasetModalOpen(false);
        setPage(1);
    }

    const spoergsmaalTypeSelected = (newValue: SingleValue<DropdownOption<SpoergsmaalTypeEnum>>) => {
        if (newValue) {
            dispatch(applyFilters(filterToState({spoergsmaalToShow: []})));
            dispatch(applyFilters(filterToState({spoergsmaalsType: newValue.value})));
        }
    }

    // On Change Handlers:
    const handleSpoergsmaalNumbersSelected = (selectedOptionKeys?: string[]) => {
        dispatch(applyFilters(filterToState({spoergsmaalToShow: selectedOptionKeys})))
    }

    return (
        <div id="create-evalueringsstatistik-container">
            <div className="card">
                <div className="card-body wrap d-flex align-items-center">
                    <h3>{currentUser.Authenticated ? Localizer.global_evalueringsstatistik() : Localizer.createEvalueringsstatistikPage_gennemsnit()}</h3>
                    <HelpLinks linkPlacement={LinkPlacementEnum.EvalueringsstatistikPage} additionalClasses={"margin-left-s"}/>
                    <div className="actions flex-align-r">
                        <PrintButton
                            onBeforePrint={() => dispatch(setShowAllDatasetAverageAndFilters(true))}
                            onAfterPrint={() => dispatch(setShowAllDatasetAverageAndFilters(false))}
                            show={evalueringsstatistikSliceState.dataset.length > 0}
                            additionalClassNames="margin-right-s"
                        />
                        <button id="create-dataset-btn" className="btn btn-primary"
                                onClick={() => addCreateDatasetModal()}
                                data-toggle="modal" data-target={`#${addDatasetModalTarget}`}>
                            <EmblaIcon iconName={EmblaIcons.Plus}/>
                            {Localizer.createEvalueringsstatistikPage_addDataset()}
                        </button>
                    </div>
                </div>

                { showDropdowns &&
                    <div id="graph-filter-dropdowns-container" className={`flex-container wrap margin-left-m ${isMobile ? "flex-column" : "align-items-center"}`}>
                        {!isMobile ? <h5 className="subtle margin-right-s">{Localizer.evalueringPage_visSpoergsmaal()}</h5> : "" }
                        <div className={`${isMobile ? "margin-right-m" : "margin-right-s"}`}>
                            <Dropdown
                                placeholder={Localizer.evalueringPage_visSpoergsmaal()}
                                options={spoergsmaalTypeDropdownOptions}
                                isClearable={false}
                                value={spoergsmaalTypeDropdownOptions.filter(x => x.value === evalueringsstatistikFilterSliceState.spoergsmaalsType)}
                                onChange={spoergsmaalTypeSelected}/>
                        </div>
                        {!isMobile ? <h5 className="subtle margin-right-s">{Localizer.evalueringPage_ogVis()}</h5> : "" }
                        <div className={`margin-right-m ${isMobile ? "margin-top-m" : ""}`}>
                            <Dropdown
                                id={"sporgsmaal-to-show"}
                                placeholder={Localizer.evalueringPage_spoergsmaal()}
                                closeMenuOnSelect={false}
                                isClearable
                                value={spoergsmaalNumbersToShowDropdownOptions.filter(x => evalueringsstatistikFilterSliceState.spoergsmaalToShow?.includes(x.value.toString()))}
                                options={spoergsmaalNumbersToShowDropdownOptions}
                                onChange={(newVal) => handleSpoergsmaalNumbersSelected(newVal!.map((option) => option.value.toString()))}
                                isMulti
                            />
                        </div>
                    </div>
                }
                <UddannelsesEvalueringCardComponent/>
                <div>
                    {addDatasetModalOpen &&
                        <SlideIn
                            id={addDatasetModalTarget}
                            title={Localizer.createEvalueringsstatistikPage_addDataset()}
                            actionFinishedCallback={() => closeAddDatasetModal()}
                            actionOnCloseCallback={() => closeAddDatasetModal()}
                            bodyContent={bodyContent()}
                            defaultOpen={true}
                            modalSize={ModalSize.md}
                            footerContent={page === 1
                                ? <AddDatasetUddannelsesstederActions modalId={addDatasetModalTarget} next={handleNextClick} />
                                : <AddDatasetFilteringActions modalId={addDatasetModalTarget} back={handleBackClick} />
                            }
                            additionalFooterClasses={"justify-content-between"}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
