

import useLogbogApi from "core/hooks/useLogbogApi";
import { useCallback, useEffect, useState } from "react";
import { UddannelsesplanModel } from "../uddannelsesplan/uddannelsesplanModel";

const useUddannelsesplaner = (forloebId: string, shouldFetch: boolean) => {
  const { uddannelsesplanApi  } = useLogbogApi();
  const [uddannelsesplaner, setUddannelsesplaner] = useState<UddannelsesplanModel[]>([])
  const [isLoading, setIsLoading] = useState(true);

  const loadUddannelsesplanList = useCallback(async () => {
    if (!forloebId) {
      setUddannelsesplaner([]);
      return;
    }

    setIsLoading(true);

    const uddannelsesplanerFromApi = await uddannelsesplanApi.getUddannelsesplanerByForloeb(forloebId);
    setUddannelsesplaner(uddannelsesplanerFromApi);

    setIsLoading(false);
  }, [forloebId, uddannelsesplanApi])

  useEffect(() => {
    if (shouldFetch) {
      loadUddannelsesplanList();
    }
  }, [loadUddannelsesplanList, shouldFetch])

  return {
    uddannelsesplaner,
    refetch: loadUddannelsesplanList,
    isLoading
  }
}

export default useUddannelsesplaner;
