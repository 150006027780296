import React from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import { ContextMenuDefaultProps } from "./contextMenuDefaultProps";
import LeftMenuLink from "core/layout/leftMenu/leftMenuLink";
import { EmblaIcons, EmblaIconsType } from "core/emblaIcons";
import {LeftMenuExternalLink} from "../../layout/leftMenu/leftMenuExternalLink";

type ItemProps = {
    emblaIconName: EmblaIconsType;
    text: string;
    linkRoutePath: string;
    shouldRender?: boolean;
    externalLink?: boolean;
    showLine?: boolean;
    enableShowNotification?: boolean;
    showNotification?: boolean;
    showNotificationAmount?: number;
    clickableOnMobile?: boolean;
}

const Item = ({emblaIconName, text, linkRoutePath, shouldRender = true, showLine = true, showNotification, showNotificationAmount, clickableOnMobile, externalLink = false}: ItemProps) => {
    return(
        <>
            {shouldRender &&
                <div>
                    {externalLink
                        ?
                        <LeftMenuExternalLink
                            emblaIconName={emblaIconName}
                            linkText={text}
                            routePath={linkRoutePath}
                        />
                        :
                        <LeftMenuLink
                            emblaIconName={emblaIconName}
                            linkText={text}
                            routePath={linkRoutePath}
                            routePathsToHighlight={[linkRoutePath]}
                            showNotification={showNotification}
                            showNotificationAmount={showNotificationAmount}
                            disabled={!clickableOnMobile}
                        />
                    }
                    {showLine &&
                        <hr className={"line"}/>
                    }
                </div>
            }
        </>
    );
}

type ContextMenuProps = React.PropsWithChildren<ContextMenuDefaultProps>;

const ContextMenu = (props: ContextMenuProps) => {
    const borderClasses = "btn-default rounded-circle btn-icon";

    return (
        <>
            <div
                className={`btn menu-link bottom-menu-contex-menu ${props.border ? borderClasses : ""} ${props.additionalClasses ?? ""}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-label="Default dropdown menu"
                role="button"
            >
                <EmblaIcon iconName={EmblaIcons.Menu}/>
            </div>
            <div className={`dropdown-menu ${props.additionalChildrenWrapperClasses ?? ""}`} aria-labelledby="dropdown-menu-primary">
                {props.children}
            </div>
        </>
    );
}

export default Object.assign(
    ContextMenu,
    {
        Item,
    },
);
