import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {EmblaIconsType} from "../../emblaIcons";
import classNames from "classnames";
import {BadgePaddingSizes, BadgeTypes} from "./badgeConstants";

type BadgeTypesType = typeof BadgeTypes[keyof typeof BadgeTypes];
type BadgePaddingSizesType = typeof BadgePaddingSizes[keyof typeof BadgePaddingSizes];

type InfoBadgePillInputProps = {
    badgeType: BadgeTypesType;
    badgeText: string;
    emblaIconName?: EmblaIconsType;
    additionalClasses?: string;
    paddingSize?: BadgePaddingSizesType;
}

const InfoBadgePill = ({badgeType, badgeText, emblaIconName, additionalClasses, paddingSize = BadgePaddingSizes.Xs}: InfoBadgePillInputProps) => {

    return (
        <span
            className={classNames("badge badge-pill padding-right-s padding-left-s margin-right-s", badgeType, additionalClasses, paddingSize)}>
              <div>
                  {emblaIconName &&
                      <EmblaIcon iconName={emblaIconName}/>
                  }
                  {badgeText}
              </div>
          </span>
    )
};

export default InfoBadgePill;
