import React, {useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {Loading} from "../../core/components/loading/loading";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "infrastructure/localization/localizer";
import useEffectAsync from "../../infrastructure/effect";
import {LogbogForloebEvalueringApi} from "../../services/api/logbogEvaluering/logbogForloebEvalueringApi";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {Evaluering} from "./evaluering";
import {useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {InitForloebState} from "../forloeb/forloebApi";
import {useDispatch} from "react-redux";
import {RoutePaths} from "../../infrastructure/routes";
import {ForloebModel} from "../../core/sharedmodels/forloeb/forloebModel";
import {ForloebEvalueringModel} from "../../core/sharedmodels/evaluering/forloebEvalueringModel";
import {UserSimpleModel} from "../../core/sharedmodels/user/userSimpleModel";

const evalueringApi = new LogbogForloebEvalueringApi(new HttpClientService());

export function EvalueringPage() {
    const dispatch = useDispatch();
    const routeMatch = useRouteMatch();

    const [forloebEvalueringModel, setForloebEvalueringModel] = useState<ForloebEvalueringModel>(new ForloebEvalueringModel());
    const [uddannelsesansvarlige, setUddannelsesansvarlige] = useState<UserSimpleModel[]>();
    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;
    const forloebModel = ForloebModel.FromState(forloebSliceState.forloebState);

    SetTitleBar(Localizer.evalueringPage_evalueringAfSted(),
        [{ text: Localizer.global_forloeb(), url: RoutePaths.Forloeb([uddannelseslaegeId]).url}, { text: forloebModel.GetStedInfo(), url:  ""} ]);

    useEffectAsync(async () => {
        if(!forloebSliceState.forloebState.id){
            await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
        }
    }, [uddannelseslaegeId])

    useEffectAsync(async () => {
        if(forloebSliceState.forloebState.id){
            const forloebEvalueringModel = await evalueringApi.getOrCreateForloebEvaluering(forloebSliceState.forloebState.id);
            setForloebEvalueringModel(forloebEvalueringModel);
            const uddannelsesansvarligeModel = await evalueringApi.getUddannelsesansvarligeByForloebId(forloebSliceState.forloebState.id);
            setUddannelsesansvarlige(uddannelsesansvarligeModel);
        }

        setIsLoading(false);
    }, [forloebSliceState.forloebState.id])

    return (
        <Loading isLoading={isLoading} text={Localizer.evalueringPage_getEvaluering()}>
            {forloebEvalueringModel.forloebEvalueringId &&
                <Evaluering forloebEvaluering={forloebEvalueringModel} uddannelsesansvarlige={uddannelsesansvarlige}/>
            }
        </Loading>
    );
}
