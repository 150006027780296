import {Localizer} from "../../../infrastructure/localization/localizer";
import {DatasetModel} from "../datasetModel";
import {RootTypeEnumTranslator, getAllTranslatedRootTypeEnums} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useStamdataApi from "core/hooks/useStamdataApi";
import useLogbogApi from "core/hooks/useLogbogApi";
import { ForSelectModel } from "core/sharedmodels/forSelectModel";
import FilterItemDetails from "./evalueringFilterItem";

type EvalueringsstatistikFilterDetailsProps = {
    dataset: DatasetModel
}

const handleFetchAndSet = async (apiPromise: Promise<ForSelectModel[]>, setFunc: Dispatch<SetStateAction<string[] | undefined>>) => {
    const data = await apiPromise;
    setFunc(data.map(x => x.text));
}

export const EvalueringsstatistikFilterDetails = ({dataset}: EvalueringsstatistikFilterDetailsProps) => {

    const { evalueringstatistikApi } = useLogbogApi();
    const { stamdataLaegeinstitutionApi, stamdataUddannelseApi } = useStamdataApi();

    const [allSpecialer, setAllSpecialer] = useState<string[]>();
    const [allSygehuse, setAllSygehuse] = useState<string[]>();
    const [allRegioner, setAllRegioner] = useState<string[]>();
    const [allUdannelser, setAllUdannelser] = useState<string[]>();

    const third = Math.ceil(dataset.uddannelsessteder.length / 3);
    const firstHalf = dataset.uddannelsessteder?.slice(0, third);
    const secondHalf = dataset.uddannelsessteder?.slice(third, third * 2)
    const thirdHalf = dataset.uddannelsessteder.slice(third * 2)

    const regionFilterValgt = dataset.regioner && dataset.regioner?.length > 0;
    const sygehusFilterValgt = dataset.sygehuse && dataset.sygehuse?.length > 0;
    const specialeFilterValgt = dataset.uddannelsesspecialer && dataset.uddannelsesspecialer?.length > 0;
    const uddannelsestypeValgt =  dataset.stillingsTypeName;

    useEffect(() => {
        if(specialeFilterValgt) return;
        handleFetchAndSet(evalueringstatistikApi.getSpecialerSelect(), setAllSpecialer);
    }, [evalueringstatistikApi, specialeFilterValgt]);

    useEffect(() => {
        if(sygehusFilterValgt) return;
        handleFetchAndSet(stamdataLaegeinstitutionApi.getAllSygehuseSelect(), setAllSygehuse);
    }, [stamdataLaegeinstitutionApi, sygehusFilterValgt]);

    useEffect(() => {
        if(regionFilterValgt) return;
        handleFetchAndSet(evalueringstatistikApi.getRegionerSelect(), setAllRegioner);
    }, [evalueringstatistikApi, regionFilterValgt]);

    useEffect(() => {
        if(uddannelsestypeValgt) return;
        handleFetchAndSet(stamdataUddannelseApi.getUddannelser(), setAllUdannelser);
    }, [stamdataUddannelseApi, uddannelsestypeValgt]);

    return (
        <div className="card-body border-top">
            <div className="d-flex wrap">
                <div className="flex-row flex-fill">
                    <h5>{Localizer.global_filter()}</h5>

                    <FilterItemDetails
                        text={Localizer.global_regioner()}
                        items={regionFilterValgt ? dataset.regioner : allRegioner}
                    />

                    <FilterItemDetails
                        text={Localizer.laegeinstitution()}
                        items={dataset.laegeinstitutionName ? [RootTypeEnumTranslator(dataset.laegeinstitutionName)] : getAllTranslatedRootTypeEnums()}
                    />

                    <FilterItemDetails
                        text={Localizer.sygehuse()}
                        items={sygehusFilterValgt ? dataset.sygehuse : dataset.isStandardVisning === true ? [] : allSygehuse}
                    />

                    <FilterItemDetails
                        text={Localizer.uddannelsesType()}
                        items={uddannelsestypeValgt ? dataset.stillingsTypeName : allUdannelser}
                    />

                    <FilterItemDetails
                        text={Localizer.global_speciale()}
                        items={specialeFilterValgt ? dataset.uddannelsesspecialer : allSpecialer}
                    />

                    <p className="margin-top-m wrap">{Localizer.uddannelsessteder()}</p>
                    <div className="row">
                        <div className="col-sm-4">
                            {!!firstHalf &&
                                <ul className="pl-3">
                                    {firstHalf?.map((uddannelsessted) =>
                                        <li key={uddannelsessted.id}>{uddannelsessted.navn}</li>
                                    )}
                                </ul>
                            }
                        </div>
                        <div className="col-sm-4">
                            {!!secondHalf &&
                                <ul className="pl-3">
                                    {secondHalf?.map((uddannelsessted) =>
                                        <li key={uddannelsessted.id}>{uddannelsessted.navn}</li>
                                    )}
                                </ul>
                            }
                        </div>

                        <div className="col-sm-4">
                            {!!thirdHalf &&
                                <ul className="pl-3">
                                    {thirdHalf?.map((uddannelsessted) =>
                                        <li key={uddannelsessted.id}>{uddannelsessted.navn}</li>
                                    )}
                                </ul>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

