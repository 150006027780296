import useScreenResolution from "../../core/hooks/useScreenResolution";
import React, {useState} from "react";
import {Localizer} from "../../infrastructure/localization/localizer";
import {Tab, Tabs} from "../../core/components/Tabs/Tabs";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {
    SpecialespecifikkeFilerOverview
} from "../forloeb/forloebOverviews/specialespecifikkeFilerOverview";
import UddannelsesstedFiles from "./components/uddannelsesstedFiles";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import { useRouteMatch } from "react-router-dom";
import { GenericIdPath } from "infrastructure/route/routePathConstants";
import useInitUddannelseslaegeForloebState from "core/hooks/useInitUddannelseslaegeForloebState";

const removeWhiteSpace = (str: string) => str.replace(/\s/g, ''); 
const specialespecifikkeFilerTabId = removeWhiteSpace(Localizer.forloebpage_specialespecifikkeFiler());
const filesFromEducationPlaceTabId = removeWhiteSpace(Localizer.FilesFromEducationPlace());

export const FilerPage = () => {
    const {isMobile} = useScreenResolution();
    const [activeTab, setActiveTab] = useState<string>();
    const { id: uddannelseslaegeId } = useRouteMatch<GenericIdPath>().params;

    const { initializedToCorrectLaege } = useInitUddannelseslaegeForloebState(uddannelseslaegeId);

    const setTabAndHistory = (id: string) => {
        setActiveTab(id);
    }

    const tabs: Tab[] = [
        {
            title: Localizer.forloebpage_specialespecifikkeFiler(),
            id: specialespecifikkeFilerTabId,
            defaultActive: activeTab === specialespecifikkeFilerTabId,
            content: <SpecialespecifikkeFilerOverview/>
        }, {
            title: Localizer.FilesFromEducationPlace(),
            id: filesFromEducationPlaceTabId,
            defaultActive: activeTab === filesFromEducationPlaceTabId,
            content: <UddannelsesstedFiles />
        }
    ];

    return (
        <>
            <ForloebSpecificPageWrapper hideAllContent={!initializedToCorrectLaege}>
                <ForloebHeader hideSeUddannelseAndForloebDetails={true}/>
                {isMobile ?
                    <div className="row top-level-row">
                        <div className="col-md-6 col-12">
                            <SpecialespecifikkeFilerOverview/>
                        </div>
                        <div className="col-md-6 col-12">
                            <UddannelsesstedFiles />
                        </div>
                    </div>
                    :
                    <div className="top-level-row">
                        <Tabs tabs={tabs} tabClickedCallback={x => setTabAndHistory(x.id)}/>
                    </div>
                }
            </ForloebSpecificPageWrapper>
        </>
    );
}
