import React from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {useAppSelector} from "../../../app/hooks";
import {kompetenceCardReducer, KompetenceCardState} from "../cards/kompetenceCardSlice";
import {svarReadTypeCompareMap} from "../readSvar/svarReadTypeCompareMap";
import {KvSkemaConstants} from "./KbuSkemaConstants";
import {GenericDownloadButton} from "../../../core/components/fileMetadata/genericDownloadButton";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import useLogbogApi from "../../../core/hooks/useLogbogApi";

export class KompetenceEvalueringDetailsProps {
    evalueringModel: KompetenceEvalueringModel;
    modalTarget: string;
}

export function KompetenceEvalueringDetails(props: KompetenceEvalueringDetailsProps) {
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const indsendtDato = props.evalueringModel.indsendtDato.dateWithoutTimeFormat(false);
    const detailRenderId = "detailRenderId";

    const vurderingSpoergsmaal = props.evalueringModel.sektioner.filter(s => s.includedInProgress).flatMap(x => x.spoergsmaal);
    const {logbogExportApi} = useLogbogApi();

    return (
        <div className="flex-container">
            <div className="flex-container-row margin-bottom-m">
                <div className="flex-column">
                    <h5>{Localizer.global_kompetence()}</h5>
                    <p>{kompetenceCardState.activeLaegekompetenceMeta.kompetenceNummer}. {kompetenceCardState.activeLaegekompetenceMeta.titel}</p>
                </div>
                <GenericDownloadButton
                    additionalClasses={"flex-align-r"}
                    clickFunction={async () => await logbogExportApi.getKompetenceEvalueringPdf(props.evalueringModel.laegekompetenceEvalueringId, kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId, kompetenceCardState.activeLaegekompetenceMeta.UserId)}
                    child={<EmblaIcon additionalClasses={"subtle"} iconName="download"/>}/>
            </div>
            <div className="d-flex margin-bottom-m">
                <div className="flex-column">
                    <h5>{Localizer.UdfyldtAf()}</h5>
                    <p>{props.evalueringModel.createdByUser?.firstName + " " + props.evalueringModel.createdByUser?.lastName}</p>
                </div>
                {props.evalueringModel.indsendtDato &&
                    <div className="flex-column margin-left-xxl">
                        <h5>{Localizer.dato()}</h5>
                        <p>{indsendtDato}</p>
                    </div>
                }
            </div>
            {props.evalueringModel.tekst &&
                <div className="margin-bottom-m">
                    <h5>{Localizer.Kompetencevurdering()}</h5>
                    <p>{props.evalueringModel.tekst}</p>
                </div>
            }
            <div className="border margin-bottom-m"/>

            <div className="flex-column margin-bottom-m">
                {vurderingSpoergsmaal.map((spoergsmaal, index) =>
                    <React.Fragment key={spoergsmaal.id}>
                        <div className="margin-bottom-m">
                            <>
                                {spoergsmaal.isFeedback
                                    ?
                                    <>
                                        <h5>{Localizer.global_feedback()}</h5>
                                        <i><p>{spoergsmaal?.titel}</p></i>
                                    </>
                                    :
                                    <>
                                        <p>{spoergsmaal?.titel}</p>
                                        <p className={"subtle"}>{spoergsmaal.tekst}</p>
                                    </>
                                }
                            </>
                            {spoergsmaal.evalueringSvar?.map(es => {
                                const Component = svarReadTypeCompareMap[spoergsmaal.spoergsmaalTypeEnum];
                                return Component ?
                                    <Component spoergsmaal={spoergsmaal}
                                               evalueringSvar={es}
                                               excludeAnswerText={KvSkemaConstants.InitialKbuSkemaIds.includes(props.evalueringModel.evalueringsSkemaId)}
                                               renderId={detailRenderId}/> : <></>;
                            })}
                        </div>
                        <div className="border margin-bottom-m"/>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}
